.containerErrorHandler {
  width: 100%;
  height: 100vh;
  text-align: center;
  padding: 1rem;
  box-sizing: border-box !important;
  display: flex;
  align-items: center;
}

.bodyContainer {
  /* padding-top: 20%; */
  max-width: 620px;
  margin: auto;
}

.headerText {
  font-weight: 800;
  color: black;
}

.t-dark .headerText {
  color: var(--colour-text-primary);
}

.definition {
  margin-top: 20px;
  font-size: 17px;
  color: gray;
  font-weight: 200;
}

.t-dark .definition{
  color: var(--colour-text-primary);
}

.goBackbutton {
  border: none;
  border-radius: 45px;
  padding: 10px 50px;
  color: black;
  background-color: #75d1fc;
  font-weight: 600;
  margin-top: 20px;
}
