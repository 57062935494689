.cardContainer {
  border-radius: 15px;
  background-color: white;
  padding: 20px 20px;
}

.header {
  display: flex;
}

.topic {
  margin-top: 0% !important;
  width: 70%;
  font-size: 15px;
}

.topicBorder {
  border: 1px solid rgb(192, 192, 215);
  padding: 2px 5px;
  border-radius: 5px;
}

.save {
  width: 30%;
  text-align: right;
}

.body {
  margin-top: 10px;
}

.primaryText {
  font-weight: 800;
  font-size: 20px;
  color: black;
}

.secondaryText {
  font-size: 12px;
  color: gray;
}

.body {
  color: var(--colour-text-primary);
  font-size: 10px;
}

.footer {
  margin-top: 20px;
  display: flex;
  width: 100%;
}

.time {
  width: 50%;
  font-size: 12px;
}

.likes {
  width: 50%;
  text-align: right;
  font-weight: 800;
  font-size: 12px;
}
