.resources-page,
.resources-page * {
  box-sizing: border-box !important;
}

.resources-page .background-White {
    background-color: #fff;
  }

  .t-dark .resources-page .background-White {
    background-color: #12151E;
    ;
  }
  
  .t-dark .resources-page .background-White h3 {
    color: #FFFFFF;
  }

  .t-dark .resources-page .background-White .saveBtn {
    background-color: #171D35;
    color: #FFFFFF;
  }
  
  .t-dark .resources-page .background-White .likeBtn svg {
    color: #FFFFFF;
  }

  .resources-page .seperator {
      border: 1px solid #ECECEC;
  }

  .resources-content {
      color: var(--colour-text-secondary);
      font-size: 20px;
      margin-left: 120px !important;
  }
  .resources-video {
      text-align: center;
      width: 100%;
  }

  .video-lightbox {
      position: relative !important;
  }
  
  .resources-page .document-embed {
    padding-bottom: 30px;
  }

 .resources-page .resource-page-container {
    padding: 15px 150px !important;
  }
  .resource-download-document-button {
    margin-left: 120px !important;
  }

  .background-White .assessment-title{
    word-break: break-word;
    /* overflow: hidden; */
  }