.iview-welcome,
.iview-welcome * {
  box-sizing: border-box !important;
}

.background-White {
  background-color: #fff;
}

.text-iview{
  color: whitesmoke !important;
}

.image-overflow {
  /* margin-bottom: -100px; */
}

button.saveBtn {
  border: none;
  border-radius: var(--border-radius-softly-rounded);
  padding: var(--spacing-4) var(--spacing-8);
  /* background-color: var(--colour-action-background); */
  /* color: var(--colour-action-primary); */
  background-color: var(--colour-container-on-primary);
  color: var(--colour-action-feature);
  height: 1.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all var(--transition-timing-function-default)
    var(--transition-speed-normal);
}

button.saveBtn:hover {
  border: none;
  background-color: var(--colour-action-primary);
  color: var(--colour-action-primary-text);
}

button.saveBtn span.saveBtnText {
  margin-left: 0.375rem;
}

#category-label {
  margin-left: 0px;
  font-size: 14px;
  border: 1px solid #d9e2ff;
  border-radius: 4px;
  padding: 3px 8px 2px;
}

#cardtype {
  margin-left: 10px;
  font-size: 14px;
}

button.likeBtn {
  border: none;
  padding: var(--spacing-4) var(--spacing-8);
  background-color: var(--colour-background-primary);
  color: var(--colour-text-primary);
}

button.likeBtn:hover {
  border: none;
}

button.likeBtn span.likeBtnText {
  font-size: 12px;
}

.iview-view-collection-subheading {
  color: var(--colour-text-secondary);
  font-size: 16px;
  padding-bottom: 15px;
  cursor: pointer;
}

ul.videocollectionList {
  list-style: none;
  padding: 0px;
}

.downarrowIcon {
  color: var(--colour-action-primary-arrow);
}

.videoCollectionListRow {
  margin-top: 1rem !important;
}

.background-dark {
  background-color: var(--colour-container-featured-dark) !important;
  color: var(--colour-container-small);
  border: 1px solid var(--colour-background-primary-transparent) !important;
  /* margin-bottom: 5px; */
}

/* .background-button-dark {
  background-color: var(--colour-container-featured-dark) !important;
  color: var(--colour-container-small) !important;
  border: 1px solid var(--colour-container-small) !important;
  margin-bottom: 5px;
}

.background-button-light {
  background-color: var(--colour-action-outline) !important;
  color: var(--colour-text-primary) !important;
  border: 1px solid var(--colour-action-outline) !important;
  margin-bottom: 5px;
} */

.background-light {
  background-color: var(--colour-background-secondary) !important;
  color: var(--colour-text-primary) !important;
  border: 1px solid var(--colour-border-hairline) !important;
  /* margin-bottom: 5px; */
}

.common-margin-from-top {
  margin-top: 10px !important;
  /* padding: 4px; */
  padding: 4px 0;
}
.iviewCards {
  column-gap: 15px;
}

.iview-collection-title-dark {
  font-size: 24px;
  color: var(--colour-action-secondary-text);
}
.iview-collection-title-light {
  font-size: 24px;
  color: var(--colour-text-primary);
}

/* .t-dark h3 {
  color: var(--colour-text-inverse);
} */
.text-color-white {
  color: var(--colour-action-primary-text);
}
.iview-collection-subtitle-dark {
  color: var(--colour-action-secondary-text);
  font-size: 11px;
  font-weight: 700;
}
.iview-collection-subtitle-light {
  color: var(--colour-action-feature);
  font-size: 11px;
  font-weight: 700;
}
.iview-collection-description-dark {
  color: var(--colour-action-secondary-text);
}
.iview-collection-description-light {
  color: var(--colour-text-primary);
}

.text-color-primary {
  color: var(--colour-text-primary);
}
.text-color-action-primary {
  color: var(--colour-action-primary);
}
.twoCloumn {
  columns: 2 auto;
  padding-bottom: 5px;
}

.iview-library-item {
  padding-left: 10px;
}
.iview-card-remove-pading .cyJtPX {
  padding: 0px !important;
}
.iview-assesment-heading {
  color: var(--colour-action-feature) !important;
  font-size: 11px !important;
  font-weight: bold !important;
  margin-bottom: 0px;
}

.iview-assesment-heading,
#cardtype {
  margin-left: -2px;
}

.iview-interview-heading {
  font-size: 28px;
  font-weight: bold;
  color: var(--colour-text-primary);
}

.iview-assesments-cards {
  margin-bottom: 20px !important;
}

.iview-assesment-count {
  color: var(--colour-text-secondary);
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  .other-iView-collection{
    display: flex;
    flex-direction: column;
  }

  .iView-collection-img{
    margin: unset !important;
  }

  .other-iView-video{
    width: unset !important;
  }
  .iview-interview-heading {
    font-size: 18px;
  }
}

@media screen and (max-width: 425px) {
  .iView-collection-focusArea{
    flex-wrap: wrap;
    gap: 5px;
  }

  .iView-collection-header{
    font-size: 2rem !important;
  }
}
