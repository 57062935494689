.jobListTwoColumn {
    flex-wrap: wrap;
    row-gap: 20px;
    list-style-type: none;
    padding: 0;
    column-gap: 24px;
    display: block;
    -webkit-columns: 2;
    columns: 2;
    width: 100%;
}

.jobListTwoColumn li {
    width: 100%;
    -webkit-break-inside: avoid;
    break-inside: avoid;
    margin-bottom: 20px;
}

.jobListOneColumn {
    row-gap: 12px;
    flex-wrap: wrap;
    display: flex;
    list-style-type: none;
    padding: 0;
}

.jobListOneColumn li {
    width: 100%;
    -webkit-break-inside: avoid;
    break-inside: avoid;
}

.savedjobsTab {
    width: 100%;
}

.bodyText {
    text-align: justify;
}

.assessment-card-block {
    display: grid;
    grid-row: auto;
    grid-template-columns: auto auto;
    column-gap: 5px;
    margin-bottom: 20px;
}

.assessment-card {


    display: grid;
    grid-row: auto;
    grid-template-columns: auto auto;
    column-gap: 30px;
    margin-bottom: 50px;
    z-index: 0
}

.start-section {
    background-color: var(--colour-background-primary);
    color: var(--colour-text-primary);
}

.start-bookmarking-section {
    width: 100%;
    margin-bottom: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 15px;
}

.event-card p {
    color: var(--colour-global-white)
}

.start-bookmarking {
    width: 100%;
}

.start-bookmarking.assessment-page .assessment-review-result-wrapper {
    min-height: auto;
    border-radius: 15px;
}

@media (max-width: 992px) {
    .bookmark-layout {
        margin-top: 0px !important;
    }

    label[for="bookmark-filter"] {
        margin: auto
    }

    .stack-buttons-mobile {
        display: flex;
        flex-direction: column;
    }

    .start-bookmarking.assessment-page .assessment-review-result-wrapper {
        background-color: transparent;
    }
}


@media (max-width: 475px) {
    .start-bookmarking-section p {
        font-size: 1.5rem !important;
    }

    .start-bookmarking-section .p-3 {
        padding: var(--spacing-20) !important;
    }

    .start-bookmarking-section,
    .start-bookmarking-section * {
        box-sizing: border-box !important;
    }

    .bookmark-layout .jobListTwoColumn {
        gap: var(--spacing-16);
    }

    .bookmark-landing .jobListTwoColumn {
        flex-wrap: nowrap;
        flex-direction: row;
        overflow-x: auto;
        column-gap: 12px;
    }

    .bookmark-landing .jobListTwoColumn::-webkit-scrollbar {
        display: none;
    }

    .bookmark-landing .jobListTwoColumn li {
        min-width: 320px;
        max-width: 320px;
        margin: 0;
    }

    .bookmark-landing .jobListTwoColumn li h4 {
        margin-right: 4rem;
    }

    .bookmark-landing.bookmark-job-sections .jobListTwoColumn li>div {
        height: 100%;
        box-sizing: border-box !important;
    }
}