*, ::after, ::before {
    box-sizing: revert !important;
}
.inpStyle {
    border: 1px solid #ccc;
    padding: 20px 20px;
    display: flex;
    }
.countryCode {
    border: 0;
}
.phoneField {
    border: 0;
    outline: none;
    background-color: transparent;
}
.PhoneInputInput {
    border: 0;
    max-width: 30px;
    outline: none;
    color: transparent;
    text-shadow: 0 0 0 #2E344D;
    pointer-events: none;
    background-color: transparent;
}
.t-dark .PhoneInputInput, .t-dark .phoneField {
    color: var(--colour-text-primary);
}
.autocomplete_city input{
    font-weight: unset;
    font-size: inherit;
    background-color: transparent;
    color: var(--colour-text-primary);
}

.welcome-onboard{
    margin-top: 75px;
}
.serviceOfficeOptions {
    overflow: auto;
    max-height: 250px;
}
.customLangHeader {
    position: absolute;
    top: 12px;
    left: 20px;
    width: 300px;
}
.customLangHeaderWithBackButton {
    position: absolute;
    top: 10px;
    left: 100px;
    width: 300px;
}
@media screen and (max-width: 435px) {
    .signup-details{
        margin-top: 0 !important;
    }

    .welcome-onboard{
        margin-top: 40px;
        margin-bottom: 20px;
    }
    .customLangHeader {
        width: 220px;
    }
    .customLangHeaderWithBackButton {
        width: 190px;
    }
}

@media screen and (max-width: 285px) {
    .customLangHeader {
        width: 100px;
    }
    .customLangHeaderWithBackButton {
        width: 100px;
    }
}