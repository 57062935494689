.accordion{
    width: 100%;
    margin: 18px auto;
    color: var(--colour-background-primary);
    background:var(--colour-text-primary);
    box-shadow: var(--shadow-high);
    border-radius: 15px;
    padding: 5px;
}

.container {
    width: 100%;
    /* max-width: 600px; */
}

.accordion_heading{
    background:var(--colour-text-primary);;
    margin-top: 2px;
} 

.accordion_heading .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.accordion_heading span {
    cursor: pointer;
    background-color:var(--colour-text-primary);
    border-radius: 100%;
    border: 1.21875px solid var(--colour-action-outline);
}

.container .arrow {
    background: var(--colour-action-primary);
    color: var(--colour-text-primary);
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container .arrow:hover{
    /* background: var(--colour-global-white); */
    border: 1px solid var(--colour-action-outline);
}

.container .cross {
    height: 39px;
    width: 39px;
}

.container .cross:hover {
    border: 1px solid var(--colour-action-outline);
}

.container .cross i {
    position: relative;
    top: 8px;
    left: 10px;
}

.accordion_heading .container .accordion_title{
    font-family: var(--font-family-Helvetica-Neue-LT-Pro);
    font-weight: var(--font-weight-regular);
    font-size: 18px;
    width: 85%;
    margin-top: 18px;
}

.container .container_buttons {
    width: 15%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    /* position: relative; */
}

.container .container_buttons .arrow i {
    /* position: relative;
    top: 8px;
    left: 5px; */
}

.accordion_content_container span{
    font-family: var(--font-family-Helvetica-Neue-LT-Pro);
    font-weight: var(--font-weight-regular);
    font-style: normal;
    font-size: 14px;
}

.accordion_content_container{
    margin: 0 10px 10px 10px;
    padding-bottom: 5px;
}

.accordion_content {
    display: flex;
    height: 0;
    opacity: 0;
    overflow: hidden;
    transition: all 600ms ease-in-out;
}

.notification-arrow i {
   font-size: 12px;
}

.notification-down-arrow {
    transform: rotate(0deg);
}

.notification-up-arrow {
    transform: rotate(180deg);
}

@media (max-width: 767px){
    .accordion_heading .container .accordion_title{
        width: 75%;
    }
    .container .container_buttons{
        width: 25%;
    }
}