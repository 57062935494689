html {
  --colour-border-success: #78e052;
  --colour-border-error: #9D323D;
  --colour-text-success: #5C7D70;
  --colour-text-warn: #B36205;
  --colour-text-error: #9D323D;
  --colour-background-error: #F9EFEF;
  --colour-background-success: #E6FAF1;
  --colour-text-on-success: #000;
  --colour-text-on-error: #FFF;
  --colour-gradient_green: #78e052;
  --colour-gradient_blue: #1ab2fc;
  --colour-global-white: #FFF;
  --colour-global-black: #000;
  --colour-global-blue-light: #E8F7FF;
  --colour-global-grey-10: #F4F4F5;
  --colour-global-grey-20: #DEE1EA;
  --colour-global-grey-90: #12151E;
  --colour-global-red: #9D323D;
  --colour-global-aqua-blue: #75D1FC;
  --colour-global-dark-blue: #386196;
  --colour-dark-blue-70: #171D35;
}

html,
.t-light {
  --colour-background-primary: #FFF;
  --colour-background-secondary: #F4F4F5;
  --colour-text-primary: #282A32;
  --colour-text-secondary: #646E8A;
  --colour-text-inverse: #FFF;
  --colour-action-primary: #75D1FC;
  --colour-action-outline: #DEE1EA;
  --colour-action-primary-text: #282A32;
  --colour-action-secondary-text: #FFF;
  --colour-action-primary-arrow: #75D1FC;
  --colour-border-hairline: #ECECEC;
  --colour-action-disabled: #DEE1EA;
  --colour-action-disabled-text: #646E8A;
  --colour-action-small: #386196;
  --colour-action-background: #F4F4F5;
  --colour-action-feature: #386196;
  --colour-container-on-primary: #F4F4F5;
  --colour-container-on-secondary: #FFF;
  --colour-container-featured: #282A32;
  --colour-container-featured-dark: #282A32;
  --colour-container-featured-light: #FFF;
  --colour-container-small: #D1F0FF;
  --colour-link-nav-text: #282A32;
  --colour-link-nav-icon: #646E8A;
  --colour-switch-icon: #646E8A;
  --colour-accordion-button: #F4F4F5;
  --colour-alert-background: #FFF;
  --colour-alert-icon: #E8F7FF;
  --colour-modal-actions: #F4F4F5;
  --colour-modal-primary-content: #FFF;
  --colour-modal-secondary-content: #F4F4F5;
  --colour-tabs-inactive-text: #000;
  --colour-tabs-inactive-line: #DEE1EA;
  --colour-tabs-active-text: #1ab2fc;
  --colour-tabs-active-line: #1ab2fc;
  --colour-accordion-inactive-text: #282A32;
  --colour-accordion-inactive-icon: #282A32;
  --colour-accordion-inactive-line: #DEE1EA;
  --colour-accordion-active-text: #1ab2fc;
  --colour-accordion-active-icon: #1ab2fc;
  --colour-accordion-active-line: #1ab2fc;
  --colour-carousel-action-background: #FFF;
  --colour-carousel-action-icon: #1ab2fc;
  --colour-carousel-action-border: #FFF;
  --colour-carousel-action-disabled-icon: #1ab2fc;
  --colour-background-primary-transparent: rgba(255, 255, 255, 0);
  --colour-background-mask: rgba(23, 29, 53, 0.4);
  --colour-rating-low: #9D323D;
  --colour-rating-mid: #B36205;
  --colour-rating-high: #5C7D70;
}

.t-dark {
  --colour-background-primary: #000;
  --colour-background-secondary: #282A32;
  --colour-text-primary: #FFF;
  --colour-text-secondary: #F4F4F5;
  --colour-text-inverse: #282A32;
  --colour-action-primary: #75D1FC;
  --colour-action-outline: #646E8A;
  --colour-action-primary-text: #282A32;
  --colour-action-secondary-text: #FFF;
  --colour-action-primary-arrow: #75D1FC;
  --colour-action-disabled: #575756;
  --colour-action-disabled-text: #646E8A;
  --colour-action-small: #D1F0FF;
  --colour-action-background: #E8F7FF;
  --colour-action-feature: #E8F7FF;
  --colour-container-on-primary: #282A32;
  --colour-container-on-secondary: #000;
  --colour-container-featured: #3E3F47;
  --colour-container-small: #386196;
  --colour-container-featured-dark: #3E3F47;
  --colour-container-featured-light: #282A32;
  --colour-link-nav-text: #FFF;
  --colour-link-nav-icon: #F4F4F5;
  --colour-border-hairline: #3E3F47;
  --colour-switch-icon: #FFF;
  --colour-accordion-button: #282A32;
  --colour-alert-background: #282A32;
  --colour-alert-icon: #282A32;
  --colour-modal-actions: #12151E;
  --colour-modal-primary-content: #282A32;
  --colour-modal-secondary-content: #12151E;
  --colour-tabs-inactive-text: #F4F4F5;
  --colour-tabs-inactive-line: #575756;
  --colour-tabs-active-text: #FFF;
  --colour-tabs-active-line: #FFF;
  --colour-accordion-inactive-text: #FFF;
  --colour-accordion-inactive-icon: #FFF;
  --colour-accordion-inactive-line: #DEE1EA;
  --colour-accordion-active-text: #FFF;
  --colour-accordion-active-icon: #1ab2fc;
  --colour-accordion-active-line: #1ab2fc;
  --colour-carousel-action-background: #282A32;
  --colour-carousel-action-icon: #FFF;
  --colour-carousel-action-border: #DEE1EA;
  --colour-carousel-action-disabled-icon: #646E8A;
  --colour-background-primary-transparent: rgba(18, 21, 30, 0);
  --colour-background-mask: rgba(46, 52, 77, 0.9);
  --colour-rating-low: #9D323D;
  --colour-rating-mid: #B36205;
  --colour-rating-high: #78e052;
}

* {
  --spacing-4: 0.25rem;
  --spacing-8: 0.5rem;
  --spacing-10: 0.625rem;
  --spacing-12: 0.75rem;
  --spacing-16: 1rem;
  --spacing-20: 1.25rem;
  --spacing-24: 1.5rem;
  --spacing-32: 2rem;
  --spacing-40: 2.5rem;
  --spacing-56: 3.5rem;
  --spacing-64: 4rem;
  --spacing-80: 5rem;
  --spacing-104: 6.5rem;
  --spacing-128: 8rem;
  --spacing-168: 10.5rem;
}

* {
  --shadow-smooth: 0px 2px 2px rgba(12, 23, 54, 0.1), 0px 0px 4px rgba(12, 23, 54, 0.1);
  --shadow-medium: 0px 2px 2px rgba(12, 23, 54, 0.1), 0px 4px 10px rgba(12, 23, 54, 0.1);
  --shadow-high: 0px 0px 4px rgba(0, 4, 23, 0.05), 0px 8px 15px 2px rgba(0, 4, 23, 0.1);
  --border-thickness-none: 0;
  --border-thickness-thin: 1px;
  --border-thickness-medium: 2px;
  --border-radius-square: 0;
  --border-radius-very-softly-rounded: 2px;
  --border-radius-softly-rounded: 4px;
  --border-radius-rounded: 8px;
  --border-radius-highly-rounded: 16px;
  --border-radius-very-highly-rounded: 32px;
  --border-radius-pill: 100px;
}

@font-face {
  font-family: "Helvetica Neue LT Pro";
  src: url("assets/fonts/helvetica_neue/light/HelveticaNeueLTW05-45Light.eot");
  src: url("assets/fonts/helvetica_neue/light/HelveticaNeueLTW05-45Light.eot") format("embedded-opentype"), url("assets/fonts/helvetica_neue/light/HelveticaNeueLTW05-45Light.woff2") format("woff2"), url("assets/fonts/helvetica_neue/light/HelveticaNeueLTW05-45Light.woff") format("woff"), url("assets/fonts/helvetica_neue/light/HelveticaNeueLTW05-45Light.ttf") format("truetype"), url("assets/fonts/helvetica_neue/light/HelveticaNeueLTW05-45Light.svg#HelveticaNeueLTW05-45Light") format("svg");
  font-weight: 300;
}
@font-face {
  font-family: "Helvetica Neue LT Pro";
  src: url("assets/fonts/helvetica_neue/regular/HelveticaNeueLTW05-55Roman.eot");
  src: url("assets/fonts/helvetica_neue/regular/HelveticaNeueLTW05-55Roman.eot") format("embedded-opentype"), url("assets/fonts/helvetica_neue/regular/HelveticaNeueLTW05-55Roman.woff2") format("woff2"), url("assets/fonts/helvetica_neue/regular/HelveticaNeueLTW05-55Roman.woff") format("woff"), url("assets/fonts/helvetica_neue/regular/HelveticaNeueLTW05-55Roman.ttf") format("truetype"), url("assets/fonts/helvetica_neue/regular/HelveticaNeueLTW05-55Roman.svg#HelveticaNeueLTW05-55Roman") format("svg");
  font-weight: 400;
}
@font-face {
  font-family: "Helvetica Neue LT Pro";
  src: url("assets/fonts/helvetica_neue/medium/HelveticaNeueLTW05-65Medium.eot");
  src: url("assets/fonts/helvetica_neue/medium/HelveticaNeueLTW05-65Medium.eot") format("embedded-opentype"), url("assets/fonts/helvetica_neue/medium/HelveticaNeueLTW05-65Medium.woff2") format("woff2"), url("assets/fonts/helvetica_neue/medium/HelveticaNeueLTW05-65Medium.woff") format("woff"), url("assets/fonts/helvetica_neue/medium/HelveticaNeueLTW05-65Medium.ttf") format("truetype"), url("assets/fonts/helvetica_neue/medium/HelveticaNeueLTW05-65Medium.svg#HelveticaNeueLTW05-65Medium") format("svg");
  font-weight: 500;
}
@font-face {
  font-family: "Helvetica Neue LT Pro";
  src: url("assets/fonts/helvetica_neue/bold/HelveticaNeueLTW05-75Bold.eot");
  src: url("assets/fonts/helvetica_neue/bold/HelveticaNeueLTW05-75Bold.eot") format("embedded-opentype"), url("assets/fonts/helvetica_neue/bold/HelveticaNeueLTW05-75Bold.woff2") format("woff2"), url("assets/fonts/helvetica_neue/bold/HelveticaNeueLTW05-75Bold.woff") format("woff"), url("assets/fonts/helvetica_neue/bold/HelveticaNeueLTW05-75Bold.ttf") format("truetype"), url("assets/fonts/helvetica_neue/bold/HelveticaNeueLTW05-75Bold.svg#HelveticaNeueLTW05-75Bold") format("svg");
  font-weight: 600;
}
@font-face {
  font-family: "DIN Next LT Pro";
  src: url("assets/fonts/din_next/light/DINNextLTW05-Light.eot");
  src: url("assets/fonts/din_next/light/DINNextLTW05-Light.eot") format("embedded-opentype"), url("assets/fonts/din_next/light/DINNextLTW05-Light.woff2") format("woff2"), url("assets/fonts/din_next/light/DINNextLTW05-Light.woff") format("woff"), url("assets/fonts/din_next/light/DINNextLTW05-Light.ttf") format("truetype"), url("assets/fonts/din_next/light/DINNextLTW05-Light.svg#DINNextLTW05-Light") format("svg");
  font-weight: 300;
}
@font-face {
  font-family: "DIN Next LT Pro";
  src: url("assets/fonts/din_next/regular/DINNextLTW05-Regular.eot");
  src: url("assets/fonts/din_next/regular/DINNextLTW05-Regular.eot") format("embedded-opentype"), url("assets/fonts/din_next/regular/DINNextLTW05-Regular.woff2") format("woff2"), url("assets/fonts/din_next/regular/DINNextLTW05-Regular.woff") format("woff"), url("assets/fonts/din_next/regular/DINNextLTW05-Regular.ttf") format("truetype"), url("assets/fonts/din_next/regular/DINNextLTW05-Regular.svg#DINNextLTW05-Regular") format("svg");
  font-weight: 400;
}
@font-face {
  font-family: "DIN Next LT Pro";
  src: url("assets/fonts/din_next/medium/DINNextLTW05-Medium.eot");
  src: url("assets/fonts/din_next/medium/DINNextLTW05-Medium.eot") format("embedded-opentype"), url("assets/fonts/din_next/medium/DINNextLTW05-Medium.woff2") format("woff2"), url("assets/fonts/din_next/medium/DINNextLTW05-Medium.woff") format("woff"), url("assets/fonts/din_next/medium/DINNextLTW05-Medium.ttf") format("truetype"), url("assets/fonts/din_next/medium/DINNextLTW05-Medium.svg#DINNextLTW05-Medium") format("svg");
  font-weight: 500;
}
@font-face {
  font-family: "DIN Next LT Pro";
  src: url("assets/fonts/din_next/bold/DINNextLTW05-Bold.eot");
  src: url("assets/fonts/din_next/bold/DINNextLTW05-Bold.eot") format("embedded-opentype"), url("assets/fonts/din_next/bold/DINNextLTW05-Bold.woff2") format("woff2"), url("assets/fonts/din_next/bold/DINNextLTW05-Bold.woff") format("woff"), url("assets/fonts/din_next/bold/DINNextLTW05-Bold.ttf") format("truetype"), url("assets/fonts/din_next/bold/DINNextLTW05-Bold.svg#DINNextLTW05-Bold") format("svg");
  font-weight: 600;
}
@font-face {
  font-family: "Inter";
  src: url("assets/fonts/inter/inter.woff2") format("woff2");
  font-display: swap;
  font-weight: 400;
}
@font-face {
  font-family: "Inter";
  src: url("assets/fonts/inter/inter.woff2") format("woff2");
  font-display: swap;
  font-weight: 500;
}
@font-face {
  font-family: "Inter";
  src: url("assets/fonts/inter/inter.woff2") format("woff2");
  font-display: swap;
  font-weight: 600;
}
@font-face {
  font-family: "Inter";
  src: url("assets/fonts/inter/inter.woff2") format("woff2");
  font-display: swap;
  font-weight: 700;
}
@font-face {
  font-family: "Noto Sans";
  src: url("assets/fonts/noto_sans/NotoSans-Regular.woff2") format("woff2");
  font-display: swap;
  font-weight: 400;
}
@font-face {
  font-family: "Noto Sans";
  src: url("assets/fonts/noto_sans/NotoSans-Regular.woff2") format("woff2");
  font-display: swap;
  font-weight: 500;
}
@font-face {
  font-family: "Noto Sans";
  src: url("assets/fonts/noto_sans/NotoSans-Regular.woff2") format("woff2");
  font-display: swap;
  font-weight: 600;
}
@font-face {
  font-family: "Noto Sans";
  src: url("assets/fonts/noto_sans/NotoSans-Regular.woff2") format("woff2");
  font-display: swap;
  font-weight: 700;
}
* {
  --font-family-Helvetica-Neue-LT-Pro: "Helvetica Neue LT Pro", Arial, sans-serif;
  --font-family-DIN-Next-LT-Pro: "DIN Next LT Pro", Arial, sans-serif;
  --font-family-Inter: "Inter", Helvetica, Arial, "Droid Sans", "Arimo", sans-serif;
  --font-family-Noto-Sans: "Noto Sans";
  --font-family-system-fonts: Helvetica, Arial, "Droid Sans", "Arimo", sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
}

@font-face {
  font-family: "icomoon";
  src: url("assets/fonts/icons/icomoon.eot?li98l7");
  src: url("assets/fonts/icons/icomoon.eot?li98l7#iefix") format("embedded-opentype"), url("assets/fonts/icons/icomoon.ttf?li98l7") format("truetype"), url("assets/fonts/icons/icomoon.woff?li98l7") format("woff"), url("assets/fonts/icons/icomoon.svg?li98l7#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-favourite:before {
  content: "\e953";
}

.icon-favorite_filled:before {
  content: "\e952";
}

.icon-star2:before {
  content: "\e950";
}

.icon-tick2:before {
  content: "\e951";
}

.icon-subtract:before {
  content: "\e94f";
}

.icon-close:before {
  content: "\e90d";
}

.icon-sun:before {
  content: "\e905";
}

.icon-moon:before {
  content: "\e906";
}

.icon-document:before {
  content: "\e94e";
}

.icon-compare:before {
  content: "\e94d";
}

.icon-tick:before {
  content: "\e94c";
}

.icon-add:before {
  content: "\e94b";
}

.icon-person:before {
  content: "\e949";
}

.icon-bell:before {
  content: "\e94a";
}

.icon-play:before {
  content: "\e948";
}

.icon-information:before {
  content: "\e947";
}

.icon-edit-alt:before {
  content: "\e946";
}

.icon-map-pin:before {
  content: "\e93f";
}

.icon-save:before {
  content: "\e940";
}

.icon-saved:before {
  content: "\e941";
}

.icon-search:before {
  content: "\e942";
}

.icon-calendar:before {
  content: "\e943";
}

.icon-button-arrow:before {
  content: "\e944";
}

.icon-edit:before {
  content: "\e945";
}

.icon-star:before {
  content: "\e93e";
}

.icon-arrow_back:before {
  content: "\e900";
}

.icon-arrow_downward:before {
  content: "\e901";
}

.icon-arrow_forward:before {
  content: "\e902";
}

.icon-attachment:before {
  content: "\e903";
}

.icon-book:before {
  content: "\e904";
}

.icon-business_center:before {
  content: "\e907";
}

.icon-card_travel:before {
  content: "\e908";
}

.icon-chat_bubble_outline:before {
  content: "\e909";
}

.icon-check:before {
  content: "\e90a";
}

.icon-check_box:before {
  content: "\e90b";
}

.icon-check_box_outline_blank:before {
  content: "\e90c";
}

.icon-contact_page:before {
  content: "\e90e";
}

.icon-content_paste:before {
  content: "\e90f";
}

.icon-download_done:before {
  content: "\e910";
}

.icon-error_outline:before {
  content: "\e911";
}

.icon-event:before {
  content: "\e912";
}

.icon-event_available:before {
  content: "\e913";
}

.icon-event_busy:before {
  content: "\e914";
}

.icon-explore:before {
  content: "\e915";
}

.icon-favorite_border:before {
  content: "\e916";
}

.icon-filter_alt:before {
  content: "\e917";
}

.icon-filter_list:before {
  content: "\e918";
}

.icon-folder_open:before {
  content: "\e919";
}

.icon-get_app:before {
  content: "\e91a";
}

.icon-https:before {
  content: "\e91b";
}

.icon-indeterminate_check_box:before {
  content: "\e91c";
}

.icon-insert_photo:before {
  content: "\e91d";
}

.icon-lock_open:before {
  content: "\e91e";
}

.icon-map:before {
  content: "\e91f";
}

.icon-more_vert:before {
  content: "\e920";
}

.icon-note_add:before {
  content: "\e921";
}

.icon-notifications_active:before {
  content: "\e922";
}

.icon-notifications_none:before {
  content: "\e923";
}

.icon-notifications_off:before {
  content: "\e924";
}

.icon-notifications_paused:before {
  content: "\e925";
}

.icon-open_in_new:before {
  content: "\e926";
}

.icon-pan_tool:before {
  content: "\e927";
}

.icon-place:before {
  content: "\e928";
}

.icon-play_circle_filled:before {
  content: "\e929";
}

.icon-play_circle_outline:before {
  content: "\e92a";
}

.icon-radio_button_checked:before {
  content: "\e92b";
}

.icon-radio_button_unchecked:before {
  content: "\e92c";
}

.icon-record_voice_over:before {
  content: "\e92d";
}

.icon-report_problem:before {
  content: "\e92e";
}

.icon-restore:before {
  content: "\e92f";
}

.icon-room:before {
  content: "\e930";
}

.icon-save_alt:before {
  content: "\e931";
}

.icon-send:before {
  content: "\e932";
}

.icon-sentiment_dissatisfied:before {
  content: "\e933";
}

.icon-sentiment_neutral:before {
  content: "\e934";
}

.icon-sentiment_satisfied:before {
  content: "\e935";
}

.icon-share:before {
  content: "\e936";
}

.icon-stop_circle:before {
  content: "\e937";
}

.icon-text_snippet:before {
  content: "\e938";
}

.icon-toggle_off:before {
  content: "\e939";
}

.icon-toggle_on:before {
  content: "\e93a";
}

.icon-touch_app:before {
  content: "\e93b";
}

.icon-upload:before {
  content: "\e93c";
}

.icon-watch_later:before {
  content: "\e93d";
}

html {
  --transition-timing-function-default: ease-in-out;
  --transition-speed-normal: 100ms;
  --transition-speed-slow: 250ms;
}

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: auto;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}

/* Margin helpers */
.margin-bottom-16 {
  margin-bottom: var(--spacing-16);
}

.no-margin {
  margin: 0;
}

html,
body {
  height: 100%;
}

html,
body,
fieldset,
legend,
h1 {
  padding: 0;
  margin: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
  padding-block-start: 0;
  padding-block-end: 0;
}

ul {
  padding-left: 1rem;
  margin: 0;
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--colour-container-featured) var(--colour-container-on-primary);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: var(--colour-container-on-primary);
  border-radius: 20px;
}

*::-webkit-scrollbar-thumb {
  background: var(--colour-container-featured);
  border-radius: 20px;
}

/* Headers */
/* Headlines */
/* Titles */
/* Labels */
/* Body text */
/* Components */
/* Use it as  rem(14)*/
.onboarding-loading-page__content {
  display: flex;
  flex-direction: column;
  padding: 0 0.875rem;
  align-items: center;
}
.onboarding-loading-page__content-rows {
  display: grid;
}
@media (min-width: 1280px) {
  .onboarding-loading-page__content {
    align-items: start;
  }
}
.onboarding-loading-page .header {
  margin-bottom: var(--spacing-40);
  text-align: center;
}
.onboarding-loading-page .header__title {
  font-family: var(--font-family-DIN-Next-LT-Pro);
  font-weight: var(--font-weight-bold);
  font-size: 1.75rem;
  line-height: 2.125rem;
  letter-spacing: -0.04rem;
  color: var(--colour-text-primary);
  margin-bottom: var(--spacing-8);
}
.onboarding-loading-page .header__description {
  font-family: var(--font-family-Helvetica-Neue-LT-Pro);
  font-weight: var(--font-weight-regular);
  font-size: 1rem;
  line-height: 1.625rem;
  color: var(--colour-text-secondary);
}
@media (min-width: 1280px) {
  .onboarding-loading-page .header {
    margin-bottom: var(--spacing-24);
    text-align: left;
  }
  .onboarding-loading-page .header__title {
    font-family: var(--font-family-DIN-Next-LT-Pro);
    font-weight: var(--font-weight-bold);
    font-size: 2.75rem;
    line-height: 2.875rem;
    letter-spacing: -0.03rem;
  }
  .onboarding-loading-page .header__description {
    font-family: var(--font-family-Helvetica-Neue-LT-Pro);
    font-weight: var(--font-weight-light);
    font-size: 1.25rem;
    line-height: 2rem;
  }
}
.onboarding-loading-page__image {
  margin-bottom: 1.75rem;
}
@media (min-width: 1280px) {
  .onboarding-loading-page__image {
    margin-bottom: 0;
  }
}
.onboarding-loading-page .progress-item {
  font-family: var(--font-family-Helvetica-Neue-LT-Pro);
  font-weight: var(--font-weight-regular);
  font-size: 1rem;
  line-height: 1.625rem;
  display: grid;
  margin-bottom: var(--spacing-16);
  grid-template-columns: 40px auto;
  color: var(--colour-text-primary);
}
.onboarding-loading-page .progress-item__checkmark {
  height: 1.5rem;
  width: 1.5rem;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--colour-action-primary);
  border-radius: 50%;
  color: var(--colour-global-white);
}
.onboarding-loading-page__progress-bar {
  margin-top: var(--spacing-32);
  width: 100%;
}
@media (min-width: 1280px) {
  .onboarding-loading-page__progress-bar {
    margin-top: var(--spacing-40);
  }
}

html,
.t-light {
  --filter-button-background: var(--colour-global-white);
  --filter-button-text: var(--colour-action-primary);
  --filter-button-border: var(--colour-global-white);
}

.t-dark {
  --filter-button-background: transparent;
  --filter-button-text: var(--colour-action-small);
  --filter-button-border: var(--colour-action-outline);
}

.job-search .filter-actions {
  margin-top: 2.5rem;
  margin-bottom: 0.625rem;
}
.job-search_matches .filter-actions {
  margin-top: 0;
  margin-bottom: 0;
}
.job-search .filter-button {
  width: 100%;
  margin-bottom: 0.75rem;
}
.job-search .filter-button i {
  top: 0.125rem;
  position: relative;
}
.job-search .filter-button_filter {
  border-color: var(--filter-button-border);
  color: var(--filter-button-text);
  background-color: var(--filter-button-background);
}
.job-search .filter-button_filter > span:first-child {
  position: relative;
}
.job-search .filter-button_filter i {
  position: absolute;
  font-size: 1.375rem;
  top: -0.0625rem;
}
.job-search .filter-button_filter.is-active i {
  font-size: 1rem;
  top: 0.125rem;
  left: 0.125rem;
}
.job-search .filter-button_filter > span:last-child {
  margin-left: 1.875rem;
}
.job-search .filter-button:last-child {
  margin-bottom: 0;
}
.job-search .filter-button.is-active {
  border-color: var(--colour-action-primary);
  background-color: var(--colour-action-primary);
  color: var(--colour-global-white);
}
.job-search .filter-button.is-active:hover {
  background-color: transparent;
  color: var(--colour-action-primary);
}
@media (min-width: 1280px) {
  .job-search .filter-button {
    width: unset;
    margin-bottom: 0;
    margin-right: 0.625rem;
  }
}
.job-search .popup-savesearch {
  padding: 0;
  width: 23.75rem;
  margin: 1rem 0;
}
.job-search .popup-savesearch .input-border {
  background-color: transparent;
}

.forgot-password-page__image {
  margin-bottom: 1.75rem;
}
@media (min-width: 1280px) {
  .forgot-password-page__image {
    margin-bottom: 0;
  }
}
.forgot-password-page__header {
  margin-bottom: 1.5rem;
}
@media (min-width: 1280px) {
  .forgot-password-page__header {
    margin-bottom: 1.75rem;
  }
}
.forgot-password-page__form {
  padding: 0 0.875rem;
  max-width: auto;
}
@media (min-width: 905px) {
  .forgot-password-page__form {
    max-width: 25.5rem;
  }
}
@media (min-width: 1280px) {
  .forgot-password-page__form {
    padding: 0;
  }
}
.forgot-password-page__input {
  margin-top: 1.5rem;
  margin-bottom: 2.5rem;
}
.forgot-password-page .forgot-password-page__submit-button {
  width: 100%;
}
@media (min-width: 1440px) {
  .forgot-password-page .forgot-password-page__submit-button {
    width: 60%;
  }
}
.forgot-password-page .forgot-password-page__help-link {
  font-family: var(--font-family-Helvetica-Neue-LT-Pro);
  font-weight: var(--font-weight-regular);
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--colour-action-small);
  padding-left: 0.25rem;
  text-decoration: none;
}
.forgot-password-page .forgot-password-page__help-link:hover {
  text-decoration: underline;
}
.forgot-password-page__copy {
  font-family: var(--font-family-Helvetica-Neue-LT-Pro);
  font-weight: var(--font-weight-regular);
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--colour-text-primary);
  margin-top: 1.5rem;
  font-weight: 500;
}
@media (min-width: 1280px) {
  .forgot-password-page__copy {
    margin-top: 1rem;
  }
}
@media (min-width: 1440px) {
  .forgot-password-page__copy {
    align-self: flex-start;
  }
}

.login-page__image {
  margin-bottom: 1.75rem;
}
@media (min-width: 1280px) {
  .login-page__image {
    margin-bottom: 0;
  }
}
.login-page__header {
  margin-bottom: 1.5rem;
}
@media (min-width: 1280px) {
  .login-page__header {
    margin-bottom: 1.75rem;
  }
}
.login-page__form {
  padding: 0 0.875rem;
}
@media (min-width: 905px) {
  .login-page__form {
    max-width: 25.5rem;
  }
}
@media (min-width: 1280px) {
  .login-page__form {
    padding: 0;
  }
}
.login-page__input {
  margin-bottom: 1rem;
}
.login-page .login-page__sign-in-button {
  margin-top: 2.1875rem;
  width: 100%;
}
@media (min-width: 1440px) {
  .login-page .login-page__sign-in-button {
    width: 60%;
  }
}
.login-page .login-page__register-link {
  font-family: var(--font-family-Helvetica-Neue-LT-Pro);
  font-weight: var(--font-weight-regular);
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--colour-action-small);
  padding-left: 0.25rem;
  text-decoration: none;
}
.login-page .login-page__register-link:hover {
  text-decoration: underline;
}
.login-page__copy {
  font-family: var(--font-family-Helvetica-Neue-LT-Pro);
  font-weight: var(--font-weight-regular);
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--colour-text-primary);
  margin-top: 1.5rem;
  font-weight: 500;
}
@media (min-width: 1280px) {
  .login-page__copy {
    margin-top: 1rem;
  }
}
@media (min-width: 1440px) {
  .login-page__copy {
    align-self: flex-start;
  }
}

.discover-page .carousel {
  margin-top: 2.5rem;
}
.discover-page .card-item {
  width: 18.75rem;
  margin-right: 0.75rem;
}
@media (min-width: 1280px) {
  .discover-page .card-item {
    margin-right: 1.5rem;
  }
}