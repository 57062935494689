h2 {
    font-family: Arial, Helvetica, sans-serif;
}

.assessment-card-heading {
    margin-top: 30px;
}

.assessment-card {
    /* display: flex; */
    /* margin: 30px; */

    display: grid;
    grid-template-columns: auto auto;
    column-gap: 30px;
    margin-bottom: 50px;
    z-index: 0;
}

.job-greetings {
    width: 100%;
    padding: auto 50px;
    margin-bottom: 8px;
}

/* 
.job-resume-block {
    display: flex;
} */

.task-card {
    /* display: flex; */
    /* margin: 30px; */

    display: grid;
    grid-template-columns: auto auto;
    column-gap: 30px;
    margin-bottom: 50px;
}

.resource-card {
    /* display: flex; */
    /* margin: 30px; */

    display: grid;
    grid-template-columns: auto auto;
    column-gap: 30px;
    margin-bottom: 50px;
}

.assessment-new {
    /* margin: 20px; */
}

.assessment-started {
    /* margin: 20px; */
}

.assessment-done {
    /* margin: 20px; */
}

.task-new {
    /* margin: 20px; */
}

.task-started {
    /* margin: 20px; */
}

.task-done {
    /* margin: 20px; */
}

.resource-video {
    /* margin: 20px; */
}

.resource-document {
    /* margin: 20px; */
}

.resource-article {
    /* margin: 20px; */
}

.self-discovery {
    width: 100%;
}

/* My calander css*/
.my-calander-container-row {
    background-color: var(--colour-background-primary);
    color: var(--colour-text-primary);
    padding: 30px 0;
    border-radius: 10px;
    margin-bottom: 30px;
}

.my-calander-title {
    font-size: 28px;
    color: var(--colour-text-primary);
    font-weight: bold;
}

.my-calander-description {
    color: var(--colour-text-secondary);
    font-size: 16px;
}

.my-calander-title-description {
    margin-left: 20px;
}

.my-calander-schedule-button {
    margin-top: 25px;
}

.coachContainerRow {
    background-color: var(--colour-background-primary);
    color: var(--colour-text-primary);
    padding: 30px 0;
    border-radius: 10px;
    margin-bottom: 30px;
}

.coachTitle {
    color: var(--colour-text-primary);
    font-family: 'Helvetica Neue LT Pro';
    font-size: 16px;
    padding: 8px 0;
}

.coachName {
    color: var(--colour-text-primary);
    font-family: 'Helvetica Neue LT Pro';
    font-size: 16px;
    padding-bottom: 14px;
}

.emailLink {
    color: var(--colour-text-primary);
    text-decoration: none;
}

.myCoachHeading {
    font-size: 18px;
    color: var(--colour-text-primary);
}

.myCoachSubcontent {
    color: var(--colour-text-primary);
    font-size: 16px;
}

.coachMeetingRequestContainerRow {
    background-color: var(--colour-global-grey-90);
    color: var(--colour-text-primary);
    padding: 30px 0;
    border-radius: 10px;
    margin-bottom: 30px;
}

.social-icon {
    display: flex;
}
.social-icon > div {
    cursor: pointer;
    margin-right: 8px;
}

.coachBiobuttonCollapse {
    border: solid #646E8A;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    /* margin-left: 12px; */
    margin-top: 3px;
    float: right;
    /* margin-right: 8px; */
}

.closeCoachBio {
    border: none;
    /* background: #1456FF; */
    /* color: #ffff; */
    float: right;
    margin-right: 8px;
    margin-top: -1px;
}

.coachActivButton {
    /* background: #1456FF !important; */
    /* color: #ffff !important; */
    width: auto;
}

.CoachBioInactive {
    width: auto;
}

.coachProfileImage {
    height: 128px;
    width: 140px;
}

.containerHeader {
    font-weight: 800;
    font-size: 25px;
}

.cardBody {
    margin-top: 10px;
    border-radius: 10px;
    /* padding: 10px 10px; */
    /* background-color: darkgray; */
    border: 1px solid #ECEFF5;
}

.buttonContainer {
    display: flex;
    height: 100%;
    align-items: center;
}

.viewButton {
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    background: transparent;
    border: 1px solid gray;
}

.invitationCard {
    margin-top: 10px;
    border-radius: 10px;
    padding: 20px 20px;
    padding-bottom: 25px;
    background-color: #171d35;
    color: white;
}

.invitationButtonsContainer {
    margin-top: 30px;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-column-gap: 5px;
}

.invitationButtons {
    cursor: pointer;
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    background: transparent;
    border: 1px solid gray;
    /* margin-right: 5px; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.invitationAcceptButtons {
    cursor: pointer;
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    background: var(--colour-action-primary);
    border: 1px solid gray;
    /* margin-right: 5px; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


.meetingConfirmModal section {
    height: auto;
    max-width: 20rem;
}

.CancelMeeting section>div>div {
    text-align: center;
}

.description {
    color: var(--colour-text-primary);
}

.more-collection-desc {
    color: var(--colour-text-primary);
}

.no-coach-title-Events {
    color: var(--colour-global-white);
    font-size: 28px;
    font-weight: 700;
}

.no-coach-description-Events {
    color: var(--colour-global-white);
    font-size: 16px;
}

.no-coach-title {
    color: var(--colour-text-primary);
    font-size: 28px;
    font-weight: 700;
}

.no-coach-description {
    color: var(--colour-text-secondary);
    font-size: 16px;
}

.request-meeting-button {
    margin-top: 35px !important;
}

.schedule_coaching_session-title {
    color: var(--colour-global-white);
    font-size: 28px;
    font-weight: 700;
}

.schedule_coaching_session-description {
    color: var(--colour-global-white);
    font-size: 16px;
}


.t-dark input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

.t-light input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(0);
}

.meetingScheduleModal,
.meetingScheduleModal * {
    box-sizing: border-box !important;
}

.meetingScheduleModal h5 {
    padding: 0px;
}

.meetingScheduleModal section {
    height: auto;
    max-width: 60rem !important;
    width: auto;
    padding: var(--spacing-24);
}

.meetingScheduleModal .modal-content-wrapper,
.meetingConfirmModal .modal-content-wrapper,
.meetingReScheduleModal .modal-content-wrapper {
    padding: 0px 20px 0px 0px;
}

/**/
.book-event-button {
    margin-left: 10px !important; 
}

.when-no-coach-button {
    float: right;
}

.carousel.executive-market-carousel-buttons {
    /* margin-top: 0px; */
    min-height: 80px;
}

.executive-event-button {
    float: right;
    margin-right: 100px !important;
    margin-top: -50px !important;
}

.learning-collections {
    padding: 40px 40px 40px 40px !important;
    box-sizing: border-box !important;
}

.heading_learning-collections {
    text-align: center;
}

.description_learning-collections {
    text-align: center;
    width: 768px !important;
    margin-bottom: 36px !important;
}

.more-collections p {
    width: 576px;
    text-align: center;
    color: var(--colour-text-primary);
}

.dashboard-coach-container {
    width: 100%;
    /* box-sizing: border-box !important; */
    display: flex;
}

.dash-event {
    margin-top: 20px;
}

.jobseeker-job-doc-cols .jobseeker-job-cols,
.jobseeker-job-doc-cols .jobseeker-doc-cols {
    width: auto !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.jobseeker-job-doc-cols {
    max-width: 100%;
}

.jobseeker-job-doc-cols .jobseeker-doc-cols>div>div {
    padding: var(--spacing-40)
}

.mobile-view_recommended-section {
    display: none;
}

.upload-icon-btn i {
    transform: rotate(180deg);
    display: inline-block;
}

.dashboard-coach-container .buttonDash {
    margin-bottom: 6px;
}

.dashboard_page .learning-collections {
    margin-bottom: 5px;
}

.dashboard_page .executive-market-events {
    padding-top: 20px !important;
}

.dashboard_page .my-calander-container-row {
    margin-top: 10px;
}

.dashboard_page .job-seeker-firstvisit {
    padding-bottom: 0px !important;
    padding-top: 45px !important;
}

.dashboard_page .job-seeker-returnvisit {
    /* padding-top: 65px !important; */
}

.dashboard_page .executive-market-events {
    background: var(--colour-container-on-secondary);
    border-radius: 15px;
    /* margin-top: 20px; */
    /* margin-bottom: 10px; */
}

.dashboard_page .executive-market-carousel-buttons {
    padding-left: 15px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: unset;
}

.dashboard_page .myresume_dashboard h2 {
    font-family: var(--font-family-Helvetica-Neue-LT-Pro) !important;
    font-weight: var(--font-weight-light);
}

.dashboard_page .dashboard-coach-container {
    /* margin-top: 25px; */
}

.dashboard_page .mycalender_desctiption {
    width: 570px;
}

.dashbaord-page-layout {
    display: flex !important;
    gap: var(--spacing-24);
}

.dashboard_page .dashboardnocoach-mycalendar-btn{
    display: flex;
}

@media (min-width: 1500px) {
    .dashbaord-page-layout {
        max-width: 1800px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (max-width: 912px) {
    .dashboard_page .dashboardnocoach-mycalendar-btn{
        margin-left: unset;
        margin-top: 10px;
    }
    
    .dashboard_page .mycalender_desctiption {
        width: unset;
    }

    .job-seeker_cal .buttonDash button {
        margin-top: 15px;
    }

    .dashboard_page .job-greetings>div {
        padding-top: 10px !important;
        padding-bottom: 2px !important;
    }

    .my-calander-schedule-button {
        margin-right: 45px;
    }

    .job-greetings h2,
    .job-greetings p {
        color: var(--colour-text-primary);
    }

    .mobile-view_recommended-section {
        display: flex;
        margin-top: 15px;
        gap: 10px;
    }

    .myresume_dashboard picture {
        width: 100%;
    }

    .dashboard_featureVedio picture {
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .mobile-view_recommended-section {
        display: flex;
        gap: 10px;
    }

    .self-discovery-recommnded .recommended-section .carousel>div:first-child>div:first-child {
        margin-bottom: unset !important;
    }

    .learning-collections {
        padding: 30px 30px 30px 30px !important;
        /* width: 760px !important; */
        /* overflow-x: auto; */
        box-sizing: border-box !important;
    }

    .learning-collections .description {
        /* width: 690px !important; */
    }

    .self-discovery-recommnded {
        box-sizing: border-box !important;
    }

    .resource-card {
        /* width: 700px !important; */
        /* overflow-y: scroll !important; */
        /* width: 650px !important; */
        overflow-x: auto;
        box-sizing: border-box !important;
    }

    .resource-card div:nth-child(2) {
        height: 340px !important;
        width: 226px !important;
    }

    .resource-card::-webkit-scrollbar {
        display: none !important;
    }

    .more-collections {
        width: 100% !important;
    }

    .more-collections p {
        /* width: 600px; */
    }

    .button-container {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 8px;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-right: 35px;
    }

    .nav-section .button-container {
        gap: unset;
    }

    .meetingScheduleModal section {
        width: 41% !important;
    }

    .job-greetings h2 {
        color: var(--colour-text-primary);
    }

    .job-greetings p {
        color: var(--colour-text-primary);
    }

    .dashboard-coach-container * {
        box-sizing: border-box !important;
    }

    .my-calander_container {
        box-sizing: border-box !important;
    }

    .my-calander_container .my-calander-title-description {
        width: 90%;
    }

    .skills div:nth-child(2) {
        height: unset !important;
        width: unset !important;
    }
}

@media screen and (max-width: 475px) {
    .dashboard_page .buttonEve{
        margin-bottom: 10px !important;
    }
    
    .lao-review-category{
        flex-direction: column-reverse;
    }

    .jobseeker-job-doc-cols .jobseeker-doc-cols>div>div {
        padding: var(--spacing-16)
    }

    .dashboard_page .jobseeker-doc-cols {
        margin-top: 15px;
    }

    .dashboard_page .executive-market-events {
        margin-left: -1rem;
        width: 100vw;
        border-radius: 0;
    }

    .dashboard_page .exe-cal button {
        margin-top: 15px;
    }

    .dashboard_page .myresume_dashboard button {
        width: 100% !important;
    }

    .dashboard_page .executive-market-events {
        margin-right: -10px !important;
    }

    .dashboard_page .executive-market-carousel-buttons {
        padding-left: 10px;
    }

    .description_learning-collections {
        margin-bottom: 15px !important;
    }

    .my-calander-title-description {
        margin-left: 10px;
    }

    .my-calander-title {
        margin-bottom: 8px;
    }

    .my-calander-schedule-button {
        margin-left: 5px;
        margin-right: unset;
    }

    .goto-resume-btn {
        box-sizing: border-box !important;
        width: 100% !important;
    }

    .learning-collections {
        padding: 20px 20px 20px 20px !important;
        /* width: 360px !important; */
    }

    .learning-collections .description {
        width: 375px !important;
    }

    .resource-card {
        /* width: 500px !important; */
        overflow-x: auto;
    }

    .resource-card div:nth-child(2) {
        height: 340px !important;
        width: 226px !important;
    }

    .skills div:nth-child(2) {
        height: unset !important;
        width: unset !important;
    }

    .more-collections {
        align-items: flex-start !important;
    }

    .more-collections h5 {
        margin: 0 !important;
    }

    .more-collections p {
        width: 320px;
        text-align: unset;
    }

    .button-container {
        width: 100% !important;
        justify-content: flex-start;
    }

    .meetingScheduleModal section {
        width: auto !important;
        height: 100%;
        padding: 0px;
    }

    .meetingScheduleModal h5 {
        padding: var(--spacing-24);
        border-bottom: 1px solid var(--colour-border-hairline);
    }

    .meetingScheduleModal .modal-content-wrapper {
        padding: 0px var(--spacing-24);
    }

    .my-calander_container .my-calander-schedule-button {
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
    }

    .heading_learning-collections {
        text-align: unset;
    }

    .description_learning-collections {
        text-align: unset;
    }

    .job-greetings h2 {
        word-break: break-word;
    }

    .self-discovery-recommnded {
        padding-left: 0px !important;
    }
}

@media screen and (max-width: 375px) {
    .mobile-view_recommended-section button {
        font-size: 0.9rem !important;
    }

    .recommended-section .card {
        min-width: 300px !important;
        /* margin-right: 8px !important; */
    }

    .learning-collections {
        padding: 15px 15px 15px 15px !important;
    }

    .learning-collections .description {
        width: 310px !important;
    }

    .more-collection {
        padding-left: unset;
    }

    .more-collections p {
        width: 310px;
    }

    .button-container {
        width: 100% !important;
    }

    .meetingScheduleModal section {
        /* width: 83% !important; */
    }
}