/* * {
    font-family: var(--font-family-Helvetica-Neue-LT-Pro);
} */

.fc-day-today {
    background-color: inherit !important;
}

.fc-button-group>button:first-child:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    border-color: #d0d1d1;
    padding: 10px;
}

.fc-button-group>button:last-child:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    border-color: #d0d1d1;
    padding: 10px;

}

.fc-direction-ltr .fc-button-group > .fc-button:not(:first-child)
{
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-color: #d0d1d1;
    padding: 10px;
}

.fc-button-primary {
    background-color: #e6e6e6 !important;
    color: #4059fa !important
}

.fc-button-active {
    color: white !important;
    background: #31344C !important
}

.fc-theme-standard {
    /* height: 602px !important; */
    /* width: 1200px !important; */
    width: 100%;
    /* height: 100%; */
    max-width: 1200px;
    height: 700px;
    background: transparent;
    padding: 5px !important;
}

.fc-daygrid-day {
    background-color: white !important;
}

.fc-day-other {
    background-color: #ddd !important;
}

.fc-today-button, .fc-prev-button, .fc-next-button  {
    text-transform: capitalize !important;
}

.fc-dayGridMonth-button, .fc-timeGridDay-button, .fc-timeGridWeek-button  {
    text-transform: capitalize !important;
}

.fc-button
{
    line-height: 1 !important;
}

.fc-day-today {
    /* background: rgba(255, 219, 40, 0.253) !important; */
    border: none !important;
    color: rgb(14, 13, 13)    !important;
  
} 


.fc-col-header 
{
    border: 1px !important;
    text-decoration: none;
}

.fc-daygrid-day-number, .fc-col-header-cell-cushion {
    color :black;
    text-decoration: none;
}

.fc-daygrid-event, fc-daygrid-dot-event, fc-event, fc-event-start, fc-event-end, fc-event-past
{
    display: block;
    position: relative;
    padding-left: 15px;
}

.fc-daygrid-event-dot
{
    position: absolute;left: -3px ;top: 8px ;
}

.fc-theme-standard .fc-popover {
    border: none !important;
}

.fc .fc-more-popover .fc-popover-body {
    background-color: var(--colour-background-primary);
}

.t-light .fc-theme-standard .fc-popover-header {
    background: var(--colour-background-secondary);
}
.t-dark .fc-theme-standard .fc-popover-header {
    color: var(--colour-text-primary);
    background: var(--colour-background-secondary);
}

.fc-theme-standard td {
    background-clip: padding-box;
}

.fc-timegrid-event-short .fc-event-time:after {
    content: "";
}

@media (min-width: 768px) and (max-width: 992px) {
    .fc-theme-standard {
        height: 600px;
    }
}

@media (max-width: 600px) {
    .fc-theme-standard {
        height: 650px;
    }
    .fc .fc-toolbar{
        flex-direction: column;
        gap: 15px;
    }
    .fc-theme-standard td div {
    }
    .fc-theme-standard .fc-daygrid-day .fc-daygrid-day-frame .fc-daygrid-day-events * {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .fc-timeGridWeek-view .fc-col-header th a {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
    }
}