/* css for a) */
ul.lower-alpha {
    list-style-type: none;
    counter-reset: list; 
  }
  ul.lower-alpha li {
    counter-increment: list; 
  }
  ul.lower-alpha li::marker {
    content: counter(list, lower-alpha) ') '; 
  }
/* end css for a) */

 /* css for (1) */
 ol.numbered-parentheses {
    list-style: none; 
    counter-reset: list; 
  }
  ol.numbered-parentheses li {
    counter-increment: list; 
  }
  ol.numbered-parentheses li::marker {
    content: '(' counter(list) ') ';
  }
  /* end css for (1) */

.arrow{
    list-style-type: '➢ ';
}
.no-bullets{
    list-style-type: none;
}
.underline{
    text-decoration: underline;
} 

.privacy-content{
    overflow-x: auto;
}
.privacy-content table, th, td {
    border: 1px solid black !important;
  }

  .privacy-content .WordSection1{
    color: rgb(104, 104, 104);
  }

  .privacy-content span {
    font-family: Arial, sans-serif !important;
  }
  
  .privacy-content li {
    font-family: Arial, sans-serif !important;
  }
  .termsofuse{
    text-align: justify;
    .t-dark & {
        color: #fff !important;
       }
  }
  .ft-size{
    font-size: 14pt;
  }
  @media print {
    .privacy-content {
      overflow-x: visible;
      width: auto;
    }
  }

  .txt-center{
  text-align: center;
  }
  .terms-use{
    text-align: justify;
  }

