/* .uploadButtonWrap {
    padding: 24px 35px;
    cursor: pointer;
    background: var(--colour-background-secondary);
    border: 2px dashed var(--colour-action-outline);
    border-radius: 15px;
    text-align: center;
    margin-bottom: 15px;
} */
.uploadIconWrap {
    width: 42px;
    height: 42px;
    background: #FFFFFF;
    border-radius: 50%;
    line-height: 47px;
    display: inline-block;
    margin-bottom: 10px;
}
.uploadText {   
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    color: #646E8A;
    margin: 0;
}
.uploadText p {
    margin: 0;
}

.fileWrap {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #DEE1EA;
    padding: 10px 0px;
    gap: 20px;
}
.fileName {
    font-size: 16px;
    color: var(--colour-text-primary);
}
.fileTime {
    font-size: 12px;
    color: var(--colour-text-primary);
}
.errorMsg {
    font-size: 12px;
    color: #9D323D;
}
.fileSize {
    font-size: 12px;
    color: var(--colour-text-primary);
    padding: 7px 12px;
    border: 1px solid #DEE1EA;
    line-height: 10px;
}
.errorStatus {
    font-size: 12px;
    color: #9D323D;
    padding: 7px 12px;
    border: 1px solid #DEE1EA;
    line-height: 10px;
    background: #F9EFEF;
}
.deleteIcon {
    height: 26px;
    border: 0;
    background: none;
    padding: 4px 5px;
    cursor: pointer;
    margin-left: 8px;
}
.tickCrossIcon {
    position: absolute;
    top: -1px;
    left: 0;
}
.fileuploadloader {
    position: absolute;
    top: 3px;
    left: -3px;
}
.itemInfo {
    padding-left: 20px;
}
.fileLeft {
    position: relative;
}
.fileRight {
    display: flex;
    align-items: center;
}
.reUploadIcon {
    margin-right: 10px;
    border: 0;
    background: none;
    padding: 7px 5px;
    cursor: pointer;
    height: 26px;
    line-height: 0;
}
.footerButton {
    padding: 25px 0px 0 0;
    text-align: center;
}
.footerButton button {
    margin: 0 5px;
}


.filesItemsWrapper {
    padding-bottom: 5px;
}


.uploadWrapper{
    width: 500px;
}

.uploadWrapper p {
    color: var(--colour-text-primary);
}

@media screen and (max-width: 425px) {
    .uploadWrapper{
        width: unset;
    }
}

@media screen and (max-width: 375px) {
    .footerButton{
        display: flex;
    }
    .footerButton button span {
        margin-right: 1px !important;
        margin-left: 8px !important;
    }

    .itemInfo{
        padding-left: 20px;
    }

    .fileSize{
        margin-left: 8px;
    }
}



















#form-file-upload {
    /* height: 16rem;
    width: 28rem;
    max-width: 100%;
    text-align: center; */
    position: relative;
    margin-top: 10px;
  }
  
  #input-file-upload {
    display: none;
  }
  
  #label-file-upload {
    /* background: #eceff5; */
    background-color: var(--colour-background-secondary);
    /* border: 3px dashed #dee1ea; */
    border: 2px dashed var(--colour-action-outline);
    border-radius: 15px;
    cursor: pointer;
    margin: 0 !important;
    padding: 35px 35px 15px 35px;
    text-align: center;
    width: 100%;
    box-sizing: border-box !important;
  }
  
  #label-file-upload.drag-active {
    background-color: #ffffff;
  }
  
  .upload-button {
    cursor: pointer;
    padding: 0.25rem;
    font-size: 1rem;
    border: none;
    font-family: 'Oswald', sans-serif;
    background-color: transparent;
  }
  
  .upload-button:hover {
    text-decoration-line: underline;
  }
  
  #drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}
.uploadIconWrap {
    width: 42px;
    height: 42px;
    background: #FFFFFF;
    border-radius: 50%;
    line-height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px auto 10px;
}