.myCoach,
.myCoach * {
    box-sizing: border-box !important;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
display: block;
padding: var(--spacing-16) var(--spacing-12) var(--spacing-16) var(--spacing-20);
border: var(--colour-action-outline) var(--border-thickness-thin) solid;
width: 100%;
}

.react-datepicker-wrapper input[type="text"]{
    border: none;
    font-size: 16px;
    font-family: var(--font-family-Helvetica-Neue-LT-Pro);
    width: 100%;
    z-index: 1;
    background: transparent;
    color: var(--colour-action-disabled-text);
    -webkit-text-fill-color: var(--colour-action-disabled-text);
}

.react-datepicker-wrapper input[type="text"]:focus{
    outline-width: 0;
    outline: 0;
}

.react-datepicker-wrapper .react-datepicker__input-container::after {
    background-image: url("./calendar_schedule_icon.png");
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background-size: cover;
    top: 18px;
    right: 10px;
    z-index: -1;
}
.react-datepicker-wrapper .react-datepicker__input-container {
    z-index: 2;
}

.coachContainerRow {
    background-color: var(--colour-background-primary);
    color: var(--colour-text-primary);
    padding: 30px;
    border-radius: 10px;
    margin-bottom: var(--spacing-24);
}

.nocoach-container .coachContainerRow {
    padding: 20px 0;
}

.buttonDash {
    background-color: var(--colour-background-primary);
    color: var(--colour-text-primary);
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 20px;
    border-radius: 10px;
    margin-bottom: 30px;
}

.buttonEve {
    margin-right: 5px !important;
    margin-left: 100px !important;
}

.coachTitle {
    color: var(--colour-text-primary);
    font-family: 'Helvetica Neue LT Pro';
    font-size: 16px;
    padding: 8px 0;
}

.coachName {
    color: var(--colour-text-primary);
    font-family: 'Helvetica Neue LT Pro';
    font-size: 16px;
    padding-bottom: 14px;
}

.emailLink {
    color: var(--colour-text-primary);
    text-decoration: none;
}

.myCoachHeading {
    font-size: 18px;
    color: var(--colour-text-primary);
}

.myCoachSubcontent {
    color: var(--colour-text-primary);
    font-size: 16px;
}

.coachMeetingRequestContainerRow {
    background-color: var(--colour-container-featured);
    color: var(--colour-text-primary);
    padding: 30px 0;
    border-radius: 10px;
    margin-bottom: var(--spacing-24);
}

.social-icon {
    display: flex;
}
.social-icon > div {
    cursor: pointer;
    margin-right: 8px;
}

.coachBiobuttonCollapse {
    border: solid #646E8A;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    /* margin-left: 12px; */
    margin-top: 3px;
    float: none;
    position: relative;
    top: 2px;
    left: 10px;
    vertical-align: super;
    /* margin-right: 8px; */
}

.closeCoachBio {
    border: none;
    /* background: #1456FF; */
    /* color: #ffff; */
    float: right;
    margin-right: 8px;
    margin-top: -1px;
}

.coachActivButton {
    /* background: #1456FF !important; */
    /* color: #ffff !important; */
    width: auto;
}

.CoachBioInactive {
    width: auto;
}

.coachMessageBorder {
    border-top: 1px solid var(--colour-border-hairline);
}

.coachProfileImage {
    max-height: 128px;
    max-width: 128px;
    width: 100%;
    object-fit: contain;
}

.font-weight-bold {
    font-weight: var(--font-weight-bold);
}

.noCoach-container {
    padding-left: 40px !important;
}

.containerHeader {
    font-weight: 700;
    font-size: 28px;
    /* font-family: var(--font-family-DIN-Next-LT-Pro); */
    /* letter-spacing: -1px; */
}

.cardBody {
    margin-top: 5px;
    border-radius: 8px;
    padding: 12px;
    /* background-color: darkgray; */
    /* border: 1px solid #ECEFF5; */
    border: 1px solid var(--colour-action-outline);
    box-sizing: border-box !important;
}

.cardBody .row {
    justify-content: space-between;
}

.invitation-title {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: var(--spacing-8);
}

.invitation-card-timings {
    font-size: 16px;
    line-height: 26px;
}

.t-dark .cardBody {
    border: 1px solid var(--colour-border-hairline);
}

/* .cardBodyLight button {
    background-color: var(--colour-background-primary-transparent);
} */

.cardBodyLight {
    background-color: var(--colour-background-secondary);
}


.buttonContainer {
    display: flex;
    height: 100%;
    align-items: center;
}

.viewButton {
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    background: transparent;
    border: 1px solid gray;
}

.invitationCard {
    margin-top: 6px;
    border-radius: 10px;
    padding: 20px 20px;
    padding-bottom: 25px;
    background-color: #171d35;
    color: white;
}

.invitationButtonsContainer {
    margin-top: 30px;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-column-gap: 5px;
}

.invitationButtonsOLD {
    cursor: pointer;
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    background: transparent;
    border: 1px solid gray;
    /* margin-right: 5px; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.invitationButtons {
    background: transparent !important;
    color: white !important
}

.meetingConfirmModal {
    color: var(--colour-text-primary);
}

.meetingNoTimeSlotAvailable {
    color: var(--colour-text-primary);
}

.meetingConfirmModal,
.meetingConfirmModal *,
.meetingReScheduleModal,
.meetingReScheduleModal * {
    box-sizing: border-box !important;
}

label.container,
label.container * {
    box-sizing: revert !important;
}

.meetingConfirmModal section {
    height: auto;
    max-width: 20rem;
    width: 354px;
    padding: var(--spacing-24);
}

.meetingReScheduleModal section {
    height: auto;
    max-width: none;
    width: auto;
    padding: var(--spacing-24);
}

.meetingConfirmModal section .no-coach-meeting-header {
    /* padding: 0px; */
}

.meetingConfirmModal section .no-coach-meeting-header+div {
    /* max-width: 100%; */
}

.meetingConfirmModal section #PreferredDate {
    font-family: var(--font-family-Helvetica-Neue-LT-Pro);
}

.CancelMeeting section>div>div {
    text-align: center;
}



.no-coach-title-Events {
    color: var(--colour-global-white);
    font-size: 28px;
    font-weight: 700;
}

.no-coach-description-Events {
    color: var(--colour-global-white);
    font-size: 16px;
}

.no-coach-title {
    color: var(--colour-text-primary);
    font-size: 28px;
    font-weight: 700;
    line-height: 34px;
    margin-top: 12px;
}

.no-coach-description {
    color: var(--colour-text-secondary);
    font-size: 16px;
    margin-top: 10px;
}

.request-meeting-button {
    margin-top: 0px !important;
}

.schedule_coaching_session-title {
    font-family: var(--font-family-DIN-Next-LT-Pro);
    letter-spacing: -1px;
    color: var(--colour-global-white);
    font-size: 28px;
    font-weight: 700;
}

.schedule_coaching_session-description {
    color: var(--colour-global-white);
    font-size: 16px;
}


.t-dark input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

.t-light input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(0);
}

.d-block {
    display: "block";
}

.d-none {
    display: "none";
}

.meetingScheduleModal .fc-theme-standard,
.meetingReScheduleModal .fc-theme-standard {
    /* width: 820px !important; */
    width: 100% !important;
    height: 100% !important;
    overflow: hidden !important;
    background-color: var(--colour-background-secondary);
    padding: 10px 10px 0px 10px !important;
    border-radius: 10px;
}


.meetingReScheduleModal .fc-theme-standard {
    /* width: 820px !important; */
    /* height: 160vh !important; */
    /* width: 100% !important; */
    /* height: 100% !important; */
    /* overflow: hidden !important */
}

.upcoming-meeting,
.upcoming-meeting * {
    box-sizing: border-box !important;
}

.upcoming-meeting section {
    max-width: 354px;
    width: 100%;
}

.upcoming-meeting-title {
    font-size: 28px;
    font-weight: var(--font-weight-bold);
    font-family: var(--font-family-DIN-Next-LT-Pro);
    margin-bottom: 1rem;
}

.meetingReScheduleModal h5,
.meetingScheduleModal h5,
.meetingConfirmModal h5 {
    border-bottom: 1px solid var(--colour-border-hairline);
    padding-bottom: 10px;
    padding-left: 20px;
    margin: 0px -20px;
}

.upcoming-meeting h5 {
    border-bottom: 0px;
    font-size: 16px;
    font-weight: 400;
    padding-bottom: 7px;
}

.upcoming-meeting .modal-content-wrapper {
    margin-top: 0px;
}

.scheduler-column {
    max-width: 280px;
}

.meetingReScheduleModal label.container {
    margin: 5px 0;
}

.meetingReScheduleModal h4 {
    font-size: 14px;
    font-weight: 400;
    color: var(--colour-text-primary);
}

.meetingScheduleModal .meetingNoTimeSlotAvailable,
.meetingReScheduleModal .meetingNoTimeSlotAvailable {
    font-size: 14px;
    font-weight: 400;
}

.meetingScheduleModal .fc table,
.meetingReScheduleModal .fc table {
    font-size: 14px;
}

.meetingScheduleModal .fc .fc-toolbar-title,
.meetingReScheduleModal .fc .fc-toolbar-title {
    font-size: 18px;
}

.meetingScheduleModal .fc-button-group>button:first-child:not(:last-child),
.meetingReScheduleModal .fc-button-group>button:first-child:not(:last-child) {
    padding: 7px 15px;
}

.meetingScheduleModal .fc-direction-ltr .fc-button-group>.fc-button:not(:first-child),
.meetingReScheduleModal .fc-direction-ltr .fc-button-group>.fc-button:not(:first-child) {
    padding: 7px 15px;
}

.meetingScheduleModal .fc .fc-button,
.meetingReScheduleModal .fc .fc-button {
    font-size: 14px;
}

.meetingReScheduleModal .date-input input,
.meetingReScheduleModal .date-input input {
    font-family: var(--font-family-Helvetica-Neue-LT-Pro);
}

.schedule_meeting {
    margin-left: var(--spacing-24);
}

.font-color-secondary {
    color: var(--colour-text-secondary);
}

/* .coach-bio-section.row {
    margin-top: -50px;
    margin-bottom: 10px;
} */

.coach-session {
    background-color: var(--colour-background-primary);
    border-radius: 15px;
}

.coach-bio-section .expand-bio {
    margin-top: var(--spacing-32);
}

.coach-bio-section .coach-bio-message-position {
    /* margin-left: calc(var(--bs-gutter-x) * -1.25); */
    margin-bottom: 20px;
    margin-top: var(--spacing-24)
}

.date-time-color {
    color: var(--colour-action-disabled-text);
}

.t-dark .date-time-color {
    color: var(--bs-gray-500);
}

input[type=date] {
    font-family: var(--font-family-Helvetica-Neue-LT-Pro);
}

.maskImg {
    mask: url(../../assets/svg/arrow_banner.svg) no-repeat;
    -webkit-mask-position-x: left;
    background-size: cover !important;
}

@media (min-width: 1500px) {
    .coach-bio-section .coach-bio-message-position {
        /* margin-left: calc(var(--bs-gutter-x) * -2); */
    }
}

@media (max-width: 1024px) {
    .coach-bio-section.row {
        margin-top: -30px;
    }
}

@media screen and (max-width: 992px) {
    .myCoach .coachName {
        padding-bottom: 5px;
    }

    .coach-bio-section .coach-bio-message-position {
        margin-top: 12px;
        margin-bottom: 12px;
    }

    .coach-bio-section .coach-bio-btn {
        padding-left: 6px;
        margin-top: 6px;
    }

    .coach-bio-section .expand-bio {
        margin-top: 10px;
    }

    .meetingReScheduleModal section {
        width: 41%;
    }

    .schedule_coaching_session-title {
        font-size: 18px;
        margin-bottom: 5px;
    }

    .request-meeting-button {
        margin-top: 20px !important;
    }

    .coachMeetingRequestContainerRow {
        padding: 15px;
    }

    .coachProfileImage {
        width: 100px;
    }

    .coach-bio-section.row {
        margin-top: 0px;
    }

    .coach-bio-section .coach-bio-message-position {
        margin-left: 0;
    }

    /* .expand-bio .row:last-child .coach-bio-message-position {
        margin-bottom: 0px;
    } */

    .coach-column-layout {
        margin-top: 0px !important;
        padding-top: var(--spacing-24) !important;
    }

    .nocoach-container .coachContainerRow {
        margin-top: 18px !important;
        padding: 25px !important;
    }

    .nocoach-request_meeting {
        padding-left: unset !important;
    }

    .noCoach-container {
        display: none;
    }

    .no-coach-title {
        font-size: 18px;
    }

    .schedule_meeting {
        margin-left: unset;
    }

    .coachContainerRow {
        padding-left: 20px;
    }

    .buttonDash {
        padding-left: 20px;
    }

    .buttonEve {
        margin-right: 5px !important;
        margin-left: 5px !important;
    }

    .meetingScheduleModal * {
        box-sizing: border-box !important;
    }

    .meetingScheduleModal .fc-theme-standard {
        width: 100% !important;
        height: 160vh !important;
    }

    .meetingScheduleModal .checkmark::after {
        left: 0.08rem;
        top: 0.09rem;
    }

    .meetingScheduleModal .fc-header-toolbar,
    .meetingReScheduleModal .fc-header-toolbar {
        display: flex;
        flex-direction: column-reverse;
        gap: 10px;
    }

    .meetingReScheduleModal .fc-theme-standard {
        width: 100% !important;
        height: 160vh !important;
    }

    /* .meetingReScheduleModal .modal-content-wrapper>div>div {
        width: 95%;
    } */

    .invitationButtonsContainer {
        grid-template-columns: 25% 25% 25%;
    }

    .invitationCard {
        margin-bottom: var(--spacing-24);
    }
}

@media screen and (max-width: 475px) {
    .invitationButtonsContainer {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: space-around;
    }

    .invitationButtonsContainer button:first-child {
        width: 100%;
    }

    .invitationButtons {
        max-width: 45%;
        /* margin-top: 20px !important; */
    }

    .coachProfileImage {
        width: 64px;
    }

    .coachTitle {
        font-size: 14px;
    }

    .meetingConfirmModal section,
    .meetingReScheduleModal section {
        width: auto;
        max-width: none;
        padding: 0px;
    }

    .mobile-bg {
        background-color: var(--colour-background-secondary);
    }

    .meetingConfirmModal h5,
    .meetingReScheduleModal h5,
    .meetingScheduleModal h5 {
        font-size: 18px;
        font-weight: var(--font-weight-bold);
        font-family: var(--font-family-Helvetica-Neue-LT-Pro);
        letter-spacing: normal;
        padding: var(--spacing-24) 0px var(--spacing-24) var(--spacing-24);
        margin: 0px;
    }

    .meetingReScheduleModal h5 {
        /* padding: var(--spacing-24) 0px var(--spacing-24) var(--spacing-24); */
        /* font-weight: var(--font-weight-bold); */
        /* font-size: 18px; */
    }

    .meetingScheduleModal h5 {
        /* padding: var(--spacing-24); */
        /* font-weight: var(--font-weight-bold); */
        /* font-size: 18px; */
        margin: 0px;
    }

    .scheduler-column {
        max-width: none;
    }

    .meetingConfirmModal .modal-content-wrapper,
    .meetingReScheduleModal .modal-content-wrapper {
        padding: 0px var(--spacing-24) var(--spacing-8);
        max-height: 100% !important;
    }

    .meetingReScheduleModal .fc-theme-standard {
        width: 100% !important;
    }

    .modal-content-wrapper label {
        line-height: 28px;
    }

    .meetingReScheduleModal .modal-content-wrapper>div>div {
        width: 94%;
    }

    .meetingConfirmModal .modal-content-wrapper h2 {
        font-family: var(--font-family-DIN-Next-LT-Pro);
        font-size: 18px;
        font-weight: var(--font-weight-bold);
        margin-bottom: 10px;
    }

    .coachContainerRow {
        padding-left: 5px;
        padding-right: 5px;
    }

    .nocoach-container .coachContainerRow {
        margin-top: 15px !important;
        padding: 25px !important;
    }

    .buttonDash {
        padding-left: 5px;
    }

    .buttonEve {
        margin-right: 1px !important;
        margin-left: 1px !important;
    }  
    
}