button,
input,
optgroup,
select,
textarea {
    font-family: revert;
    font-size: revert;
    line-height: revert;
}

div {
    scrollbar-width: thin;
    scrollbar-color: var(--colour-action-primary) var(--colour-container-on-primary) !important;
}

div::-webkit-scrollbar-thumb {
    scrollbar-width: thin;
    scrollbar-color: var(--colour-action-primary) var(--colour-container-on-primary) !important;
    background: var(--colour-action-primary)
}

/* My Documets - View Modal popup css */
.viewPopup {
    padding: 10px 20px !important;
    min-width: 140px;
}

.viewLinks {
    /* color: var(--colour-action-secondary-text) !important; */
    display: block;
    text-decoration: none !important;
    font-weight: var(--font-weight-regular) !important;
}

.viewLinks+.viewLinks {
    margin-top: var(--spacing-16);
}

.viewLinks:hover {
    /* color: var(--colour-action-primary) !important; */
    text-decoration: underline !important;
}

.moreBtn {
    position: absolute;
    top: 20px;
    right: 130px;
    right: 90px;
    font-size: 20px;
    background: transparent none !important;
    border: none !important;
    color: var(--colour-global-white) !important;
}

.document-viewer-modal section {
    max-width: none;
    width: 80%;
    height: 100%;
    max-height: 95%;
}

.document-viewer-modal .document-name+div {
    max-width: 96%;
    /* width: 100%; */
    height: 100%;
    max-height: 95%;
    padding: 0 var(--spacing-16) 0 var(--spacing-16);
}

/*********************************************************/

.parsedHeadings {
    font-family: var(--font-family-Helvetica-Neue-LT-Pro);
    font-weight: var(--font-weight-regular);
    font-size: 1.125rem;
    line-height: 1.75rem;
    -webkit-letter-spacing: 0rem;
    -moz-letter-spacing: 0rem;
    -ms-letter-spacing: 0rem;
    letter-spacing: 0rem;
    color: var(--colour-text-primary);
    margin-bottom: var(--spacing-4);
}

.parsedTags,
button.parsedTags {
    font-family: var(--font-family-Helvetica-Neue-LT-Pro);
    font-weight: var(--font-weight-regular);
    font-size: 1rem;
    line-height: 1.625rem;
    -webkit-letter-spacing: 0rem;
    -moz-letter-spacing: 0rem;
    -ms-letter-spacing: 0rem;
    letter-spacing: 0rem;
    color: var(--colour-text-primary);
    background-color: var(--colour-background-secondary);
    padding: var(--spacing-4) var(--spacing-12);
    border-radius: var(--border-radius-softly-rounded);
    border: 1px solid var(--colour-action-outline);
    max-width: calc(100% - 3rem);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

button.parsedTags:before {
    /* padding: var(--spacing-2) var(--spacing-12); */
    content: "\e90d";
    font-family: "icomoon" !important;
    font-style: normal;
    font-weight: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    margin-right: 8px;
}

button.parsedTags:hover {
    background: var(--colour-background-secondary);
    border-color: var(--colour-action-primary);
    color: var(--colour-text-primary);
}

button.parsedTags span+span {
    margin-left: var(--spacing-8) !important;
}

.parsedContainer,
.react-tags__selected {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-column-gap: var(--spacing-16);
    column-gap: var(--spacing-16);
    row-gap: var(--spacing-10);
    padding: var(--spacing-10) 2rem var(--spacing-12) 0;
    border-bottom: 1px solid var(--colour-border-hairline);
    margin-bottom: var(--spacing-24);
    position: relative;
}

.react-tags__selected {
    border-bottom: 0px;
    margin-bottom: 0px;
}

.react-tags {
    border-bottom: 1px solid var(--colour-border-hairline);
    padding-bottom: var(--spacing-10);
    margin-bottom: var(--spacing-24);
}

.editIcon {
    position: absolute;
    top: calc(50% - 0.875rem);
    right: 0;
    background: none;
    border: none !important;
    font-size: 1.125rem;
    color: var(--colour-action-feature) !important;
    -webkit-transition: color var(--transition-timing-function-default) var(--transition-speed-normal) !important;
    transition: color var(--transition-timing-function-default) var(--transition-speed-normal) !important;
}

.editIcon:hover {
    color: var(--colour-action-disabled-text) !important;
}

.editIcon:focus-visible {
    outline: none;
}

.editIcon i {
    padding-bottom: 0.125rem;
    border-bottom: 2px solid var(--colour-action-feature);
    -webkit-transition: border-color var(--transition-timing-function-default) var(--transition-speed-normal);
    transition: border-color var(--transition-timing-function-default) var(--transition-speed-normal);
}

.editIcon:hover i {
    border-color: var(--colour-action-disabled-text);
}

.addIcon {
    font-size: 0.75rem;
    color: var(--colour-action-feature) !important;
    background: none !important;
    border: 2px solid var(--colour-action-feature) !important;
    border-radius: var(--border-radius-pill) !important;
    width: 1.5rem !important;
    height: 1.5rem !important;
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-pack: center !important;
    -webkit-justify-content: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-align-items: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    position: absolute;
    top: calc(50% - 0.875rem);
    right: 0;
    -webkit-transition: color var(--transition-timing-function-default) var(--transition-speed-normal) !important;
    transition: color var(--transition-timing-function-default) var(--transition-speed-normal) !important;
}

.addIcon:hover {
    background-color: var(--colour-action-primary) !important;
    color: var(--colour-action-primary-text) !important;
    border: 2px solid var(--colour-action-primary) !important;
}

.deleteIcon {
    outline: none;
}

.react-tags__search-input {
    border: 1px solid var(--colour-border-hairline);
    background: none;
    font-family: var(--font-family-Helvetica-Neue-LT-Pro);
    font-weight: var(--font-weight-regular);
    font-size: 1rem;
    line-height: 1.625rem;
    -webkit-letter-spacing: 0rem;
    -moz-letter-spacing: 0rem;
    -ms-letter-spacing: 0rem;
    letter-spacing: 0rem;
    color: var(--colour-text-primary);
    padding: var(--spacing-4) var(--spacing-12);
}

.react-tags__search {
    position: relative;
}

.react-tags__search-input:focus-visible {
    outline: none;
}

.react-tags__suggestions {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 1;
}

.react-tags__suggestions ul {
    margin: 4px -1px;
    padding: 0;
    list-style: none;
    /* background: white; */
    background: var(--colour-background-primary);
    border: 1px solid #d1d1d1;
    border-radius: 2px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 6px 8px;
    font-family: var(--font-family-Helvetica-Neue-LT-Pro);
    font-weight: var(--font-weight-regular);
    font-size: 1rem;
    line-height: 1.625rem;

    color: var(--colour-text-primary);
}

.react-tags__suggestions li mark {
    /* text-decoration: underline; */
    background: none;
    color: var(--colour-action-feature);
    padding: 0;
    margin: 0;
    /* font-weight: 600; */
}

.react-tags__suggestions li:hover {
    cursor: pointer;
    background: #eee;
}

.t-dark .react-tags__suggestions li:hover {
    background: #282A32;
}

.react-tags__suggestions li.is-active {
    background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
    opacity: 0.5;
    cursor: auto;
}

.react-tags__search {
    display: none;
}

.editableInput {
    /* border: 1px solid var(--colour-action-outline);
    font-family: var(--font-family-Helvetica-Neue-LT-Pro);
    font-weight: var(--font-weight-regular);
    font-size: 1rem;
    line-height: 1.625rem;
    padding: var(--spacing-4) var(--spacing-12); */
    max-width: 50%;
    width: 100%;
}

.editableInput:focus-visible {
    outline: none;
}

.location,
.country, .distance {
    position: relative;
}

.locationDropdown,
.countryDropdown {
    list-style: none;
    border: 1px solid var(--colour-action-outline);
    font-family: var(--font-family-Helvetica-Neue-LT-Pro);
    font-weight: var(--font-weight-regular);
    background-color: var(--colour-background-primary);
    color: var(--colour-text-primary);
    font-size: 1rem;
    line-height: 1.625rem;
    position: absolute;
    top: 100%;
    left: 0px;
    width: 97%;
    z-index: 1;
    max-height: 200px;
    overflow: auto;
}

.locationDropdown li,
.countryDropdown li {
    cursor: pointer;
}

.locationDropdown li:hover,
.countryDropdown li:hover {
    color: var(--colour-action-feature);
}

#footer-legal-content>div>p>div>p>a {
    color: #ECEFF5 !important;
}

.relativePosition {
    position: relative;
}

.uploadWrapper {
    font-family: var(--font-family-Helvetica-Neue-LT-Pro);
}

.notificationRadio {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    padding: var(--spacing-16) var(--spacing-12) var(--spacing-16) var(--spacing-20);
    box-sizing: border-box;
    border: var(--colour-action-outline) var(--border-thickness-thin) solid;
    border-radius: var(--border-radius-very-softly-rounded);
    margin-bottom: var(--spacing-8);
}

.search {
    position: relative;
}

.spinner-center {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.matchesTab #searchResultCount {
    visibility: hidden;
}

.search-box label {
    white-space: nowrap;
}

.savedTab #searchResultCount {
    width: 100%;
}

.start-bookmarking-section .start-section {}

.dashboard_message * {
    box-sizing: border-box !important;
}

/* .filterResult fieldset > div input[type='checkbox'] {
    visibility: hidden;
} */
.onboarding-questions {
    margin-top: 30px;
}

.onboarding-questions-back-btn {
    position: absolute;
    top: 5px;
    left: 25px;
    opacity: 0;
    display: none;
}

.onboarding-questions-wrapper {
    flex-direction: column;
}

.onboarding-questions-wrapper .questions-container {
    display: none;
}

.mobile-menu {
    background: var(--colour-background-primary) url('../src/assets/svg/arrow.svg') no-repeat !important;
    background-size: 100% !important;
    /* background-position: 30vw 65vh !important; */
    background-position: right -200px top 65vh !important;
}

.document-table .no-documents {
    color: var(--colour-text-primary);
    margin-top: 15px;
}

.ourPolicy .container-heading {
    margin-left: -80px;
}

.ourPolicy .WordSection1 {
    margin-left: -80px;
    width: 50vw;
}

.ourPolicy .WordSection1 table {
    margin-top: 15px;
    /* width: 30%; */
}

.ourPolicy .WordSection1 span,
p {
    /* color: var(--colour-text-primary); */
}

/* .scheduleMeetingTitle ::-webkit-input-placeholder,
.scheduleMeetingTitle ::-moz-placeholder,
.scheduleMeetingTitle ::-ms-input-placeholder, */
.scheduleMeetingTitle ::placeholder {
    font-style: italic;
}

.disablePDF_dots {
    position: relative;
    height: 100%;
}

.disablePDF_dots:before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    width: 55px;
    height: 55px;
    z-index: 99;
    background-color: rgb(50, 54, 57);
}

#react-doc-viewer {
    height: 90%;
}

#react-doc-viewer #header-bar {
    display: none;
}

@media only screen and (min-width: 1500px) {
    .ourPolicy .WordSection1 {
        width: 135%;
    }
}

@media only screen and (min-width: 1280px) {
    .filterResult fieldset>div {
        flex-basis: calc(25% - 0.8rem);
    }

    .filterResultResources fieldset>div {
        flex-grow: 1;
    }

    .dashboard_message {
        position: absolute;
        top: 0;
        left: 80;
        width: 100%;
        z-index: 1000;
    }
    .dashboardBanner:before {
        content: "";
        position: absolute;
        bottom: 0px;
        right: 0px;
        background: url(assets/svg/arrow_top.svg) no-repeat;
        background-size: cover;
        max-width: 750px;
        width: 100%;
        height: 100%;
        transform: translate(75px, -150px);
    }
    
    .dashboardBanner .maskImg {
        mask: url(assets/svg/arrow.svg) no-repeat;
        -webkit-mask-position-x: left;
        -webkit-mask-size: cover;
        background-size: contain !important;
        max-width: 800px;
        right: 0px;
        bottom: 0px;
        transform: translate(230px, 30px);
    }
}

.editSaveSearch fieldset>div>div {
    box-sizing: border-box !important;
}

.filterResult fieldset>div div.dpqWDs {
    display: none;
}

.jobMatchFilter {
    padding-top: 20px;
    display: flex;
    justify-content: space-evenly;
    width: 48%;
}

.filter-actions {
    display: flex;
    /* width: 48%; */
    width: 60%;
    justify-content: space-evenly;
    align-items: baseline;
}

/* .button-dropdown-container {
    display: flex;
    align-items: center;
    height: 70px;
} */

.dropdown-button {
    position: relative;
    display: inline-block;
}

/* dropdown.css */
.drop-down-content {
    position: absolute;
    background-color: white;
    min-width: 160px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    overflow: hidden;
    z-index: 1;
    max-height: 0;
    opacity: 0;
    transition: max-height 0.3s ease, opacity 0.3s ease;
  }
  
  .drop-down-content.open {
    max-height: 200px; /* Adjust height for animation */
    opacity: 1; /* Fade in */
  }  

.dropdown-item {
    color: #333;
    padding: 12px 20px;
    text-align: left;
    background-color: white;
    border: none;
    width: 100%;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s, color 0.3s;
    display: flex;
    justify-content: space-between; /* Aligns tick mark to the right */
    align-items: center;

    &:hover {
        background-color: #f1f1f1;
        color: #007bff;
    }

    &:not(:last-child) {
        border-bottom: 1px solid #e6e6e6;
    }
}

@media only screen and (max-width: 1024px) {
    .ourPolicy .container-heading {
        margin-left: unset;
    }

    .ourPolicy .WordSection1 {
        margin-left: unset;
        width: 50vw;
    }
}

@media screen and (max-width: 768px) {

    .ourPolicy .WordSection1 .policy-table {
        overflow-x: scroll;
    }

    .ourPolicy .WordSection1 .policy-table::-webkit-scrollbar {
        display: none;
    }

    .ourPolicy .WordSection1 {
        width: 67vw;
    }

    .jobMatchFilter {
        width: 80%;
    }

    .filter-actions {
        width: unset;
    }

    .ourPolicy .shapeMask_mobile {
        display: unset !important;
    }

    .welcome-on-board_msg .shape_postions .shapeMask_mobile {
        display: unset !important;
    }

    .onBoarding-questions .shape_postions .shapeMask_mobile {
        display: unset !important;
    }

    .onBoarding-questions .shape_postions {
        top: -68% !important;
        right: -72% !important;
        width: 151% !important;
        left: 26% !important;
    }

    .onboarding-recap_screen {
        margin-top: 60px;
    }

    .ourPolicy .shape_postions {
        top: -60% !important;
        right: -75% !important;
        width: 142% !important;
    }

    .our-policy_container {
        margin-top: 45px !important;
    }

    .welcome-on-board-screen {
        height: 80vh;
        margin-top: 55px !important;
    }

    .welcome-on-board_msg .shape_postions {
        top: -77% !important;
        right: -95% !important;
        width: 150% !important;
        left: 25% !important;
    }

    .onboarding-questions_screen {
        margin-top: 50px;
    }
}

@media screen and (max-width: 425px) {
    .ourPolicy .WordSection1 {
        width: 90vw;
    }

    .ourPolicy .WordSection1 table {
        width: 40%;
    }

    .mobile-menu {
        background-size: 150% !important;
        background-position: left -25px top 60vh !important;
    }

    .jobMatchFilter {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
    }

    .filter-actions {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
    }

    .modal-parent>div:first-child {
        margin-top: 5px;
    }

    .modal-parent.single-select>div:first-child section {
        position: unset !important;
    }

    .modal-parent.single-select>div:first-child section h2 {
        margin: unset;
        margin-bottom: 8px;
    }

    .popup-savesearch {
        padding: 9px 10px 0px 6px !important;
    }

    .ourPolicy .shape_postions {
        top: -27% !important;
        right: -110% !important;
        width: 161% !important;
        left: 20% !important;
    }

    .welcome-on-board_msg .shape_postions {
        top: -46% !important;
        right: -93% !important;
        width: 171% !important;
        left: 21% !important;
    }

    .onBoarding-questions .shape_postions {
        top: -29% !important;
        right: -93% !important;
        width: 168% !important;
        left: 16% !important;
    }
}

@media screen and (max-width: 375px) {
    .modal-parent.single-select>div:first-child section h2 {
        font-size: 1.2rem !important;
    }

    .our-policy_container {
        margin-top: 50px !important;
    }

    .ourPolicy .shape_postions {
        top: -20% !important;
        right: -110% !important;
        width: 161% !important;
        left: 20% !important;
    }

    .welcome-on-board_msg .shape_postions {
        top: -37% !important;
        right: -86% !important;
        width: 169% !important;
        left: 22% !important;
    }

    .onBoarding-questions .shape_postions {
        top: -26% !important;
        right: -80% !important;
        width: 169% !important;
        left: 21% !important
    }

    .onboarding-questions_screen {
        margin-top: 45px;
    }

    .onboarding-recap_screen {
        margin-top: 55px;
    }
}


.fc-day-other {
    background-color: var(--colour-background-secondary) !important;
}

.fc .fc-toolbar-title {
    font-weight: var(--font-weight-bold);
    font-size: 1.5rem;
    color: var(--colour-text-primary);
}

.fc-button-group>button:first-child:not(:last-child) {
    padding: 10px 20px;
}

.fc-direction-ltr .fc-button-group>.fc-button:not(:first-child) {
    padding: 10px 20px;
}

.fc-button-primary {
    background-color: var(--colour-background-secondary) !important;
    /* color: var(--colour-action-primary) !important; */
    color: var(--colour-action-small);
}

.fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:not(:disabled).fc-button-active {
    /* background-color: #31344c !important; */
    background-color: var(--colour-action-feature) !important;
    /* color: var(--colour-action-primary-text) !important; */
    border-color: #adadad;
}

.t-dark .fc-button-primary {
    color: var(--colour-text-primary) !important;
    border-color: #ccc !important;
}

.t-dark .fc .fc-button-primary:not(:disabled):active,
.t-dark .fc .fc-button-primary:not(:disabled).fc-button-active {
    /* background-color: #fff !important; */
    background-color: var(--colour-global-white) !important;
    /* color: #13151e !important; */
    color: var(--colour-container-featured) !important;
}

.fc-daygrid-event-dot {
    display: none;
}

.fc-daygrid-event,
fc-daygrid-dot-event,
fc-event,
fc-event-start,
fc-event-end,
fc-event-past {
    padding-left: 10px;
    border-color: var(--colour-border-hairline);
    background-color: var(--colour-background-secondary);
    cursor: default;
    color: var(--colour-text-primary);
}

.fc-h-event .fc-event-main {
    color: inherit;
}

.fc-daygrid-block-event .fc-event-time {
    font-weight: 400;
}

.t-light .fc-daygrid-event,
fc-daygrid-dot-event,
fc-event,
fc-event-start,
fc-event-end,
fc-event-past {
    background-color: #dde2fe;
}

.fc-daygrid-dot-event:hover,
.fc-daygrid-dot-event.fc-event-mirror {
    /* color: var(--colour-action-primary); */
    color: var(--colour-text-primary);
}

.fc-daygrid-dot-event .fc-event-title {
    font-weight: 400;
}

.t-dark .fc-day-other {
    background-color: var(--colour-background-secondary) !important;
}

.t-dark .fc-theme-standard .fc-scrollgrid {
    border-color: #686e89 !important;
}

.t-dark .fc-theme-standard td,
.t-dark .fc-theme-standard th {
    border-color: #686e89 !important;
    color: var(--colour-text-primary);
}

.t-dark .fc-daygrid-day-number {
    /* color: var(--colour-text-inverse); */
    color: var(--colour-text-primary);
}

.t-dark .fc-col-header-cell-cushion {
    color: var(--colour-text-primary);
}

.t-dark .fc .fc-day-other .fc-daygrid-day-top .fc-daygrid-day-number {
    color: var(--colour-text-primary);
}

.t-dark .fc-daygrid-event,
fc-daygrid-dot-event,
fc-event,
fc-event-start,
fc-event-end,
fc-event-past {
    background-color: #686e89;
    color: var(--colour-text-primary);
}

.t-dark .fc-timegrid-event,
fc-daygrid-dot-event,
fc-event,
fc-event-start,
fc-event-end,
fc-event-past {
    background-color: #686e89;
    color: var(--colour-text-primary);
    border: 0px;
}

.t-light .fc-timegrid-event,
fc-daygrid-dot-event,
fc-event,
fc-event-start,
fc-event-end,
fc-event-past {
    background-color: #686e89;
    color: var(--colour-text-primary);
    border: 0px;
}

.t-dark .fc-timegrid-event-harness-inset .fc-timegrid-event,
.t-dark .fc-timegrid-event.fc-event-mirror,
.t-dark .fc-timegrid-more-link {
    box-shadow: none;
}

.fc-day-today {
    /* background-color: var(--colour-background-primary) !important;
    color: var(--colour-text-primary) !important; */
    /* background-color: inherit !important; */
}


.t-dark .fc-daygrid-day {
    background-color: var(--colour-background-primary) !important;
    color: var(--colour-text-primary) !important;
}

.fc .fc-button-primary:not(:disabled):active:focus,
.fc .fc-button-primary:not(:disabled).fc-button-active:focus {
    box-shadow: none;
}

.editSaveSearch .rmsc .gray {
    color: var(--colour-text-primary);
}

.editSaveSearch .rmsc .dropdown-container {
    background-color: var(--colour-background-primary);
}

.assessment-page textarea,
.assessment-page input {
    resize: none;
}

.managed_notification .input-border {
    border: none;
}

.managed_notification .input-border span {
    color: var(--colour-text-secondary);
}

.acceptedJob h4,
.acceptedJob div:first-child>div {
    color: var(--colour-action-secondary-text);
}

.document-table li::marker {
    display: none;
    content: '';
}

.t-light .document-table div[data-popper-placement] button {
    color: var(--colour-action-small);
}

.t-light .document-viewer-modal .viewPopup button {
    color: var(--colour-action-small);
}

#onetrust-banner-sdk .ot-sdk-container,
#onetrust-pc-sdk .ot-sdk-container,
#ot-sdk-cookie-policy .ot-sdk-container {
    box-sizing: border-box !important;
}

.unableToUploadFiles {
    margin-bottom: 20px;
    font-size: 15px;
    color: #9D323D;
}

#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy td span,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy td a {
    word-break: break-word;
}

#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy table {
    table-layout: fixed;
}

.loader {
    /* position: absolute; */
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.25);
    /* border-radius: 32px; */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.t-dark .cookie-policy .cookie-content * {
    color: #fff !important;
}

.t-dark .cookie-policy a {
    color: #456da4 !important;
}

.t-dark #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy th {
    color: #696969 !important;
}

.t-dark #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy a,
.t-dark #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy a:hover {
    background: transparent !important;
}

.t-dark #ot-sdk-btn.ot-sdk-show-settings,
.t-dark #ot-sdk-btn.optanon-show-settings {
    color: #68b631 !important;
    border: 1px solid #68b631 !important;
}

.t-dark #ot-sdk-btn.ot-sdk-show-settings:hover,
.t-dark #ot-sdk-btn.optanon-show-settings:hover {
    color: #fff !important;
}

.cookie-policy p,
.cookie-policy li {
    font-size: 0.9em;
    margin-bottom: 0px;
    line-height: normal;
}

.cookie-policy #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy td span,
.cookie-policy #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy td a {
    font-size: 0.9em;
}

.privacy-policy p,
.privacy-policy li {
    font-size: 0.9em;
}
.t-dark .privacy-policy .privacy-content * {
    color: #fff !important;
}
.t-dark .privacy-policy a {
    color: #456da4 !important;
}

html[lang=pl] .banner-body h1 {
    font-size: 3rem;
}