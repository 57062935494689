.discover-welcome,
.discover-welcome * {
  box-sizing: border-box !important;
}

h2 {
  font-family: Arial, Helvetica, sans-serif;
}

.button-container .more-collection-button {
  font-weight: 700;
}

.discover-welcome .assessment-card-block {
  /* display: flex; */
  /* margin: 30px; */

  display: grid;
  grid-row: auto;
  grid-template-columns: 50% 50%;
  column-gap: 5px;
  margin-bottom: 20px;
}

.discover-welcome .assessment-card {
  /* display: flex; */
  /* margin: 30px; */

  display: grid;
  grid-row: auto;
  grid-template-columns: auto;
  column-gap: 30px;
  margin-bottom: 50px;
  z-index: 0;

  display: block;
  padding: 0px 10px;
  margin-bottom: 30px;
}

.recommended-section {
  padding: 50px 0;
}

.card-section {
  display: flex;
}

.recommended-section .card {
  max-width: 250px;
  margin-right: 10px;
  padding: 0;
}

.save-section {
  display: flex;
  justify-content: flex-start;
}

.article-page {
  width: auto !important;
  /* padding: 0 150px !important; */
}

.responsive{
  display: none;
}

.back-button {
  display: flex !important;
  margin-right: auto !important;
  margin-left: 85px !important;
  margin-top: 30px !important;
}

.dashboard_page .recommended-section{
  padding-top: 40px;
  padding-bottom: 10px;
}

.dashboard_page .job-greetings{
  margin-top: 15px;
  /* margin-bottom: 12px; */
}

.dashboard_page .self-discovery-recommnded{
  padding-left: 0px !important;
}

.discover-welcome .heading_learning-collections{
  text-align: unset;
  margin-top: -8px;
}

.discover-welcome .description_learning-collections{
  text-align: unset;
}

.learning-collection-banner h1{
  margin-top: 70px;
}

.learning-collection-banner p{
  margin-top: 15px;
}

.subject-category-collection h1{
  margin-top: 58px;
}

.subject-category-collection p{
  margin-top: 10px;
}

.discover-subpage-back {
  position: absolute;
  top: 0px;
  left: 0px;
  margin-top: 30px !important;
}

.learning-collection-page .intro-video{
  margin-top: 30px;
}

.learning-collection-page .intro-video picture{
  width: 100%;
}

.learning-collection-page .intro-video .content button{
  margin-top: 5px;
  margin-bottom: 10px;
}

.intro-video .content {
  padding: 35px !important;
}

.learning-collection-page .self-discovery-recommnded, .subject-categories-page .self-discovery-recommnded {
  background: var(--colour-container-on-secondary);
  border-radius: 15px;
  margin-top: 15px;
  padding-left: 20px !important;
  padding-right: 15px !important;
  padding-top: 15px;
}

.discover-welcome .self-discovery-recommnded{
  padding: 0;
}

.discover-welcome .all-learning-resource{
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.learning-collection-page .carousel .discover-carousel-buttons{
  padding-left: 15px;
}

.learning-collection-page .learning-collections, .subject-categories-page .learning-collections {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
@media (min-width: 1280px) {
  .learning-collection-page .learning-collection-banner .banner-body {
    max-width: 54%;
  }
  .banner-body > div {
    padding: 1rem 3rem 3rem 3rem;
  }
}
@media screen and (max-width: 900px) {
  .learning-collection-page .tools-card{
    overflow: scroll;
  }

  .learning-collection-banner h1{
    margin-top: 50px !important;
  }

  .discover-subpage-back{
    margin-top: 25px !important;
  }

  .article-page {
    width: auto !important;
    /* padding: 0 60px !important; */
  }

  .search-filters{
    display: none !important;
  }

  .iView_tools{
    margin-bottom: 40px;
  }
  .back-button {
    margin-left: 25px !important;
  }
  .responsive_filter-model {
    background-color: var(--colour-background-primary);
    height: 90vh;
    overflow-x: hidden;
    /* width: 100vw; */
  }

  .responsive_filter-model .filter-reset-button {
    position: absolute;
    top: -1%;
    left: 50%;
    border-bottom: none;
  }

  .responsive_filter-model .filter-apply-button {
    position: absolute;
    left: -9%;
    top: 95%;
    width: 100%;
  }

  .responsive_filterResultResources fieldset {
    padding: 10px !important;
  }

  .responsive{
    display: unset;
  }

  .nav-section{
    display: none !important;
  }
  .responsive_search button{
    width: 100%;
  }

  .responsive-filter-modal section{
    height: 100vh !important;
  }

  .responsive-filter-modal::-webkit-scrollbar{
    display: none;
  }
}

@media screen and (max-width: 425px) {

  .responsive_filter-model {
    background-color: var(--colour-background-primary);
    height: 100vh;
    overflow-x: hidden;
    width: 100vw;
  }

  .responsive_filter-model .filter-reset-button {
    position: absolute;
    top: -1%;
    left: 50%;
    border-bottom: none;
  }

  .responsive_filter-model .filter-apply-button {
    position: absolute;
    left: -9%;
    top: 95%;
  }

  .responsive_filterResultResources fieldset {
    padding: 10px !important;
  }

  .learning-collection-page .tool-section .tools-card::-webkit-scrollbar{
    display: none;
  }

  .learning-collection-page .tool-section .tools-card{
    column-gap: 20px;
  }

  .docebo-skills {
    margin-left: unset;
  }

  .intro-video .content{
    padding: 20px !important; 
  }

  .learning-collection-page .self-discovery-recommnded, .subject-categories-page .self-discovery-recommnded {
    margin-left: -1rem;
    width: 95vw;
    border-radius: 0;
    padding-left: 16px !important;
    padding-right: unset !important;
  }

  .discover-welcome .heading_learning-collections{
    margin-top: unset;
  }

  .discover-welcome .recommended-section{
    padding-bottom: 15px;
    /* padding-left: 10px; */
  }
  
  .article-page {
    width: auto !important;
    padding: 0 8px !important;
  }

  .discover-welcome .assessment-card{
    display: block;
  }
  .discover-welcome .banner-body p {
    max-width: 100%;
  }

  .discover-welcome .assessment-card-block{
    display: flex !important;
    flex-direction: column;
  }

  .nav-section{
    display: none !important;
  }

  .search-filters{
    display: none !important;
  }

  .filter-model{
    display: none;
  }
  .responsive{
    display: unset;
  }

  .responsive_search button{
    width: 100%;
  }

  .responsive_close button{
    width: 35%;
  }

  .responsive_search-filters {
    background-color: var(--colour-background-primary);
  }

  .responsive_search-filters div:nth-child(2) {
    margin-top: 25px;
  }

  .responsive_filter-model button {
    width: 100%;
  }

  .back-button {
    margin-left: 5px !important;
  }

  .learning-collection-banner h1 {
    margin-top: 25px;
  }

  .subject-category-collection h1 {
    margin-top: 25px;
  }

  .tools-card{
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .discover-welcome .banner-shape{
    display: unset !important;
  }

  .learning-collection-page .banner-shape{
    display: none !important;
  }

  .subject-categories-page .banner-shape{
    display: none !important;
  }

  .discover-welcome .banner-shape_graphics {
    position: relative !important;
    width: 55% !important;
    left: 239px !important;
    top: -84px !important;
  }

  .discover-welcome .banner-body h1 {
    margin-top: 45px;
  }
}

@media screen and (max-width: 375px) {
  .learning-collection-page .tool-section .tools-card{
    column-gap: 10px;
  }

  .article-page {
    width: auto !important;
    padding: 0 0px !important;
  }

  .discover-welcome .banner-shape_graphics {
    width: 64% !important;
    left: 208px !important;
    top: -73px !important;
  }

  .discover-welcome .banner-body h1 {
    margin-top: 50px;
  }
}


.resource-page-container {
  padding: 15px 150px;
}

.likeBtn {
  padding: 0 !important;
}

#cardtype {
  padding: 3px 8px 2px;
}

.task-card {
  /* display: flex; */
  /* margin: 30px; */

  display: grid;
  grid-template-columns: auto auto;
  column-gap: 30px;
  margin-bottom: 50px;
}

.resource-card {
  display: flex;
  /* margin: 30px; */

  /* display: grid; */
  grid-template-columns: auto auto auto auto auto;
  column-gap: 20px;
  margin-bottom: 50px;
}

.resource-card.docebo-skills {
  margin-bottom: 10px;
}

.image {
  width: 100%;
  padding-bottom: 50px;
}

.content {
  padding: 0 200px;
}

.more-collections {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section-1 {
  margin: 20px 0;
}

.section-1 p {
  font-family: 'Helvetica Neue LT Pro' !important;
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 24px !important;
  line-height: 32px !important;
}

.li-list {
  padding: 20px 20px !important;
}

.section-1 label {
  /* color: #646e8a !important; */
  color: var(--colour-text-primary);
  line-height: 32px !important;
  font-family: 'Helvetica Neue LT Pro' !important;
}
.t-dark .section-1 label{
  color: var(--colour-text-secondary);
}
.related-resources {
  background-color: var(--colour-background-secondary);
}

.more-collections h5 {
  margin: auto;
}

/* .related-resources {
    padding: 30px;
} */

.nav-section {
  margin-bottom: 5px;
  display: flex;
  margin-top: 10px !important;
}

.iview-card {
  width: 50% !important;
}

.nav-section .search-filters {
  display: flex;
  margin-left: auto;
}

.search-filters .input-border {
  padding: 5px;
  margin-right: 10px;
  min-width: 350px;
  /* background-color: var(--colour-background-primary); */
}

.t-light .search-filters .input-border{
  background-color: var(--colour-background-primary);
}

.search-filters .input-border button {
  width: 25%;
}

.search-filters .input-border .icon-search {
  margin-left: 5px;
  color: #646e8a;
}

.search-filters .input-label {
  display: none;
}

.related-resources .card {
  max-width: 250px;
  margin-right: 10px;
  padding: 0;
}

.button-container {
  display: flex;
}

.button-container button {
  margin-right: 10px;
}

.assessment-new {
  /* margin: 20px; */
}

.assessment-started {
  /* margin: 20px; */
}

.assessment-done {
  /* margin: 20px; */
}

.task-new {
  /* margin: 20px; */
}

.task-started {
  /* margin: 20px; */
}

.task-done {
  /* margin: 20px; */
}

.resource-video {
  /* margin: 20px; */
}

.resource-document {
  /* margin: 20px; */
}

.resource-article {
  /* margin: 20px; */
}

.CardSubHeading {
  font-size: 0.688rem;
  color: blue;
  font-weight: 700;
  margin-bottom: 4px;
}

.assessment-card-block .assessment-card1 {
  /* width: 55% !important; */
}

.tool-card1 {
  /* width: 65%; */
}

/* .learning-collections .description {
  width: 770px;
} */

.learning-collections {
  margin: 0px 0;
  border-radius: 25px;
}

.docebo-card {
  margin-left: 0;
}

.filter-model {
  margin-bottom: 30px;
}

.filter-section {
  display: flex;
  margin-left: auto;
}

.self-discovery-recommnded {
  margin-left: -43px;
}

.discover-welcome .filter-button.filter-button_filter.is-active {
  background-color: var(--colour-action-primary);
  color: var(--colour-background-primary);
}

.discover-welcome .filter-button_filter{
  background-color: var(--colour-container-on-secondary);
}

.discover-welcome .filter-button_filter span {
  color: var(--colour-action-feature);
}


.intro-video {
  width: 100%;
  margin-top: 50px;
}

.tabs {
  width: 100%;
}

.dropdown-heading button {
  width: unset;
}

.responsive_filterResultResources div:nth-child(2) {
  z-index: 2;
}

.responsive_filterResultResources div:nth-child(3) {
  z-index: 1;
}

.responsive_filter-model .filter-apply-button {
  z-index: 0;
}

.recommended-section .view-events {
  margin-top: 30px;
}

.learning-collection-page .tool-section {
  margin-top: 35px;
}

.tool-section {
  margin-top: 30px;
}

.tool-section .tools-card {
  display: grid;
  /* grid-template-columns: auto auto; */
  grid-template-columns: 49% 49%;
  column-gap: 20px;
  margin-bottom: 50px;
  margin-top: 20px;
  z-index: 0;
}

.learning-collection-page .tool-section .tools-card{
  margin-bottom: 35px;
}