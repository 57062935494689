.article-page,
.article-page * {
    box-sizing: border-box !important;
    font-family: var(--font-family-Helvetica-Neue-LT-Pro);
}

.article-page {
    color: var(--colour-text-primary);
}

.article-page .background-White {
    background-color: #fff;
    padding: 80px;
}

@media screen and (max-width: 768px){
    .article-page .background-White {
        padding: 25px !important;
    }

    .related-resources{
        padding-left: 0px !important;
        padding-right: 36px !important;
        padding-top: 60px !important;
        padding-bottom: 17px !important;
    }
}

@media screen and (max-width: 425px){
    .article-page .background-White {
        padding: 15px 2px 15px 2px !important;
    }
/* 
    .related-resources{
        padding-left: 0px !important;
        padding-right: 36px !important;
        padding-top: 50px !important;
        padding-bottom: 17px !important;
    } */
}

@media screen and (max-width: 375px){
    /* .article-page .background-White {
        padding: 0px !important;
    } */
}

.article-page .content {
    align-items: center;
    justify-content: center;
    margin: auto;
}

.related-resources {
    padding: 75px 50px;
}

.card-section {
    display: flex;
}

.img_article{
    object-fit: cover;
 }

@media screen and (max-width: 768px) {
    .img_article{
        object-fit: cover !important;
    }
}
@media screen and (max-width: 425px) {
    .img_article{
        object-fit: cover !important;
    }
}

.related-resources .card {
    max-width: 250px;
    margin-right: 10px;
    padding: 0;
}

.article-page .save-section {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}

.article-page button.saveBtn {
    border: none;
    border-radius: var(--border-radius-softly-rounded);
    padding: var(--spacing-4) var(--spacing-8);
    background-color: var(--colour-container-on-primary);
    /* color: var(--colour-action-primary); */
    color: var(--colour-action-small);
    height: 1.875rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all var(--transition-timing-function-default) var(--transition-speed-normal);
}

.article-page button.saveBtn:hover {
    border: none;
    background-color: var(--colour-action-small);
    color: var(--colour-action-secondary-text);
}

.t-dark .article-page button.saveBtn {
    background-color: var(--colour-background-secondary);
    color: var(--colour-action-feature);
}

.t-dark .article-page button.saveBtn:hover {
    background-color: var(--colour-action-feature);
    color: var(--colour-action-primary-text);
}

.article-page button.saveBtn span.saveBtnText {
    margin-left: 0.375rem;
}

.article-page #category-label {
    margin-left: 0px;
    font-size: 14px;
    border: 1px solid #d9e2ff;
    border-radius: 4px;
    padding: 3px 8px 2px;
}

.t-dark .article-page #category-label {
    border-color: var(--colour-action-outline);
}

.article-page #cardtype {
    margin-left: 0px;
    font-size: 14px;
}

.article-page button.likeBtn {
    border: none;
    padding: 0;
    background-color: var(--colour-background-primary);
    color: var(--colour-text-primary);
}

.article-page button.likeBtn:hover {
    border: none;
    color: var(--colour-text-primary);
}

.article-page button.likeBtn svg {
    vertical-align: bottom;
}

.article-page button.likeBtn span.likeBtnText {
    font-size: 12px;
}

.article-body,
.article-body .article-rich-text {
    color: #282A32;
    word-break: break-word;
}

.article-page .article-body p {
    margin-left: auto !important;
    margin-right: auto !important;
    margin-bottom: 30px;
}

.article-body .article-title {
    color: var(--colour-text-primary);
}

.article-page ul,
.article-page ol {
    margin-top: 30px;
}

.article-page .description {
    color: #646E8A !important;
}

.article-body ul li,
.article-body ol li {
    margin-left: auto !important;
    margin-right: auto !important;
    margin-bottom: 10px;
}

.article-body h1 {
    font-size: 32px;
}

.article-body h2 {
    font-size: 28px;
}

.article-body h3 {
    font-size: 24px;
}

.t-dark .article-page .background-White {
    background-color: #12151E;
}

.t-dark .article-page .background-White h3 {
    color: #FFFFFF;
}

.t-dark .article-body,
.t-dark .article-body .article-rich-text {
    color: #FFFFFF;
}

.article-page table {
    border-radius: 10px;
    overflow: hidden;
    font-family: var(--font-family-Helvetica-Neue-LT-Pro);
    /* table-layout: fixed; */
    width: 100%;
}

.article-page table table tr:first-child {
    background-color: transparent;
    color: var(--colour-text-primary);
}

.article-page table tr:nth-child(odd) {
    background-color: var(--colour-background-primary);
}

.article-page table tr:nth-child(even) {
    background-color: var(--colour-background-secondary);
}

.article-page table tr:first-child {
    background-color: var(--colour-container-featured);
    color: var(--colour-action-primary-text);
    /* font-weight: var(--font-weight-medium); */
    text-transform: capitalize;
    letter-spacing: 1px;
}


.article-page table tr td {
    border-right: 1px solid var(--colour-action-outline);
    padding: 10px;
    font-size: 14px;
}

.carousel {
    margin-top: -40px;
}

.article-page table tr td * {
    background-color: transparent;
    font-size: 14px;
}

.article-page table tr:first-child td,
table tr td:last-child {
    border-right: 0px;
}

.article-page img {
    max-width: 100% !important;
    height: auto !important;
    max-height: 400px;
}

.article-page .border-class {
    border:0px;
    border-top: 1px solid var(--colour-border-hairline);
    margin-bottom: var(--spacing-40);
}

.article-page .download-files {
    background-color: var(--colour-background-secondary);
    border-radius: 8px;
    cursor: pointer;
}

.article-page .download-files span span {
    text-transform: uppercase;
    color: var(--colour-text-secondary);
    font-weight: 500;
}

.article-page .download-files i {
    font-size: 30px;
    color: var(--colour-text-secondary);
}

.duration {
    color: var(--colour-text-secondary);
}

.article-page .short-description {
    font-weight: 300;
    font-size: 20px;
    line-height: 32px;
    color: #646E8A;
}

.article-page q {
    font-weight: 300;
    font-size: 24px;
    line-height: 32px;
    color: #646E8A;
}