.card {
  background-color: var(--colour-background-primary);
  width: 700px;
  margin: auto;
  height: 400px;
  padding: 100px 100px;
  border-radius: 15px !important;
}

.iView-done-page .card{
  height: unset !important;
  border-radius: 32px !important;
  /* min-height: 500px; */
  margin-bottom: 10px;
  padding-top: 70px;
  background-color: var(--colour-background-primary);
}

.iView-done-page .bodyContent{
  width: 623px;
  /* text-align: center; */
  line-height: 26px;
  margin-right: auto !important;
  margin-left: auto !important;
}

.iView-done .header{
  color: var(--colour-text-primary);
}

.header {
  font-weight: 800;
  font-size: 30px;
  margin: auto;
  margin-top: 0;
  margin-bottom: 0;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.assessment-review-result-wrapper {
  /* display: flex; */
  align-items: center;
  text-align: center;
  /* margin-left: 20px !important */
}

.topic {
  padding-top: 20px;
  width: 100% !important;
  text-align: center;
}

.actionButtons {
  border: 1px solid #dee1ea;
  border-radius: 50px;
  color: var(--colour-action-small);
  margin-right: 10px;
  padding: 10px;
  cursor: pointer;
  font-size: 16px;
  font-weight: var(--font-weight-bold);
}

.topicBorder {
  border: 1px solid rgb(192, 192, 215);
  padding: 2px 5px;
  border-radius: 5px;
}

.spanElements {
  /* font-weight: 800; */
  font-size: 12px;
  /* color: blue; */
  margin-left: 10px;
}

.body {
  margin-top: 20px;
}

.bodyHeader {
  font-size: 30px;
}

.iview-interview-start-description {
  font-size: 24px;
}

.bodyContent {
  line-height: 20px;
  width: 100%;
  text-align: left;
}

.buttionContainer {
  margin-top: 20px;
}

.button {
  border: none;
  border-radius: 25px;
  background-color: #1456ff;
  padding: 10px 40px;
  font-weight: 700;
  color: white;
}

.bodyTextWrapper {
  margin-top: 50px;
}

.iView-done {
  box-sizing: border-box !important;
}

@media screen and (max-width: 768px) {
  .iView-done.container{
    max-width: none;
    background: var(--colour-background-primary);
  }

  .card {
    margin-bottom: 20px !important;
    width: 575px;
    padding: 24px 60px;
  }

  .iView-done .text-end {
    margin-left: 75px;
  }

  .iView-done.card {
    border-radius: 0px !important;
    width: auto;
    padding: 0px;
    background-color: transparent;
    border: 0px;
    height: auto;
  }

  .mobile-header-wrapper {
    background-color: var(--colour-background-primary);
    display: flex;
    flex-direction: column;
    padding: 24px 60px;
  }
  .bodyTextWrapper {
    padding: 0px 30px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 475px) {

  .iView-done-page .bodyContent{
    width: unset;
    text-align: unset;
  }
  
  .bodyTextWrapper {
    padding: 0px var(--spacing-24);
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .actionButtons{
    display: block;
    margin-bottom: 15px;
  }
  .iView-done {
    padding-left: unset !important;
  }

  .card {
    width: 300px;
    padding: 20px 48px
  }

  .iView-done .text-end {
    margin-left: -35px;
  }
}

@media screen and (max-width: 375px) {
  .card {
    width: 280px;
    padding: 15px 40px;
  }

  .iView-done .text-end {
    margin-left: -40px;
  }
}