.norecords,
.onBoardingDropdown {
    list-style: none;
    border: 1px solid var(--colour-action-outline);
    font-family: var(--font-family-Helvetica-Neue-LT-Pro);
    font-weight: var(--font-weight-regular);
    background-color: var(--colour-background-primary);
    font-size: 1rem;
    line-height: 1.625rem;
    position: absolute;
    top: 63px;
    left: 30px;
    right: 30px;
    width: auto;
    z-index: 1;
    max-height: 200px;
    overflow: auto;
    padding-left: 20px;
    font-size: 15px;
}

.aboutme_searchlist{
    left: 50px !important;
}

.norecords {
    padding: 8px;
    top: -11px;
    left: 34px;
    position: relative;
}

.loading {
    position: relative;
    top: -6px;
    left: 200px;
    width: 85%;
    z-index: 1;
}

.onBoardingDropdown li {
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.onBoardingDropdown li:hover {
    color: var(--colour-action-feature);
}

.selectedBox_sectors,
.selectedBox_jobs {
    min-height: 200px;
    min-height: 20px;
    border: 1px solid var(--colour-action-outline);
    /* border-radius: 21px; */
    padding: 12px;
    /* width: 50%; */
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 10px;
}

.selectedBox_jobs button {
    margin: 5px;
    border-radius: 10px;
    font-size: 17px;
    border: 1px solid gray;
}

.delete_button {
    margin: 5px;
    font-size: 12px;
    display: flex;
    align-items: center;
}

.sector_select {
    position: relative;
}

.selectedWrapper {
    min-height: 200px;
}

.aboutme_jobModal section p{
    margin-left: 29px
}

.selectedBox_jobs{
    color: var(--colour-text-primary);
    border: 1px solid var(--colour-action-outline);
}

.onBoardingDropdown li{
    color: var(--colour-text-primary);
}
.inactiveAccountMsg {
    top: 0;
    margin-top: -48px;
    z-index: 9;
}
.inactiveAccountMsg > div {
    align-items: center;
}
.inactiveAccountMsg > div > div div:first-child {
    display: none;
}

@media (max-width: 1024px) {
    .onBoardingDropdown {
        top: 63px;
        left: 30px;
        right: 30px;
        width: auto;
    }
}

@media (max-width: 768px) {
    .onBoardingDropdown {
        top: 63px;
        left: 30px;
        right: 30px;
        width: auto;
    }
}

@media (max-width: 425px) {
    .aboutme-banner .banner-shape{
        display: unset !important;
    }

    .aboutme-banner .banner-shape > div{
        position: relative !important;
        width: 70% !important;
        left: 213px !important;
        top: -95px !important;
    }

    .aboutme-banner .banner-body h1 {
        margin-top: 80px;
    }
}

@media (max-width: 375px){
    .aboutme_jobsnSectorModal .ms-4{
        margin-left: 4px !important;
    }

    .aboutme_jobModal section p{
        margin-left: 10px;
    }

    .aboutme-banner .banner-shape > div{
        width: 70% !important;
        left: 173px !important;
        top: -86px !important;
    }

    .aboutme-banner .banner-body h1 {
        margin-top: 60px;
    }
}