.Header{
    padding-left: 1rem;
    font-family: Arial, sans-serif;
}

.SubHeader
{
    padding-left: 1rem;
    font-family: Arial, sans-serif;
    margin: 10px 0px 10px 0px
}


.PhoneInputInput {
    pointer-events: none;
}

.phoneNumberComponent {
    margin-bottom: var(--spacing-20);
}

.platform_settings form fieldset div:last-child{
    margin-top: 3px;
}

.platform_settings form fieldset div:last-child button{
    margin-top: 5px;
}

@media (max-width: 768px){
    .myProfileWrapper {
        background:var(--colour-background-primary) !important;

    }
    .myProfileContainer {
        /* margin: -1rem -1rem */
        width: 100%;
    }
}

@media (max-width: 425px){
    .myProfile-pageBanner .banner-shape{
        display: unset !important;
    }

    .myProfile-pageBanner .banner-shape > div{
        position: relative !important;
        width: 70% !important;
        left: 213px !important;
        top: -95px !important;
    }

    .myProfile-pageBanner .banner-body h1 {
        margin-top: 80px;
    }
}

@media (max-width: 375px){
    .myProfile-pageBanner .banner-shape > div{
        width: 70% !important;
        left: 184px !important;
        top: -92px !important;
    }

    .myProfile-pageBanner .banner-body h1 {
        margin-top: 60px;
    }
}