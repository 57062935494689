table{
    /* width: 80vh;
    border: 2px solid black; */
}
td {
    /* margin : 0 auto; */
}
.pre-login-header {
    display:flex;
    margin-left: 50px;

}
.brand-name-margin {
    margin: 0px 30px;
}
.background-none {
    background: none !important;
}

@media (max-width: 425px){

  .landing_page .shape_postions{
    /* position: relative; */
    max-width: 33.75rem;
    /* width: 100%; */
    /* bottom: -34%; */
  }

  .landing_page .shape_postions .shapeMask-upper_mobile{
      margin-bottom: -55px !important;
      position: relative !important;
      width: 108% !important;
      left: -6% !important;
      bottom: -16px;
    }

  .landing_page .shape_postions .shapeMask-lower_mobile{
        position: relative !important;
        width: 88% !important;
        left: 4% !important;
    }

    .landing_page h1 {
        /* margin-top: -45px !important; */
        /* font-size: 3rem !important; */
        margin-bottom: 8px;
        line-height: 4.5rem !important;
    }

    .landing_page button {
        margin-top: unset !important;
        margin-left: -25px;
    }
}

@media (max-width: 375px){
  .landing_page .shape_postions{
    /* width: 104% !important;  */
    /* bottom: -43% !important; */
  }

  
  .landing_page .shape_postions .shapeMask-upper_mobile{
    margin-bottom: -50px !important;
    position: unset !important;
    width: 100% !important;
    left: unset !important;
  }

  .landing_page .shape_postions .shapeMask-lower_mobile{
    position: unset !important;
    width: 100% !important;
    left: unset !important;
  }

  .landing_page h1 {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }

  .landing_page button {
    margin-left: -4px; 
  }
}