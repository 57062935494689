.EventCard {
  background-color: white;
  width: 300px;
  margin: 10px;
  height: 400px;
  /* padding: 10px; */
  /* border-radius: 2.25rem !important; */
  border-radius: 15px !important;
}

.self-discovery {
  width: 100%;
  max-width: 100%;
  /* padding: 20px; */
  margin: 15px 20px 0 20px;
}

.self-discovery .clsHeading p {
  /* color: var(--colour-background-primary); */
  color: var(--colour-global-white)
}

.self-discovery a {
  /* background-color: var(--colour-container-featured); */
  /* color: var(--colour-background-primary); */
  /* border: 1px solid var(--colour-background-primary); */
}

.self-discovery a:hover {
  /* background-color: var(--colour-action-primary); */
  /* color: var(--colour-action-primary-text); */

    background-color: var(--colour-background-primary);
    color: var(--colour-text-primary);
    border: solid 1px var(--colour-action-primary);
}

.EventCard .heKpAr {
  margin: 5px;
}

.carousel {
  margin-left: 5px;
}

.carousel .bgpBeR {
  margin-left: 16px;
}

.carousel .izSQAk {
  margin-bottom: 15px;
}

.carousel .sc-eBhrFy {
  /* color: var(--colour-text-secondary); */
}

.carousel p {
  /* color: var(--colour-background-primary); */
  color: var(--colour-global-white);
}

.recommendedEvents {
  width: 100%;
  max-width: 100%;
  padding: 10px 5px 25px 10px;
  margin: 20px;
  background-color: var(--colour-background-primary);
  border-radius: 20px;
}

.recommendedEvents .carousel h2,
.recommendedEvents .carousel h2+div {
  color: var(--colour-action-primary-text);
}

.myeventsWrapper {
  color: var(--colour-text-primary);
}

.recommendedEventsCard {
  display: grid;
  grid-template-columns: auto auto;
  z-index: 0;
}

.event-page-recommended .carousel {
  margin-top: 0px;
}

.event-page-recommended .carousel h2,
.event-page-recommended .carousel h2+div {
  color: var(--colour-text-primary);
}

.search-filters {
  width: fit-content;
  margin-bottom: 30px;
}

.events-block {
  gap: 30px 20px;
  display: grid;
  grid-template-columns: 50% 50%;
  margin-bottom: 30px;
}

.events-card {
  z-index: 0;
}

.events-card, .events-card * {
  box-sizing: border-box !important;
}

.events-card p {
  color:var(--colour-action-secondary-text);
}

.search-filters-mobile {
  display: none;
}

.allEvents_searchblock {
  display: flex;
  justify-content: space-between;
}

.allEvents_searchblock .input-border{
  background-color: var(--colour-container-on-secondary) !important;
  border: 1px solid var(--colour-action-outline) !important;
}

.events-mobile_title{
  display: none;
}

.allevents_heading-title{
  font-family: var(--font-family-DIN-Next-LT-Pro);
  font-weight: var(--font-weight-bold);
  font-size: 28px;
}

.nodata-text {
  color: var(--colour-text-primary);
}

.events-details-page{
  border-bottom: 1px solid var(--colour-border-hairline);
}

.events-details-page button {
  margin-bottom: 30px;
}

.events-details-page h3{
  font-size: 19px;
  font-family: var(--font-family-Helvetica-Neue-LT-Pro);
  margin-bottom: -10px;
}


@media screen and (max-width: 768px) {
  .search-filters-mobile {
    display: unset;
    /* width: 85%; */
  }

 .search-filters-mobile .input-border{
    background-color: var(--colour-container-on-secondary);
  }

  .search-filters-mobile .input-border > div {
    color: var(--colour-text-secondary);
  }

  .search-filters-mobile .events-block_btn{
    background-color: var(--colour-action-primary);
    color: var(--colour-text-primary);
  }
}

@media screen and (max-width: 425px) {
  .event-page-recommended {
    margin-left: 0rem;
    margin-right: 0rem;
    border-radius: 0px;
  }
  .events-mobile_title{
    display: unset;
    margin-bottom: unset;
    font-family: var(--font-family-Helvetica-Neue-LT-Pro);
    font-weight: var(--font-weight-regular);
    font-size: 16px;
    color: var(--colour-text-primary);
    position: relative;
    top: 16px;
    left: 5px;
    text-transform: capitalize;
  }

  .myevents_section .allevents_heading-title{
    margin-bottom: 22px !important;
  }

  .events-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: unset;
  }

  .events-block .events-card {
    height: 400px;
    width: 360px;
  }

  .search-filters-mobile {
    /* display: unset; */
    width: 95%;
  }
}

@media screen and (max-width: 375px) {
  .events-block .events-card {
    height: 380px;
    width: 320px;
  }
}

.events-card  .events-desp p, 
.events-card .events-desp span, 
.events-card .events-desp ul li {
  color: #fff !important;
  background-color: transparent !important;
}


.EventCard .recomm-events-desp p, 
.EventCard .recomm-events-desp span,
.EventCard .recomm-events-desp ul li {
  color: #fff !important;
  background-color: transparent !important;
}