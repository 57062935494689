.main-page button span {
    font-family: var(--font-family-Inter);
}

.main-page p {
    font-family: var(--font-family-system-fonts);
}

html[lang=ja], html[lang=ja] *, html[lang=ko], html[lang=ko] *, html[lang=ar], html[lang=ar] * {
    font-family: var(--font-family-Noto-Sans);
}