.jobListTwoColumn {
    flex-wrap: wrap;
    row-gap: 20px;
    list-style-type: none;
    padding: 0;
    column-gap: 24px;
    display: block;
    -webkit-columns: 2;
    columns: 2;
    width: 100%;
}

.jobListTwoColumn li * {
    box-sizing: border-box !important;
}

.jobListTwoColumn li {
    width: 100%;
    -webkit-break-inside: avoid;
    break-inside: avoid;
    margin-bottom: 20px;
}

.jobListOneColumn {
    row-gap: 12px;
    flex-wrap: wrap;
    display: flex;
    list-style-type: none;
    padding: 0;
}

.jobListOneColumn li {
    width: 100%;
    -webkit-break-inside: avoid;
    break-inside: avoid;
}

.savedjobsTab {
    width: 100%;
}

.filterResult * {
    box-sizing: border-box !important;
}

.jobWidget {
    padding: 60px;
    /* background: #ECEFF5; */
    width: 100%;
    box-sizing: border-box !important;
}

.saveSearchDelete div {
    text-align: center;
}

.jobs-dropdown {
    display: none;
}

.upload_resume {
    padding-top: 10px !important;
}


.jobs_loadmore {
    padding-top: 50px;
    width: 100%;
}

.job-filterResult .input-label {
    margin-top: -3px;
}


.job-filterResult-mobile .dropdown-content {
    z-index: 1000;
}

.jobToolsWrapper .assessment-card {
    margin-bottom: 30px;
    display: block;
    padding: 0 10px;
}

.buttonGroup {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    gap: 10px;
    align-items: center;
    /* border: 1px solid red; */
    /* background-color: var(--colour-container-featured-light); */
    border-radius: 18px;
    padding: 0 20px;
}

.tabs {
    /* position: absolute; */
    margin-bottom: 50px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    /* scroll-behavior: auto; */
}

.tab {
    display: flex;
    gap: 5px;
    background-color: #75d1fc;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    border-radius: 5px;
    font-size: 15px;
    /* font-weight: 400; */
    cursor: pointer;
}

.editTitleModal section {
    max-width: 20rem;
    min-width: 20rem;
}

/** AutoSuggest **/
.searchCont {
    position: relative;
}

.autosuggestlist {
    position: absolute;
    top: 53%;
    left: 11%;
    z-index: 9;
    background: #fff;
    padding: 0;
    list-style-type: none;
    min-width: 68%;
    border: 1px solid #b6c1ce;
    border-radius: 2px;
    margin: 0;
    box-sizing: border-box;
    max-height: 280px;
    overflow-y: auto;
}

.autosuggestlist ul {
    list-style: none;
    padding: 2%;
    margin: 0;
}

.autosuggestlist ul li {
    display: flex;
}

.autosuggestlist ul li span {
    padding: 5px;
    cursor: pointer;
    width: 50%;
}

.autosuggestlist ul li:hover {
    background-color: #f4f4f5;
}

.autosuggestlist ul li span:nth-child(2) {
    text-align: right;
}
.autosuggestlist.error{
    font-family: var(--font-family-system-fonts);
    font-weight: var(--font-weight-regular);
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0rem;
    background-color: var(--colour-background-error);
    color: var(--colour-text-error);
    font-size: 12px;
    padding: 8px 0px 8px 4px;
    display: none;
}
.autosuggestlist.error.showerror {
    display: block;
}
/** AutoSuggest **/

@media screen and (max-width: 1399.98px) {
    .autosuggestlist {
        /* top: 53%; */
        top: 38%;
        left: 12%;
        min-width: 64%;
    }
}

@media screen and (max-width: 1280px) {

    .autosuggestlist {
        top: 40%;
        left: 13.5%;
        min-width: 60%;
    }
}

@media screen and (max-width: 1200px) {

    .autosuggestlist {
        top: 28%;
        left: 2.2%;
        min-width: 95.3%;
    }
}

@media screen and (max-width: 912px) {

    .jobSearch-filter_mobile section {
        padding-left: 0px;
        padding-right: 0px;
        max-height: 50vh !important;
    }

    .jobFilterButtons {
        position: relative;
    }

    .jobFilterButtons-resetFilter {
        width: 100%;
        position: relative;
        top: -10px;
    }

    .jobFilterButtons-apply {
        position: absolute;
        top: 1032%;
        width: 90%
    }

    .job-filterResult-mobile {
        height: 50vh;
    }

    .buttonGroup {
        padding: 20px;
    }

    .tabs {
        /* margin-bottom: 20px; */
        flex-direction: column;
    }

    .autosuggestlist {
        top: 28%;
        left: 3%;
        min-width: 94%;
    }
}

@media screen and (max-width: 768px) {
    .jobSearch-filter_mobile section {
        max-height: 60vh !important;
    }

    .matchesTab .greeting h2 {
        color: var(--colour-text-primary)
    }

    .matchesTab .greeting p {
        color: var(--colour-text-primary)
    }

    .buttonGroup {
        padding: 20px;
    }

    .autosuggestlist {
        top: 28%;
        left: 3%;
        min-width: 93.5%;
    }
}

@media screen and (max-width: 475px) {

    .jobFilterButtons-apply {
        position: absolute;
        top: 995%;
        width: 84%;
    }

    .job-filterResult-mobile {
        height: 90vh;
    }

    .jobListTwoColumn li {
        margin-bottom: 0px;
    }

    .jobListTwoColumn {
        display: flex;
        flex-direction: column;
    }

    .jobWidget {
        padding: 12px;
    }

    .jobs-tabs {
        display: none;
    }

    .jobs-dropdown {
        display: unset;
    }

    .jobs_loadmore {
        padding-top: 18px;
        text-align: center;
    }

    .compare-jobs div:first-child>div:first-child {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .compare-jobs div:first-child div:first-child div:nth-child(3) {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .autosuggestlist {
        top: 24%;
        left: 6.2%;
        min-width: 87%;
    }
}

 .introduction-field{
    width: 100%;   
 }