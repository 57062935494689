.cardStart {
  /* background-color: white; */
  background-color: var(--colour-background-primary);
  color: var(--colour-text-primary);
  width: 700px;
  margin: 0px auto 30px;
  min-height: 400px;
  padding: 100px 100px;
  border-radius: 2.25rem;
}

.headerStart {
  font-family: var(--font-family-DIN-Next-LT-Pro);
  font-weight: 700;
  font-size: 44px;
  letter-spacing: -1px;
}

.topicStart {
  margin-top: 20px;
}

.topicBorder {
  /* border: 1px solid rgb(192, 192, 215); */
  border: 1px solid var(--colour-action-outline);
  padding: 2px 5px;
  border-radius: 5px;
}

.spanElements {
  font-weight: 500;
  font-size: 12px;
  color: var(--colour-action-feature);
  font-family: var(--font-family-DIN-Next-LT-Pro);
  margin-left: 10px;
}

.body {
  margin-top: 20px;
}

.bodyHeader {
  font-size: 30px;
}

.iview-interview-start-description {
  font-size: 24px;
}

.bodyContent {
  line-height: 20px;
  width: 80%;
  color: var(--colour-text-primary) !important;
}

.buttionContainer {
  margin-top: 20px;
}

.button {
  border: none;
  border-radius: 25px;
  background-color: #1456ff;
  padding: 10px 40px;
  font-weight: 700;
  color: white;
}

@media screen and (max-width: 768px) {
  .cardStart {
    margin-bottom: 20px !important;
    width: 575px;
    padding: 24px 60px;
  }

  .iView-Start .text-end {
    margin-left: -20px;
  }
}

@media screen and (max-width: 425px) {
  .headerStart {
    font-size: 28px;
  }

  .buttionContainer {
    text-align: center;
  }

  .cardStart {
    width: auto;
    margin: 0px 20px;
    padding: 30px 20px;
  }

  .iView-Start .text-end {
    margin-left: -35px;
  }

  .iView-Start {
    padding-left: unset !important;
    box-sizing: border-box !important;
  }

  .spanElements {
    display: inline-block;
  }

  .topicStart .topicBorder {
    display: inline-block;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 375px) {
  .cardStart {
    width: 280px;
    padding: 15px 40px;
  }

  .iView-Start .text-end {
    margin-left: -40px;
  }
}