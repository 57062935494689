.wrapper {
    position: fixed;
    background: rgb(0 0 0 / 50%);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10;
}

.spinnerPosition {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}