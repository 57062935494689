.inputStyle {
    padding: 16px 20px;
    box-sizing: border-box !important;
    width: 100%;
    border: 1px solid #dee1ea;
    /* height: 60px; */
    border-radius: 3px;
    font-size: 15px;
    font-weight: bold;
    color: #2e344d;
}
.inputStyle:focus {
    outline: 0;
    border: 2px solid #dee1ea;
}
.autoWrap {
    background-color: transparent;
    position: relative;
    z-index: 0;
    top: 0;
    left: 0;
    z-index: 1;
}

.itemWrap {
    background: #fff;
    padding: 0;
    list-style-type: none;
    min-width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    border: 1px solid #b6c1ce;
    border-radius: 2px;
    margin: 0;
    box-sizing: border-box;
    max-height: 280px;
    overflow-y: auto;
    z-index: 1;
}
.item {
    padding: 0 24px;
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
}
.item:hover {
    background-color: #1456ff;
    color: #fff;
}

.itemButton {
    width: 100%;
    line-height: 32px;
    text-align: left;
}
