.newmodal {
    display: none;
    position: fixed;
    z-index: 99;
    padding: 0.5rem;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
}

.newmodal.show {
    display: block;
}

.newmodal-content {
    border-radius: 25px;
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0.65rem;
    border: 1px solid #888;
    width: 80%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.newmodal-header {
    padding: 2px 16px;
    background-color: #fff;
    color: white;
    border-radius: 25px;
}

.newmodal-body {
    padding: 2px 16px;
}

.newmodal-footer {
    padding: 2px 16px;
    background-color: #fff;
    color: white;
    border-radius: 25px;
}

.closeCont {
    padding: 1.25rem;
    position: absolute;
    top: 0;
    right: 0;
    border: none;
}

.modalClose {
    border-radius: 100px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 1.875rem;
    width: 1.875rem;
    transition: all ease-in-out 100ms;
    cursor: pointer;
    background-color: #fff;
    color: #386196;
    border: 1px solid #DEE1EA;
}

.modalClose:hover {
    background-color: #282A32;
    color: #75D1FC;
    border: 1px solid #75D1FC;
}

.newmodal-title h2 {
    color: #282A32;
}

.scrollable {
    width: 100%;
    overflow-x: auto;
    border: 1px solid #f4f4f5;
}

.scrollable table {
    border-collapse: collapse;
}

.scrollable table tr td {
    text-align: left;
    padding: 10px;
    border-right: 1px solid #f4f4f5;
}

.scrollable td:first-child {
    font-weight: bold;
    color: #282A32;
    background-color: #f4f4f5;
    white-space: nowrap;
    width: 300px;
}

.scrollable div.description {
    overflow-y: scroll;
    white-space: normal;
    height: 200px;
    width: 350px;
}

@media screen and (min-width: 912px) {

    .scrollable tr>th:first-child,
    .scrollable tr>td:first-child {
        position: sticky;
        left: 0;
    }
}

@media screen and (max-width: 912px) {}

@media screen and (max-width: 768px) {}

@media screen and (max-width: 475px) {}