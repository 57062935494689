.assessment-page,
.assessment-page * {
  box-sizing: border-box !important;
  transition: all var(--transition-timing-function-default) var(--transition-speed-normal);
}

.main-page .assessment-page {
  position: relative;
  z-index: 1;
}

.assessment-page .background-White {
  background-color: #fff;
  padding: 80px;
}

.assessment-page .instructions {
  color: var(--colour-text-primary);
}

.description-container {
  /* padding: 0 40px !important; */
}

.assessment-page .image-overflow {
  margin-bottom: 0px;
  max-width: 100%;
  width: 100%;
  /* position: absolute;
  bottom: -40px; */
  object-fit: cover;
}

.assesment_startpage-image-wrapper {
  background: linear-gradient(148.28deg, #78E052 -3.49%, #1AB2FC 80.37%);
  padding-left: 16px;
  position: absolute;
  bottom: -80px;
  right: calc(var(--bs-gutter-x) * .5);
  left: calc(var(--bs-gutter-x) * .5);
  border-radius: 16px;
  overflow: hidden;
}

.t-dark .assessment-page .background-White {
  background-color: #12151E;
}

.t-dark .assessment-page .background-White h3 {
  color: #FFFFFF;
}

.t-dark .question-label {
  color: #ffffff !important;
}

.t-dark .progress-label {
  color: #fff !important;
}

.t-dark textarea {
  /* background-color: #12151E !important; */
  /* color: #fff !important; */
  border-color: var(--colour-action-outline);
}

.t-dark .assessment-page .background-White .saveBtn {
  background-color: #171D35;
  color: #FFFFFF;
}

.t-dark .assessment-page .background-White .likeBtn svg {
  color: #FFFFFF;
}

.t-dark .assessment-page .container-fluid p {
  color: #FFFFFF;
}

.assessment-page .save-section {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.assessment-page button.saveBtn {
  border: none;
  border-radius: var(--border-radius-softly-rounded);
  padding: var(--spacing-4) var(--spacing-8);
  background-color: var(--colour-action-background);
  color: var(--colour-action-feature);
  height: 1.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all var(--transition-timing-function-default) var(--transition-speed-normal);
}

.assessment-page button.saveBtn:hover {
  border: none;
  background-color: var(--colour-action-primary);
  color: var(--colour-action-primary-text);
}

.assessment-page button.saveBtn span.saveBtnText {
  margin-left: 0.375rem;
}

.t-dark .assessment-page button.saveBtn {
  /* background-color: var(--colour-action-background); */
}

.assessment-page #category-label {
  margin-left: 0px;
  font-size: 14px;
  border: 1px solid #d9e2ff;
  border-radius: 4px;
  padding: 3px 8px 2px;
}

.t-dark .assessment-page #category-label {
  border-color: var(--colour-action-outline);
}

.assessment-page #cardtype {
  margin-left: 0px;
  font-size: 14px;
}

.assessment-page button.likeBtn {
  border: none;
  padding: 0;
  background-color: var(--colour-background-primary);
  color: var(--colour-text-primary);
}

.assessment-page button.likeBtn:hover {
  border: none;
  color: var(--colour-text-primary);
}

.assessment-page button.likeBtn svg {
  vertical-align: bottom;
}

.assessment-page button.likeBtn span.likeBtnText {
  font-size: 12px;
}

.assessment-page .progress.progressBar {
  width: 65%;
  height: 8px;
  /* background-color: #dee1ea; */
  background-color: var(--colour-action-disabled);
}

.t-dark .assessment-page .progress.progressBar {
  background-color: var(--colour-action-outline);
}

.assessment-page .progress .progress-bar {
  /* background-color: #1456ff; */
  background-color: var(--colour-action-primary);
}

.assessment-page .assessment-review-result-wrapper .progress .progress-bar {
  background-color: var(--colour-action-feature);
  box-shadow: 0px 0px 4px rgba(0, 4, 23, 0.05), 0px 8px 15px 2px rgba(0, 4, 23, 0.1);
  border-radius: 90px;
}

.assessment-page .progress-wrapper {
  width: 100%;
  max-width: 340px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.assessment-page .strength-progress-bar .progress.progressBar {
  background-color: var(--colour-container-on-secondary);
  border: 1px solid var(--colour-border-hairline);
  height: 16px;
  border-radius: 90px;
  overflow: unset;
}

.assessment-page .strength-interest-result_page .progress-wrapper label {
  font-family: var(--font-family-DIN-Next-LT-Pro);
  font-style: normal;
  font-weight: var(--font-weight-bold);
  font-size: 28px;
  line-height: 34px;

  color: var(--colour-action-feature);
}

.skill-resultpage .progress-wrapper label {
  font-family: var(--font-family-DIN-Next-LT-Pro);
  font-style: normal;
  font-weight: var(--font-weight-bold);
  font-size: 28px;
  line-height: 34px;

  color: var(--colour-action-feature);
}

.assessment-page .progress-label {
  width: 33%;
}

.assessment-page .loader {
  /* position: absolute; */
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.25);
  /* border-radius: 32px; */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.assessment-page .container.assessment-question-wrapper {
  background-color: var(--colour-background-primary);
  color: var(--colour-text-primary);
  border-radius: 32px;
  min-height: 600px;
  padding: 50px 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: none;
}

.role button {
  height: 40px;
  width: 165px;
  left: 0.5px;
  top: 0px;
  border-radius: 90px;
  padding: 8px;
}

.job-list {
  background-color: var(--colour-container-on-primary);
  padding: 16px;
  border-radius: 8px;
  position: relative;
  border: 1px solid var(--colour-container-on-primary);
}

.job-list:hover {
  border: 1px solid var(--colour-action-feature);
}

.job-list .job-title {
  font-weight: var(--font-weight-bold);
  font-size: 1.1875rem;
  line-height: 1.75rem;
  color: var(--colour-text-primary);
}

.job-list .compName {
  font-weight: var(--font-weight-regular);
  font-size: 1rem;
  line-height: 1.625rem;
  color: var(--colour-text-primary);
  margin: 0;
}

.job-list label {
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 10px;
  /* background: white; */
  line-height: 0;
  border-radius: 5px;
}


.jobPlatform {
  height: 90px;
  width: 793px;
  left: 0px;
  top: 0px;
  border-radius: 8px;
  padding: 10px;
  justify-content: space-between;
  background-color: var(--colour-container-on-primary);
  position: relative;
  margin-bottom: 10px;
}

.resultHead {
  text-align: left;
}

.ResultResponseShow {
  display: none;
}

.ResultResponsHide {
  display: block;
}

.HiddenResponse {
  font-family: var(--font-family-DIN-Next-LT-Pro);
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.04em;
  text-align: left;
}

.Resultpointer p {
  width: fit-content;
  padding: 8px;
  border: 1px solid var(--colour-action-outline);
  font-family: var(--font-family-Helvetica-Neue-LT-Pro);
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  border-radius: 4px;
}

.check p {
  background-color: var(--colour-container-on-primary);
  border: 1px solid var(--colour-action-outline) !important;
}

.checkWh p {
  background-color: var(--colour-container-on-secondary);
  border: 1px solid var(--colour-action-outline) !important;
}

.highlgt p {
  background-color: rgba(0, 196, 111, 0.12)
}

.borderless {
  background-color: var(--colour-background-secondary) !important;
  color: var(--colour-background-secondary) !important;
  border: none !important;
}

.borderlessWH {
  background-color: var(--colour-background-primary) !important;
  color: var(--colour-background-primary) !important;
  border: none !important;
}

.JobRoleHeading {
  font-family: var(--font-family-DIN-Next-LT-Pro);
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: -0.04em;
  text-align: left;
}

.resultBack {
  background-color: var(--colour-background-primary);
  border: 1px solid var(--colour-border-hairline);
  border-radius: 15px;
  padding-left: 10px;
  overflow: hidden;
}

.JobRoleHeader {
  background-color: var(--colour-background-secondary);
  padding: 10px;
}

.JobRoleHeaderPDF {
  background-color: var(--colour-background-secondary);
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 10px;
}

.JobRoleHeaderUncolored {
  padding: 10px;
}


.lineB {
  border-bottom: 1px solid var(--colour-border-hairline);
}

.resultIntro {
  margin-bottom: 20px;
  width: 80%;
  /* font-family: var(--font-family-Helvetica-Neue-LT-Pro); */
  font-family: var(--font-family-Inter);
  font-size: 28px;
  font-weight: 300;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
}

.assessment-page .resultIntro p {
  font-family: var(--font-family-Inter);
}

.platBtn {
  margin: 15px !important;
  width: 20%;
}

.platFormheader {
  height: 28px;
  width: 372px;
  left: 0px;
  top: 0px;
  border-radius: nullpx;
  font-family: Helvetica Neue LT Pro;
  font-size: 19px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--colour-text-primary);
}

.platFormcompany {
  height: 30px;
  width: 372px;
  left: 0px;
  top: 28px;
  border-radius: nullpx;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;

}

.platformSaved {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
  background-color: var(--colour-container-on-secondary);
  border-radius: 5px;
  padding: 3px;
  width: 15%;
}

.platformSaved p {
  padding: 0;
  margin: 0;
  color: var(--colour-action-feature);
}

.platformSaved input {
  padding-right: 3px;
  color: var(--colour-action-feature);
}

.checked {
  background-color: var(--colour-action-primary);
  color: var(--colour-action-primary-text);
}

.checked p {
  color: var(--colour-action-primary-text);
}

.checkedBox {
  border: 1px solid var(--colour-action-feature);
}

.assessment-page .assessment-question-wrapper .options {
  display: flex;
  column-gap: 25px;
  align-items: center;
  justify-content: center;
}

.assessment-page .assessment-question-wrapper .options label.custom-radio-button {
  border-radius: 30px;
  border: 1px solid #dee1ea;
  padding: 7px 16px;
  color: var(--colour-action-feature);
  /* color: var(--colour-action-primary); */
  font-weight: var(--font-weight-medium);
  transition: all var(--transition-timing-function-default) var(--transition-speed-normal);
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.t-dark .assessment-page .assessment-question-wrapper .options label.custom-radio-button {
  /* color: var(--colour-action-primary-text); */
  color: var(--colour-text-primary);
}

.assessment-page .assessment-question-wrapper .options label.custom-radio-button.active,
.assessment-page .assessment-question-wrapper .options label.custom-radio-button:active {
  /* background-color: var(--colour-action-primary); */
  /* color: var(--colour-action-secondary-text); */
  color: var(--colour-text-inverse);
  background-color: var(--colour-action-feature);
}

.assessment-page .assessment-question-wrapper .options label.custom-radio-button:hover {
  /* border-color: var(--colour-action-primary); */
  border-color: var(--colour-action-feature);
}

.assessment-page .assessment-question-wrapper .options label.custom-radio-button span {
  opacity: 0;
}

.assessment-page .assessment-question-wrapper .options label.custom-radio-button input[type='radio'] {
  opacity: 0;
  position: absolute;
}

.assessment-page .assessment-question-wrapper .options .input-border:focus,
.assessment-page .assessment-question-wrapper .options .input-border:focus-within {
  outline: none;
}

.assessment-page .assessment-review-result-wrapper {
  background-color: var(--colour-background-primary);
  border-radius: 32px;
  min-height: 450px;
  text-align: center;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: var(--colour-text-primary);
}

.assessment-page .assessment-review-result-wrapper ul li {
  word-wrap: break-word;
}

.t-dark .border-bottom {
  border-color: var(--colour-border-hairline) !important;
}

.assessment-page .assessment-review-result-wrapper p {
  color: var(--colour-text-primary);
  word-break: break-word;
}

.mb-2 {
  /* Dont use this bootstrap class, create another class to customize */
  /* padding-bottom: 20px; */
}

.question-label {
  font-family: 'Helvetica Neue LT Pro' !important;
  font-style: normal;
  font-weight: 300 !important;
  font-size: 24px !important;
  line-height: 32px;
  text-align: center;
  color: #282a32 !important;
  flex: none;
  order: 0;
  flex-grow: 0;
}

textarea {
  font-family: var(--font-family-Helvetica-Neue-LT-Pro) !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 1rem !important;
  line-height: 26px !important;
  /* color: #3e3f47 !important; */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 10px 15px 20px;
  gap: 10px;
  width: 100%;
  height: 169px;
  background: var(--colour-background-primary);
  color: var(--colour-text-primary);
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.exit {
  margin-left: auto;
}

.continue-btn {
  /* display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 8px !important;
  gap: 4px !important;
  width: 134px;
  height: 40px;
  background: #75d1fc !important;
  border-radius: 90px !important;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-left: auto !important;
  color: #12151E !important; */
}

.section-heading {
  margin-bottom: 20px;
  width: 624px !important;
  font-family: 'Helvetica Neue LT Pro' !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 28px;
  color: #2e344d !important;
}

.section-title {
  font-family: 'Helvetica Neue LT Pro';
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  color: #2e344d;
}

.question-title {
  list-style: none;
  font-family: 'Helvetica Neue LT Pro' !important;
  /* color: #646e8a !important; */
  color: var(--colour-text-primary);
  position: relative;
  padding-left: 20px;
}

.question-title::before {
  content: '\2022';
  /* color: blue; */
  color: var(--colour-action-primary);
  margin-right: 10px;
  position: absolute;
  left: 0px;
}

.t-dark .question-title::before {
  content: '\2022';
  color: var(--colour-action-feature);
  margin-right: 10px;
}

.answers-row {
  margin-left: 20px;
  font-family: 'Helvetica Neue LT Pro' !important;
  color: #646e8a !important;
}

.question {
  text-align: start;
  margin-bottom: 5px;
  font-size: 16px !important;
  font-family: 'Helvetica Neue LT Pro' !important;
  font-weight: 400 !important;
  color: #282a32 !important;
}

.exit {
  margin-left: auto;
}

.assessment-page .column-wrapper {
  /* border: 1px solid var(--colour-action-background); */
  border: 1px solid var(--colour-action-outline);
  border-radius: 10px;
  height: 100%;
}

.assessment-page .active.column-wrapper {
  border-color: #386196;
  background: #386196;
}

.assessment-page .error.column-wrapper {
  border-color: var(--colour-global-red);
}

.assessment-page .column-title {
  background-color: var(--colour-container-small);
  font-weight: var(--font-weight-medium);
  padding: 14px;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  /* color: var(--colour-action-primary); */
  color: var(--colour-text-primary);
}

.assessment-page .active.column-wrapper .column-title {
  background-color: var(--colour-action-primary);
  color: var(--colour-text-inverse);
}

.assessment-page .error .column-title {
  background-color: var(--colour-global-red);
  color: #fff;
}

.t-dark .assessment-page .column-title {
  color: #fff;
}

.assessment-page .column-list {
  background-color: var(--colour-container-on-primary);
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  height: calc(100% - 52px);
  /* flex: ; */
}

.assessment-page .column-item {
  display: inline-flex;
  align-items: center;
  align-self: flex-start;
  column-gap: 5px;
  background-color: var(--colour-background-primary);
  border: 1px solid var(--colour-container-featured-light);
  color: var(--colour-text-primary);
  border-radius: 4px;
  padding: 4px 10px;
  font-size: 14px;
  cursor: move;
  max-width: 100%;
  transition: all var(--transition-timing-function-default) var(--transition-speed-normal);
}

.t-dark .assessment-page .column-item {
  background-color: var(--colour-action-disabled);
}

#assessment-result-wrapper {
  box-sizing: border-box !important;
  display: none;
}

.assessment-page .assessment-result-wrapper .column-item {
  cursor: pointer;
}

.question-answer-wrapper {
  padding: 10px 150px;
}

.question-answer-wrapper ul {
  padding: 0px;
}

.assessment-result-wrapper .section-title {
  padding: 5px 150px;
  margin-left: 6px;
}

.t-dark .assessment-result-wrapper .question-title {
  /* color: #fff !important; */
}

.assessment-result-wrapper .top-nav {
  padding: 30px 20px 30px 30px;
}

.assessment-page .column-item:hover {
  border-color: #386196;
  color: #386196;
}

.t-dark .assessment-page .column-item:hover {
  background-color: var(--colour-background-primary);
  border-color: var(--colour-action-feature);
  color: var(--colour-action-feature);
}

.assessment-page .column-item:active {
  border-color: #386196;
  background-color: #386196;
  color: #fff;
}

.assessment-page .assessment-result-wrapper .column-item:hover,
.assessment-page .assessment-result-wrapper .column-item:active {
  border-color: var(--colour-container-featured-light);
  background-color: var(--colour-action-disabled);
  color: var(--colour-text-primary);
  cursor: default;
}

.assessment-page .column-item.dragging {
  opacity: 0;
}

.assessment-page .column-item span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.assessment-page .column-item i {
  cursor: pointer;
}

.assessment-page div.assessment-error {
  border: 1px solid var(--colour-text-error);
  padding: var(--spacing-16) !important;
}

.assessment-page .assessment-error>div {
  display: flex;
  column-gap: 20px;
}

.assessment-page .assessment-error>div div {
  margin-bottom: 0px;
  text-align: left;
}

.assessment-page .assessment-error>div i {
  font-size: 20px;
}

.assessment-page .assessment-error button {
  display: none;
}

.assessment-page .custom-tooltip .tooltip-inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 250px;
}

.assessment-page .custom-tooltip .tooltip-inner i {
  /* font-size: 20px; */
  margin-left: 10px;
}

.assessment-page .assessment-question-wrapper .single-row-qna {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.assessment-page .assessment-question-wrapper .iyip-questionTitle {
  font-size: 18px;
  text-align: left;
}

.assessment-page .assessment-question-wrapper .iyip-options {
  display: flex;
  flex-wrap: wrap;
  gap: 15px 15px;
  align-items: flex-start;
  justify-content: flex-start;
}

.assessment-page .assessment-question-wrapper .iyip-options.equal-width-options {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
}

.assessment-page .assessment-question-wrapper .iyip-options label {
  align-items: flex-start;
  word-break: normal;
}

.assessment-page .assessment-question-wrapper .iyip-options label span:nth-of-type(1) {
  margin-top: 6px;
}

.assessment-page .assessment-question-wrapper .single-row-qna .iyip-options {
  gap: 6px;
  display: flex;
}

.assessment-page .assessment-question-wrapper .iyip-options [type=radio]+span.checkmark {
  top: -3px;
}

.assessment-page .assessment-question-wrapper .iyip-options [type=radio]:checked+span.checkmark:after {
  top: 4px;
  left: 4px;
  width: 8px;
  height: 8px;
}

/* .assessment-page .assessment-question-wrapper .iyip-options.equal-width-options label {
  width: 180px;
  column-gap: 10px;
  align-items: flex-start;
}

.assessment-page .assessment-question-wrapper .iyip-options.equal-width-options label [type=checkbox] + span {
  margin-top: 5px;
  min-width: 1.125rem;
}
.assessment-page .assessment-question-wrapper .iyip-options.equal-width-options label span {
  margin: 0px;
  overflow-wrap: anywhere;
  hyphens: auto;
} */

.custom-radio-checkbox {
  display: flex;
  align-items: flex-start;
  column-gap: 10px;
  cursor: pointer;
  width: 200px;
}

.custom-radio-checkbox input[type=radio] {
  display: none;
}

.custom-radio-checkbox span {
  border: solid 2px var(--colour-text-secondary);
  border-radius: 0.125rem;
  width: 1.125rem;
  height: 1.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3px;
}

.custom-radio-checkbox input[type=radio]:checked+span {
  background-color: var(--colour-action-primary);
  border: none;
  padding: 2px;
}

.custom-radio-checkbox span i {
  color: var(--colour-global-white);
}

.ra-result-titles {
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 15px;
  color: var(--colour-text-primary);
}

.assessment-result-wrapper .result-question-title {
  font-size: 20px;
  margin-bottom: 10px;
}

.result-options {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding-bottom: 15px;
}

.result-options label {
  background-color: var(--colour-background-secondary);
  color: var(--colour-text-primary);
  border: 1px solid var(--colour-action-outline);
  padding: 5px 15px;
  font-size: 16px;
}

.optionAnsUl,
.questionOption {
  padding: 0;
  margin: 0;
  display: flex;
  flex-flow: row wrap;
  /* align-content: space-between;
  justify-content: space-between; */
}

.optionAnsUl li {
  margin: 0 15px 15px 0;
  list-style-type: none;
  background-color: var(--colour-background-secondary);
  color: var(--colour-text-primary);
  border: 1px solid var(--colour-action-outline);
  padding: 5px 15px;
  font-size: 16px;
}

.questionOption li {
  padding: 0;
  list-style-type: none;
  margin: 0 15px 12px 0;
}

.custom-yes-no-radio {
  cursor: pointer;
  position: relative;
}

.custom-yes-no-radio input[type=radio] {
  opacity: 0;
  position: absolute;
}

.custom-yes-no-radio span {
  border-radius: 100%;
  /* background-color: var(--colour-background-secondary); */
  background-color: var(--colour-container-small);
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  /* color: #386196; */
  color: var(--colour-action-small);
}

.custom-yes-no-radio span:hover {
  border: 1px solid var(--colour-action-primary);
}

.custom-yes-no-radio input[type=radio]:checked+span,
.custom-yes-no-radio.active input[type=radio]+span {
  background-color: var(--colour-action-primary);
  color: var(--colour-action-primary-text);
}

.headerRadios {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
}

.headerRadios .custom-yes-no-radio {
  display: none;
}

.headerRadios .custom-yes-no-radio span {
  width: 18px;
  height: 18px;
  font-size: 12px;
}

.slider-row {
  background-color: rgba(244, 244, 245, 0.5);
  border-radius: 10px;
}

.t-dark .slider-row {
  background-color: rgba(40, 42, 50, 0.5);
}

.top-description {
  color: var(--colour-text-secondary);
}

.slider-title {
  background-color: var(--colour-background-secondary);
  border-radius: 10px 0px 0px 10px;
  display: flex;
  align-items: center;
  padding-top: 13px;
  padding-bottom: 13px;
}

.slider-title label {
  padding-left: 10px;
}

.slider-row .slider-column {
  padding-left: 55px;
  padding-right: 80px;
}

.skill-questions-slider .slider-row .slider-column {
  padding-left: 55px;
  padding-right: 61px;
}

.result-progress {
  background-color: var(--colour-background-secondary);
  border-radius: 4px;
}

.card-status {
  position: relative;
}

.card-status:after {
  content: "";
  display: block;
  width: 2px;
  height: 80%;
  background-color: #e8e8e8;
  /* border: 1px solid #e8e8e8; */
  margin: auto;
}

.card-row:first-child .card-status span {
  background-color: var(--colour-action-primary);
  margin-top: 22px;
}

.card-status span {
  position: relative;
  z-index: inherit;
  border-radius: 100%;
  width: 32px;
  height: 32px;
  background-color: var(--colour-action-disabled);
  color: var(--colour-text-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin: 20px auto 10px;
}

.t-dark .card-status span {
  color: var(--colour-text-inverse);
}

.card-status span i {
  display: none;
}

.card-status.inprogress::after {
  background-color: var(--colour-action-primary);
}

.card-status.inprogress span {
  background-color: var(--colour-container-small);
}

.card-status.completed::after {
  background-color: var(--colour-action-primary);
}

.card-status.completed span {
  background-color: var(--colour-action-primary);
  box-shadow: 0px 0px 0px 8px rgba(20, 86, 255, 0.2);

}

.card-status.completed span i {
  display: block;
}

.card-row:last-child .card-status::after {
  display: none;
}

.t-dark .background-White.skill-matrix-section {
  background-color: #000 !important;
}

.skill-matrix {
  background-color: #f6fcff;
  border-radius: 6px;
  height: 100%;
}

.t-dark .skill-matrix {
  background-color: #0b131e;
}

.t-dark .skill-overlap .skill-matrix {
  background-color: #f1fbff;
  border: 1px solid #d5f1fe;
}

.skill-title {
  background-color: #282A32;
  background-color: var(--colour-container-featured);
  /* color: var(--colour-text-inverse); */
  text-align: center;
  border-radius: 5px 5px 0px 0px;
  padding: 10px;
}

.skill-title label {
  color: var(--colour-text-inverse);
}

.t-dark .skill-title label {
  color: var(--colour-text-primary);
}

.t-dark .skill-overlap .skill-title label {
  color: var(--colour-text-inverse);
}

.item-border {
  border-bottom: 1px solid var(--colour-border-hairline);
}

.skill-list {
  padding: 10px;
}

.skill-list li {
  position: relative;
  list-style-type: none;
  padding-left: 15px;
  margin-bottom: 5px;
}

.skill-list li:before {
  content: '';
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50px;
  background-color: var(--colour-text-primary);
  top: 10px;
  left: 0;
}

.skill-overlap {
  transform: scale(1.1);
  background-color: #f1fbff;
  border: 1px solid #d5f1fe;
}

.t-dark .skill-overlap {
  border: 1px solid var(--colour-action-primary);
  background-color: #111d2d;
}

.skill-overlap .skill-title {
  background-color: var(--colour-action-feature);
  padding: 12px 10px 13px;
}

.card-style {
  border-radius: 20px;
  font-family: var(--font-family-Helvetica-Neue-LT-Pro);
  background-color: var(--colour-background-primary);
  /* height: 200px; */
  padding: 25px 25px 25px 40px;
}

.card-style.card-disabled {
  background-color: var(--colour-action-disabled);
}

.card-style.card-disabled .title {
  /* color: var(--colour-action-disabled-text); */
}

.card-style.card-disabled .cardFooter label {
  /* color: var(--colour-action-disabled-text); */
}

.card-style.card-disabled p div {
  /* color: var(--colour-action-disabled-text) !important; */
}

.card-style .title {
  font-weight: var(--font-weight-bold);
  font-size: 1.1875rem;
  line-height: 1.75rem;
  color: var(--colour-text-primary);
}

.card-style p {
  font-weight: var(--font-weight-regular);
  font-size: 1rem;
  line-height: 1.625rem;
  color: var(--colour-text-secondary);
  margin-top: 1.125rem;
}

.cardFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cardFooter button {
  margin: 0 8px 0 0 !important;
}

.matrix-label {
  background: var(--colour-container-small);
  border-radius: 10px;
  padding: 15px;
  color: var(--colour-text-primary);
  font-size: 16px;
}

.assessment-page .text-input {
  display: flex;
  align-items: center;
  /* position: relative; */
  padding: var(--spacing-16) var(--spacing-12) var(--spacing-16) var(--spacing-20);
  box-sizing: border-box;
  background-color: var(--colour-background-primary);
  border: var(--colour-action-outline) var(--border-thickness-thin) solid;
  border-radius: var(--border-radius-very-softly-rounded);
  color: var(--colour-text-primary);
  width: 100%;
  font-size: 1rem;
}

.assessment-page .text-input:focus-within {
  outline: var(--colour-border-hairline) var(--border-thickness-thin) solid;
}

.collapsible-career-path {
  background: var(--colour-container-on-primary);
  border-radius: 10px;
}

.career-path-header {
  cursor: pointer;
}

.career-path-header label {
  font-size: 18px;
  word-break: break-word;
  max-width: 80%;
}

.career-path-header span {
  font-size: 1rem;
}

.career-path-header i {
  font-size: 9px;
  margin-left: 10px;
}

.careerPathDesc {
  border-top: 1px solid var(--colour-border-hairline);
  word-break: break-word;
}

.delete-modal {
  color: var(--colour-text-primary);
}

.factors-wrapper {
  background-color: var(--colour-container-on-primary);
  border-radius: 15px;
  color: var(--colour-text-primary);
  box-sizing: border-box !important;
}

.factors-wrapper ul {
  padding-left: 20px;
  font-weight: 400;
}

.career-labels .factor-category label {
  font-weight: 700;
  text-transform: uppercase;
  font-family: var(--font-family-DIN-Next-LT-Pro);
  letter-spacing: 0.04em;
  font-size: 14px;
}

.rotate-arrow {
  display: inline-block;
  transform: rotate(180deg);
  vertical-align: middle;
}

.edit-career-title {
  border-bottom: 2px solid var(--colour-action-small);
}

.career-labels label {
  font-size: 18px;
  margin-bottom: 10px;
}

.factor-category li::marker {
  color: var(--colour-action-primary);
}

#assessment-result-wrapper .back-exit-container,
#assessment-result-wrapper .you-are-done-wrapper {
  display: none;
}

#assessment-result-wrapper footer {
  display: none;
}

.job-scroll {
  max-height: 510px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;
}

.appliedJobsSection {
  max-height: 490px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 30px;
  padding: 0 10px;
}

.selectJobLabel {
  background-color: var(--colour-background-primary);
  padding: 3px 10px 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.applied-jobs-list.active .selectJobLabel,
.applied-jobs-list:active .selectJobLabel,
.job-list.active .selectJobLabel,
.job-list:active .selectJobLabel {
  background-color: var(--colour-action-primary);
  color: var(--colour-action-primary-text);
}

.selectJobLabel i {
  margin-right: 5px;
  vertical-align: middle;
}

.selectJobLabel span {
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
}

.selectJobLabel input {
  display: none;
}

.selectJobLabel input~.unselected {
  display: inline-block;
}

.selectJobLabel input~.selected {
  display: none;
}

.selectJobLabel input:checked~.unselected {
  display: none;
}

.selectJobLabel input:checked~.selected {
  display: inline-block;
}

.applied-jobs-list {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background-color: var(--colour-container-on-primary);
  border: 1px solid var(--colour-container-on-primary);
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 8px;
  height: 90px;
}

.applied-jobs-list:hover,
.applied-jobs-list.active,
.applied-jobs-list:active {
  border: 1px solid var(--colour-action-feature);
}

.applied-jobs-list p {
  font-size: 14px;
}

.job-role-details-section {
  background-color: var(--colour-container-on-primary);
  padding: 8px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  border-radius: 8px;
  min-height: 58px;
}

.job-description-modal section {
  max-width: 480px;
}

.job-document-modal section {
  max-width: none;
  width: 80%;
  height: 100%;
  max-height: 80%;
}

.job-document-modal .job-description+div {
  max-width: 96%;
  height: 100%;
  max-height: 88%;
}

.assessment-review-result-wrapper li::marker {
  color: var(--colour-action-primary);
}

.help-tips {
  background-color: var(--colour-background-secondary);
  border: 1px solid var(--colour-border-hairline);
  border-radius: 5px;
  text-align: left;
}

.tip-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  color: var(--colour-text-secondary);
  cursor: pointer;
  padding: 10px;
}

.tip-icon {
  background-color: var(--colour-action-feature);
  border-radius: var(--border-radius-pill);
  color: var(--colour-action-secondary-text);
  padding: 6px;
  margin-right: 10px;
  display: inline-flex;
  vertical-align: middle;
}

.tip-header .icon-button-arrow {
  font-size: 10px;
  transition: none;
}

.t-dark .tip-icon {
  color: #6F6F6E;
}

.help-tips-desc {
  font-size: 14px;
  padding: 10px;
  color: var(--colour-text-secondary);
}

.assessment-question-wrapper .row:nth-child(odd) .help-tips {
  display: none;
}


.VD-mobile-page .assessment-review-result-wrapper {
  min-height: auto;
  border-radius: 0px;
}

.vd-accordion {
  background: var(--colour-background-primary);
  border-radius: 5px;
  overflow: hidden;
}

.vd-toggleHeader {
  background: var(--colour-container-featured);
  color: var(--colour-action-secondary-text);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 20px;
  font-size: 16px;
}

.error .vd-toggleHeader {
  background-color: var(--colour-global-red);
}

.vd-toggleHeader i {
  font-size: 10px;
}

.vd-toggleBody {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid var(--colour-border-hairline);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px
}

.error .vd-toggleBody {
  border-color: var(--colour-global-red);
}

.edit-option {
  padding: 5px 5px 5px 10px;
  margin: -5px 0px;
  border-left: 1px solid var(--colour-border-hairline);
}

.assessment-page .vd-toggleBody .column-item {
  background: var(--colour-background-secondary);
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px;
  cursor: default;
}

.assessment-page .vd-toggleBody .column-item:active {
  background-color: #386196;
}

.assessment-page .vd-toggleBody .column-item span {
  padding-left: 10px;
}

.custom-tooltip .tooltip-inner {
  display: flex;
  gap: 10px;
  align-items: center;
}

.categories-dropdown {
  min-width: 250px;
}

.refineQuestion-mobile {
  display: none !important;
}

.refineQuestion-dekstop {
  display: unset !important;
}

.strength-analysis_cards {
  padding-left: 3rem;
  padding-right: 3rem;
}

.t-dark input[type=range]::-webkit-slider-thumb {
  background-color: var(--colour-action-feature);
}

.t-dark input[type=range]::-webkit-slider-runnable-track {
  background-color: var(--colour-action-disabled);
}

input[type=range]::-webkit-slider-thumb {
  background-color: var(--colour-action-feature);
}

.about-role_question {
  max-width: 660px;
}

.skill-heading {
  font-size: 20px;
}

.ejo_result-page {
  padding-left: 41px !important;
}

.ejo_result_page .Resultpointer p {
  margin-bottom: 12px !important;
  border: 1px solid var(--colour-action-outline) !important;
}

.nojob-alertModal,
.nojob-alertModal * {
  text-align: center !important;
}

.RA_question-page .input-border {
  padding: 10px !important;
}

.sa-interest-questions .icon-close {
  font-size: 16px;
}

/* Tablet and Mobile Responsive */
@media (max-width: 1024px) {
  .assessment-page.iyip-page .container.assessment-question-wrapper {
    padding: 50px;
  }
}

@media (max-width: 992px) {
  .refineQuestion-mobile .refining-strength-bar{
    margin-top: -15px;
  }

  .refineQuestion-mobile .range-bar-text{
    margin-top: -30px;
  }

  .headerRadios .icon-close {
    font-size: 8px;
  }

  .skill-questions-slider .slider-row .slider-column{
    padding-left: 65px;
    padding-right: 65px;
  }

  .assessment-page .container.assessment-question-wrapper {
    padding: 50px;
  }

  .vd_assesment-qpage.assessment-page .container.assessment-question-wrapper {
    align-items: unset;
  }

  .jobPlatform {
    position: relative;
    width: 100%;
    left: 15px;
    height: 90px;
    border-radius: 8px;
    padding: 16px;
    justify-content: space-between;

  }

  .resultBack {
    padding: 20px;
    border: none;
  }

  .resultBackSm {
    background-color: var(--colour-background-primary);
    border: 1px solid var(--colour-border-hairline);
    padding-top: 8px;
    border-radius: 8px 8px 0px 0px;

  }

  .lineB {
    border-bottom: none;
  }

  .JobRoleHeader {
    margin-top: -10px;
    border-radius: 0px 0px 8px 8px;
  }

  .JobRoleHeaderUncolored {
    padding-bottom: 0px;
  }

  .resultIntro {
    width: 100%;
    /* font-family: var(--font-family-Helvetica-Neue-LT-Pro); */
    font-family: var(--font-family-Inter);
    font-size: 24px;
    font-weight: 300;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
  }

  .assessment-page .resultIntro p {
    font-family: var(--font-family-Inter);
  }

  .Label {
    margin-left: -15px;
  }

  .platFormheader {
    height: 28px;
    width: 372px;
    left: 0px;
    top: 0px;
    border-radius: nullpx;
  }

  .platFormcompany {
    height: 30px;
    width: 372px;
    left: 0px;
    top: 28px;
    border-radius: nullpx;
  }

  .platformSaved {
    width: 20%;
  }

  .ResultResponsHide {
    display: none;
  }

  .ResultResponseShow {
    display: block;
    font-size: 28px;
    font-weight: 700;
    line-height: 34px !important;
    letter-spacing: -0.04em;
    text-align: left;
  }

  .assessment-page .image-overflow {
    /* margin-bottom: -30%;
    position: static; */
    object-fit: cover;
  }

  .assesment_startpage-image-wrapper {
    margin-bottom: -30%;
    position: static;
  }

  .custom-mb-20 {
    margin-bottom: 23%;
  }

  .assessment-page .assessment-question-wrapper .options {
    flex-direction: column;
    row-gap: 15px;
  }

  .assessment-page .assessment-question-wrapper .options label.custom-radio-button {
    width: 100%;
  }

  .assessment-page .assessment-result-wrapper {
    text-align: left;
  }

  .assessment-page .assessment-question-wrapper .single-row-qna {
    flex-direction: column;
  }

  .assessment-page .assessment-question-wrapper .lao-single-row-qna {
    align-items: flex-start;
  }

  .assessment-page .assessment-question-wrapper .iyip-options {
    flex-direction: row;
    gap: 10px;
    /* justify-content: center; */
  }

  .assessment-page .assessment-question-wrapper .iyip-options label.custom-radio-button {
    width: auto;
  }

  .assessment-page .assessment-question-wrapper .iyip-questionSection {
    text-align: center;
  }

  .question-answer-wrapper {
    padding: 10px 50px;
  }

  .assessment-result-wrapper .section-title {
    padding: 10px 50px;
  }
}

/* @media (max-width: 768px) { */

@media (max-width: 992px) {
  .skill-matrix {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .skill-title {
    text-align: left;
    padding: 10px var(--spacing-24);
  }
  .skill-list{
    padding: 10px var(--spacing-24);
  }
  .skill-list ul {
    padding: 0;
  }
  .skill-overlap .skill-title {
    padding: 12px var(--spacing-24) 13px;
  }
  .ejo_result-page {
    padding-left: 32px !important;
  }

  .assessment-page .assessment-question-wrapper .iyip-options.equal-width-options {
    grid-template-columns: 50% 50%;
    text-align: left;
  }

  .assessment-page .assessment-question-wrapper .iyip-options label {
    /* word-break: normal; */
  }

  .skill-overlap {
    transform: scale(1);
    /* margin-top:0px; */
  }

  .headerRadios .custom-yes-no-radio {
    display: inline-block;
  }

  .looking-atoption_assessment-page .assessment_introHeading {
    margin-top: 225px !important;
  }

  .assessment-page .container.assessment-question-wrapper {
    /* border-radius: 0px; */
    padding: 40px;
  }

  .assessment_second-container-intro {
    margin-top: 40px !important;
    /* margin-bottom: 2rem !important; */
    padding: 1.5rem !important;
  }

  .assessment_introHeading {
    font-size: 1.5rem !important;
  }

  .role {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .assessment-page .progress-wrapper {
    justify-content: center;
  }

  .assessment-page .image-overflow {
    object-fit: cover;
  }

  .jobPlatform {
    position: relative;
    left: 15px;
    height: 139px;
    border-radius: 8px;

  }

  .resultBack {
    padding: 20px;
    border: none;
  }

  .resultBackSm {
    background-color: var(--colour-background-primary);
    border: 1px solid var(--colour-border-hairline);
    padding-top: 8px;
    border-radius: 8px 8px 0px 0px;
  }

  .lineB {
    border-bottom: none;
  }

  .JobRoleHeader {
    margin-top: -10px;
    border-radius: 0px 0px 8px 8px;
  }

  .JobRoleHeaderUncolored {
    padding-bottom: 0px;
  }

  .resultIntro {
    width: 100%;
    /* font-family: var(--font-family-Helvetica-Neue-LT-Pro); */
    font-family: var(--font-family-Inter);
    font-size: 24px;
    font-weight: 300;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
  }

  .assessment-page .resultIntro p {
    font-family: var(--font-family-Inter);
  }

  .platFormheader {
    height: 28px;
    width: 372px;
    left: 0px;
    top: 0px;
    border-radius: nullpx;
    margin-top: 30px;
  }

  .platFormcompany {
    height: 30px;
    width: 372px;
    left: 0px;
    top: 28px;
    border-radius: nullpx;

  }

  .platformSaved {
    width: 25%;
    top: 0;
    left: 0;
  }

  .resultHeader {
    margin: 0;
    padding: 0;
  }

  .ResultResponsHide {
    display: none;
  }

  .ResultResponseShow {
    display: block;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.04em;
    text-align: left;
  }

  .assessment-page .progress-wrapper {
    margin-top: 30px;
    flex-direction: column;
    row-gap: 10px;
    margin-bottom: 5px;
  }

  .download-share-retake {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
  }

  .question-answer-wrapper {
    padding: 10px 30px;
  }

  .assessment-result-wrapper .section-title {
    padding: 10px 30px;
  }

  .card-style-tablet {
    width: 85%;
    margin-left: 100px;
  }

  .card-status {
    /* right: 308px; */
    /* top: 82px; */
  }

  .slider-title {
    justify-content: center;
    border-radius: 10px 10px 0px 0px;
  }

  .slider-row .slider-column {
    padding: 10px 85px 10px 62px;
  }

  .refineQuestion-mobile {
    display: unset !important;
  }

  .refineQuestion-dekstop {
    display: none !important;
  }

  .strength_ref-question .mb-3 {
    margin-bottom: unset !important;
  }

  .assessment-page .progress-wrapper.strength-progress-wrapper {
    margin-top: 0px;
    /* align-items: flex-end; */
    justify-content: flex-end;
    flex-direction: row;
    /* position: relative; */
    /* bottom: 130px; */
    /* left: 180px; */
    /* align-items: unset !important; */
  }

  .VD-mobile_container {
    background-color: var(--colour-background-primary);
    max-width: 100% !important;
  }

  .VD-mobile-page .assessment-review-result-wrapper {
    max-width: 100% !important;
  }

  .t-dark .VD-mobile_container {
    background-color: var(--colour-background-primary-transparent);
  }

  .t-dark .assessment-page .assessment-review-result-wrapper {
    background-color: var(--colour-background-primary-transparent)
  }
}

@media (max-width: 900px){
  .looking-atoption_assessment-page .assessment_introHeading {
    margin-top: 200px !important;
  }
}

@media (max-width: 425px) {
 .refineQuestion-mobile .refining-strength-bar, .refineQuestion-mobile .range-bar-text{
  margin-top: -5px;
 }
  .skill-questions-slider .slider-row .slider-column{
    padding: 10px 57px 10px 24px;
  }

  .card-status span {
    margin-top: 13px;
  }

  .card-row:first-child .card-status span {
    margin-top: 15px;
  }

  .thought-provoking-result .assessment-result-wrapper .section-title {
    padding: 10px 10px;
  }

  .thought-provoking-result .question-answer-wrapper {
    padding: 10px 10px;
  }

  .vd_assesment-qpage.assessment-page .container.assessment-question-wrapper {
    padding: 60px 20px;
  }

  .ejo_result-page {
    padding-left: 18px !important;
  }

  .ejo_result_page .resultIntro {
    margin-bottom: 0px !important;
  }

  .prepare_interview-page {
    overflow-x: hidden;
  }

  .prepare_interview-page .selectJobLabel {
    position: absolute;
    right: 10px;
  }

  .prepare_interview-page .applied-jobs-list h6 {
    padding-right: 87px;
  }

  .prepare_interview-page .applied-jobs-list {
    /* padding-right: 50px; */
    height: unset;
    position: relative;
  }

  .row>.loa-buttons {
    width: auto;
    margin: auto;
  }

  .assessment-page .assessment-review-result-wrapper {
    border-radius: 0px;
  }

  .assessment-page .container.assessment-question-wrapper {
    border-radius: 0px;
    padding: 40px 20px;
  }

  .assessment-page.iyip-page .container.assessment-question-wrapper {
    padding: 40px 20px;
  }

  .assessment-page .assessment-question-wrapper .iyip-options.equal-width-options {
    grid-template-columns: 48% 48%;
    grid-column-gap: 20px;
  }

  .assessment-page .assessment-question-wrapper .iyip-options label {
    /* word-break: break-all; */
  }

  .assessment-page .progress-wrapper.strength-progress-wrapper {
    justify-content: flex-start;
    margin: 0px
  }

  .slider-row .slider-column {
      padding: 10px 60px 10px 38px;
  }

  .looking-atoption_assessment-page .assessment_introHeading {
    margin-top: 78px !important;
  }

  .assessment_second-container-intro {
    padding: 1rem !important;
  }

  .assessment_introHeading {
    margin-top: -25px !important;
  }

  .download-share-retake {
    margin-left: 1px !important;
  }

  .evaluate_start-page button {
    width: unset !important;
  }

  .evaluate-questions button {
    width: unset !important;
  }

  .job-role-details-section {
    flex-direction: column;
    gap: 15px;
  }

  .cardFooter {
    align-items: flex-start;
    flex-direction: column-reverse;
    gap: 8px;
  }

  .card-style {
    padding: 15px;
  }

  .card-style p {
    margin-top: 0.5rem;
  }

  .cardFooter .button-wrap {
    /* display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%; */
  }

  .strength_skillstart button {
    width: unset !important;
  }

  .card-status+div {
    /* right: 157px; */
    /* top: 60px; */
    padding-left: 0px;
  }

  .card-status span {
    /* width: 15px; */
    /* height: 15px; */
  }

  .review-accom_button {
    margin-bottom: 0.5rem !important;
  }

  .looking_start-page button {
    width: unset !important;
  }

  .interview_assesment {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .interview_assesment-skip p {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    text-align: center;
  }

  .strength-analysis-starpage button {
    width: unset !important;
  }

  .strength-questionpage button {
    width: unset !important;
  }

  .refining-questions button {
    width: unset !important;
  }

  .assessment-page .assessment-question-wrapper .iyip-options {
    justify-content: unset;
  }

  .strength_ref-question .px-5 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .strength_bar {
    font-size: 12px;
  }

  .assessment-question-wrapper .px-5 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .skill-questions-slider .mb-3 {
    margin-bottom: unset !important;
  }

  .refineQuestion-mobile .range-bar {
    margin-top: -10px !important;
    font-size: 12px;
  }

  .result-lables label {
    position: relative;
    left: 54px;
  }

  .strength-progress-wrapper {
    /* position: relative; */
    /* bottom: 135px; */
    /* right: 121px; */
  }

  .interest-result-lables-start label {
    /* position: relative; */
    /* left: -68px; */
  }

  .skill-result-lables-start label {
    /* position: relative; */
    /* left: -86px; */
  }

  .strength-progress-bar {
    margin-top: 1rem !important;
  }

  .skills-question button {
    width: unset !important;
  }

  .refineQuestion-mobile .mb-3 {
    margin-bottom: unset !important;
  }

  .range-bar-text {
    position: relative;
    left: -18px;
  }

  .range-bar-text div:nth-child(3) span {
    position: relative;
    /* left: -25px; */
  }

  .refining-strength-bar span {
    position: relative;
    /* left: -10px; */
  }

  .skill-overlap {
    transform: unset;
  }

  .strength-analysis .card-status {
    /* right:200px; */
    /* top: 60px; */
  }

  .evaluate-continue-btn button {
    width: unset !important;
  }

  .custom-mb-20 {
    margin-bottom: 18%;
  }

  .evaluate-Job-container p {
    margin-bottom: 0 !important;
    margin-top: 30px !important;
    font-size: 1.5rem;
  }

  /* .assessment-question-wrapper{
    padding-top: 0px !important;
  } */

  .vd_assesment-questions {
    margin-top: -20px !important;
  }

  .VD-mobile_container {
    background-color: var(--colour-background-primary);
  }

  .t-dark .VD-mobile_container {
    background-color: var(--colour-background-primary);
  }

  .t-dark .assessment-page .assessment-review-result-wrapper {
    background-color: var(--colour-background-primary)
  }

  /* .card-style {
    padding: 23px 15px 24px 15px;
  } */

  .assessment_second-container-intro {
    margin-bottom: 0 !important;
  }

  .strength-analysis_cards {
    padding-left: 3.2rem !important;
    padding-right: 1.2rem !important;
  }

  .card-style-tablet {
    width: unset !important;
    margin-left: unset !important;
  }

  .strength-progress-wrapper {
    /* position: relative; */
    /* bottom: 125px; */
    /* right: 123px; */
    /* left: unset; */
    /* align-items: center !important; */
  }

  .skill-resultpage .strength-progress-wrapper {
    /* position: relative; */
    /* bottom: 124px; */
    /* right: 115px; */
  }

  .job-list .job-title {
    padding-right: 97px;
  }
}

@media (max-width: 375px) {
  .slider-row .slider-column {
    padding: 10px 55px 10px 32px !important;
}

.skill-questions-slider .slider-row .slider-column{
  padding: 10px 52px 10px 20px !important;
}
  .cardFooter button {
    margin: 0 5px 0 0 !important;
  }

  .strength-analysis .cardFooter button {
    font-size: 14px;
    padding: 6px;
  }

  .prepare_interview-page .applied-jobs-list h6 {
    padding-right: 95px;
  }

  .result-lables label {
    position: relative;
    left: 45px;
  }

  .skill-result-lables-start label {
    left: -61px;
  }

  .skill-resultpage .strength-progress-wrapper {
    bottom: 124px;
    right: 86px;
  }

  .job-list .job-title {
    padding-right: 95px;
  }

  .strength-progress-wrapper {
    /* position: relative; */
    /* bottom: 126px; */
    /* right: 90px; */
  }

  .result-lables-start label {
    position: relative;
    left: 8px;
  }

  .strength-analysis .card-status {
    /* right: 170px; */
    /* top: 60px; */
  }

  .strength-analysis_cards {
    padding-left: 4rem !important;
  }

  .interest-result-lables-start label {
    /* position: relative; */
    /* left: -38px; */
  }
}