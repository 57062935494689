.iview-welcome,
.iview-welcome * {
  box-sizing: border-box !important;
}

.background-White {
  background-color: #fff;
}

.iview-welcome .imagebanner {
  height: 100%;
}

.image-overflow {
  /* margin-bottom: -100px; */
}

.background-Black {
  display: flex;
  background-color: var(--colour-container-featured);
  /* color: var(--colour-action-primary-text); */
  color: var(--colour-action-secondary-text);
  /* margin: 30px 0 30px 50px !important; */
}

.background-Black h5 {
  /* color: var(--colour-action-primary-text); */
  color: var(--colour-action-secondary-text);
}

.background-Black label {
  /* color: var(--colour-action-primary-text); */
  color: var(--colour-action-secondary-text);
}

.background-Black li {
  /* color: var(--colour-action-primary-text); */
  color: var(--colour-action-secondary-text);
}

.background-Black li i {
  color: var(--colour-action-primary-arrow);
  font-size: 20px;
  margin-right: 10px;
  vertical-align: middle;
}


button.saveBtn {
  border: none;
  border-radius: var(--border-radius-softly-rounded);
  padding: var(--spacing-4) var(--spacing-8);
  background-color: var(--colour-action-background);
  color: var(--colour-action-primary);
  height: 1.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all var(--transition-timing-function-default)
    var(--transition-speed-normal);
}

button.saveBtn:hover {
  border: none;
  background-color: var(--colour-action-primary);
  color: var(--colour-action-primary-text);
}

button.saveBtn span.saveBtnText {
  margin-left: 0.375rem;
}

#category-label {
  margin-left: 10px;
  font-size: 14px;
  border: 1px solid #d9e2ff;
  border-radius: 4px;
  padding: 3px 8px 2px;
}

#cardtype {
  margin-left: 10px;
  font-size: 14px;
}

button.likeBtn {
  border: none;
  padding: var(--spacing-4) var(--spacing-8);
  background-color: var(--colour-background-primary);
  color: var(--colour-text-primary);
}

button.likeBtn:hover {
  border: none;
}

button.likeBtn span.likeBtnText {
  font-size: 12px;
}

.iview-view-collection-subheading {
  color: var(--colour-text-secondary);
  font-size: 16px;
  padding-bottom: 5px;
  cursor: pointer;
}

ul.videocollectionList {
  list-style: none;
  padding: 0px;
}

.downarrowIcon {
  color: var(--colour-action-primary-arrow);
}

.videoCollectionListRow {
  margin-top: 1rem !important;
}

.background-dark {
  background-color: var(--colour-container-featured-dark) !important;
  color: var(--colour-container-small);
  border: 1px solid var(--colour-background-primary-transparent) !important;
  /* margin-bottom: 5px; */
}

.background-button-dark {
  /* background-color: var(--colour-container-featured-dark) !important; */
  /* color: var(--colour-container-small) !important; */
  /* border: 1px solid var(--colour-container-small) !important; */
  background-color: var(--colour-background-primary-transparent) !important;
  color: var(--colour-global-white) !important;
  border: 1px solid var(--colour-global-white) !important;
  /* margin-bottom: 5px; */
}

.background-button-light {
  /* background-color: var(--colour-background-secondary) !important; */
  /* color: var(--colour-text-primary) !important; */
  /* border: 1px solid var(--colour-action-outline) !important; */
  background-color: var(--colour-background-primary-transparent) !important;
  color: var(--colour-action-feature) !important;
  border: 1px solid var(--colour-action-feature) !important;
  margin-bottom: 5px;
}

.background-light {
  background-color: var(--colour-background-secondary) !important;
  color: var(--colour-text-primary) !important;
  border: 1px solid var(--colour-border-hairline) !important;
  margin-bottom: 5px;
}

.common-margin-from-top {
  margin-top: 10px !important;
  /* padding: 4px; */
  padding: 4px 0;
}
.iviewCards {
  column-gap: 15px;
}

.iview-collection-title-dark {
  font-size: 24px;
  color: var(--colour-action-primary-text);
}
.iview-collection-title-light {
  font-size: 24px;
  color: var(--colour-text-primary);
}

.t-dark h3 {
  color: #ffffff;
}

.t-dark .background-White {
  background-color: #12151e !important;
}

.text-color-white {
  color: var(--colour-action-primary-text);
}
.iview-collection-subtitle-dark {
  color: var(--colour-container-small);
  font-size: 11px;
}
.iview-collection-subtitle-light {
  color: var(--colour-action-primary);
  font-size: 11px;
}

.t-dark .iview-collection-subtitle-light {
  color: gray;
  font-size: 11px;
}

.iview-collection-description-dark {
  color: var(--colour-action-primary-text);
}
.iview-collection-description-light {
  color: var(--colour-text-primary);
}

.text-color-primary {
  color: var(--colour-text-primary);
}
.text-color-action-primary {
  color: var(--colour-action-primary);
}
.twoCloumn {
  columns: 2 auto;
  padding-bottom: 5px;
}

.iview-library-item {
  padding-left: 5px;
}
.iview-welcome .iview-card-remove-padding {
  padding: 0px !important;
  max-width: 300px;
}

.iview-welcome .title-and-description {
  text-transform: capitalize;
}

.iview-welcome .iview-carousel-cards{
  height: 273px;
}

.iview-welcome .saveBtn{
  margin-right: 5px;
}

@media screen and (max-width: 768px){
  .iview-welcome{
    overflow-x: hidden;
  }
}

@media screen and (max-width: 425px){
  .iview-header{
    flex-wrap: wrap;
  }
  .videocollectionList{
    display: flex;
    flex-direction: column;
  }
  .iview-welcome .iview-card-remove-padding {
    max-width: none;
  }
} 